export const DESCRIPTIONS = [
    {
        id: 1,
        name: 'widget.descriptions.eaters',
        icon: '🥘️',
    },
    {
        id: 3,
        name: 'widget.descriptions.alcohol',
        icon: '🧃',
    },
    {
        id: 4,
        name: 'widget.descriptions.party',
        icon: '🕺',
    },
    {
        id: 5,
        name: 'widget.descriptions.diet',
        icon: '🍞',
    },
    {
        id: 6,
        name: 'widget.descriptions.vegetarian',
        icon: '🥗',
    },
    {
        id: 7,
        name: 'widget.descriptions.drinkers',
        icon: '🥂',
    },
    {
        id: 8,
        name: 'widget.descriptions.sporty',
        icon: '🏃',
    },
    {
        id: 9,
        name: 'widget.descriptions.old',
        icon: '👵',
    },
    {
        id: 10,
        name: 'widget.descriptions.adult',
        icon: '👱‍♂️',
    },
    {
        id: 11,
        name: 'widget.descriptions.kid',
        icon: '👶',
    },
    {
        id: 12,
        name: 'widget.descriptions.pregnant',
        icon: '🤰',
    },
    {
        id: 13,
        name: 'widget.descriptions.handicap',
        icon: '👨‍🦽',
    },
    {
        id: 14,
        name: 'widget.descriptions.gourmand',
        icon: '🍔',
    },
    {
        id: 15,
        name: 'widget.descriptions.english',
        icon: '🇬🇧',
    },
];
