<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import '@/assets/images/results.png';
    import PackageSelection from '@/components/PackageSelection.ce.vue';
    import ResultsForm from '@/components/ResultsForm.ce.vue';
    import { SURVEY_THEME } from '@/constants/survey';
    import { type EventTypeTyping } from '@/typings/widget';
    import SubmitConfirmationView from '@/views/SubmitConfirmationView.ce.vue';
    import { type Ref } from 'vue';

    const props = defineProps<{
        venue: string
        name: string
        date: string
        locale: string
        eventType: number
        error?: boolean
        guests?: number
        totalGuest?: number
        eventTypes: EventTypeTyping[]
        messageData?: {
            surveyOrganizer: string
            surveyTheme: string
            surveyAppointment: string
            descriptionIds: number[]
            budgetAmount: number
            appointmentDate: string
        }
    }>();

    const emit = defineEmits<{(event: 'backToMain')}>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const PERCENTAGE_DELAY = 1000;
    const RESULTS_IMG = `${import.meta.env.VITE_CDN_URL}/assets/results.png`;

    // Refs
    const scrollContainer:Ref<HTMLElement | null> = ref(null);
    const packagesContainer:Ref<HTMLElement | null> = ref(null);

    // Data
    const showElement = ref(false);
    const packageDetailView = ref(false);
    const confirmationView = ref(false);
    const congratsView = ref(false);
    const numberOfPackages = ref(0);
    const statistics = ref([
        {
            leftLabel: t('widget.results.options.intimate'),
            rightLabel: t('widget.results.options.big'),
            percentage: 0,
        },
        {
            leftLabel: t('widget.results.options.elegant'),
            rightLabel: t('widget.results.options.festive'),
            percentage: 0,
        },
        {
            leftLabel: t('widget.results.options.simple'),
            rightLabel: t('widget.results.options.unique'),
            percentage: 0,
        },
        {
            leftLabel: t('widget.results.options.talk'),
            rightLabel: t('widget.results.options.dance'),
            percentage: 0,
        },
    ]);

    // Methods
    const resetAnimationState = ():void => {
        showElement.value = false;

        // Trigger the "showElement" transition again after a brief delay
        setTimeout(() => {
            showElement.value = true;
        }, ENTRY_DELAY);
    };

    const calculatePercentages = ():void => {
        const sizePercentage = ((props.guests ?? 0) * 100 / (props.totalGuest ?? 1));

        if (props.messageData) {
            const { surveyTheme } = props.messageData;
            statistics.value[0].percentage = sizePercentage;
            statistics.value[1].percentage = SURVEY_THEME.find(theme => theme.value === surveyTheme)?.intimate ?? 0;
            statistics.value[2].percentage = 100;
            statistics.value[3].percentage = SURVEY_THEME.find(theme => theme.value === surveyTheme)?.dance ?? 0;
        }
    };

    const scrollToTop = ():void => {
        if (scrollContainer.value) scrollContainer.value.scrollTop = 0;
    };

    const scrollToPackages = (): void => {
        if (packagesContainer.value && scrollContainer.value) {
            const anchorOffsetTop = packagesContainer.value.getBoundingClientRect().top;
            const containerOffsetTop = scrollContainer.value.getBoundingClientRect().top;

            // Calculate the scroll position relative to the scroll container
            const scrollPosition = scrollContainer.value.scrollTop + anchorOffsetTop - containerOffsetTop;

            // Smooth scrolling
            scrollContainer.value.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    };

    onMounted(() => {
        resetAnimationState();

        setTimeout(() => {
            calculatePercentages();
        }, PERCENTAGE_DELAY);
    });
</script>

<template>
    <div
        :class="{'slide-bottom-up': showElement}"
        class="final-result-card"
    >
        <div
            ref="scrollContainer"
            class="final-result-card__scroll"
        >
            <template v-if="!packageDetailView && !confirmationView">
                <div class="final-result-card__results">
                    <div class="final-result-card__results__content">
                        <span>{{ t('widget.results.title') }}</span>
                        <h2>{{ t('widget.results.subtitle') }}</h2>
                        <div class="final-result-card__results__statistics">
                            <div
                                v-for="(item, index) in statistics"
                                :key="index"
                                class="final-result-card__results__item"
                            >
                                <label>{{ item.leftLabel }}</label>
                                <div class="final-result-card__results__bar">
                                    <div
                                        class="final-result-card__results__progress"
                                        :style="{ inlineSize: item.percentage + '%' }"
                                    />
                                </div>
                                <label class="right">{{ item.rightLabel }}</label>
                            </div>
                        </div>
                        <button
                            type="button"
                            class="btn btn--thin btn--secondary final-result-card__results__submit"
                            @click="scrollToPackages"
                        >
                            <span>
                                {{ t('widget.results.packages', { number: numberOfPackages }) }}
                            </span>
                        </button>
                    </div>
                    <img
                        :src="RESULTS_IMG"
                        class="final-result-card__results__img"
                    >
                </div>
                <hr>
                <ResultsForm
                    :venue="venue"
                    :name="name"
                    :date="date"
                    :locale="locale"
                    :guest-amount="guests"
                    :event-type="eventType"
                    :event-types="eventTypes"
                    :message-data="messageData"
                    @process-request="confirmationView = true"
                />
                <hr ref="packagesContainer">
                <h2 class="final-result-card__package-title">
                    {{ t('widget.results.disc') }}
                </h2>
            </template>
            <template v-if="!confirmationView">
                <PackageSelection
                    :venue="venue"
                    :locale="locale"
                    :error="error"
                    hide-filter
                    hide-title
                    @scroll-to-top="scrollToTop"
                    @filtered-packages="numberOfPackages = $event"
                    @toggle-home="packageDetailView = !$event"
                    @process-request="congratsView = true"
                    @back-to-main="emit('backToMain')"
                />
                <div
                    v-if="!congratsView"
                    class="final-result-card__footer"
                >
                    {{ t('widget.global.made-constell') }}
                </div>
            </template>
            <SubmitConfirmationView
                v-if="confirmationView"
                @back-to-main="emit('backToMain')"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.final-result-card {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    opacity: 0;
    transform: translateY(100%) scale(1);
    transition: all 0.75s ease-in-out;

    hr {
        margin: var(--spacing-03);
        background: var(--border-tertiary);
        block-size: 1px;
        min-block-size: 1px;

        @include min-width(md) {
            margin: var(--spacing-07);
        }
    }

    &__package-title {
        font-size: var(--font-h2);
        text-align: center;
    }

    &__scroll {
        block-size: 100%;
        min-block-size: 100%;
        -ms-overflow-style: none; /* IE & Edge */
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari */
        }
    }

    &__results {
        display: flex;
        flex-direction: column-reverse;
        gap: var(--spacing-07);
        padding-block: var(--spacing-03) 0;
        padding-inline: var(--spacing-03);

        @include min-width(md) {
            padding-block: var(--spacing-07) 0;
            padding-inline: var(--spacing-07);
        }

        @include min-width(lg) {
            flex-direction: row;
        }

        &__content {
            display: flex;
            flex: 2;
            flex-direction: column;
            align-items: flex-start;

            span {
                font-size: var(--font-medium);
            }

            h2 {
                font-size: var(--font-h2);
            }
        }

        &__img {
            border-radius: var(--border-radius-md);
            block-size: 168px;
            inline-size: 100%;

            @include min-width(md) {
                block-size: 340px;
            }

            @include min-width(lg) {
                block-size: 100%;
                inline-size: 286px;
            }
        }

        &__statistics {
            display: flex;
            flex-direction: column;
            padding: var(--spacing-04);
            border-radius: var(--border-radius-md);
            background: var(--background-primary);
            gap:  var(--spacing-02);
            inline-size: 100%;
            margin-block: var(--spacing-04) var(--spacing-05);

            @include min-width(md) {
                padding: var(--spacing-05);
            }
        }

        &__submit > span {
            font-size: var(--font-regular);
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--spacing-04);
            inline-size: 100%;

            label {
                flex: 1;
                font-size: var(--font-regular);
                font-weight: var(--font-weight-medium);
                min-inline-size: 70px;

                @include min-width(md) {
                    min-inline-size: 110px;
                }

                &.right {
                    text-align: end;
                }
            }
        }

        &__bar,
        &__progress {
            border-radius: var(--border-radius-rounded);
            block-size: var(--spacing-02);
        }

        &__bar {
            flex: 2;
            background: var(--background-secondary);
            inline-size: 100%;
        }

        &__progress {
            background: var(--widget-brand-color);
            block-size: var(--spacing-02);
            transition: inline-size 0.5s ease-in-out;
        }
    }

    &__footer {
        padding: 20px;
        color: var(--text-tertiary);
        font-size: 12px;
        margin-block: 20px;
        margin-inline: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    &.slide-bottom-up {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}
</style>
