/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { type Ref } from 'vue';

// eslint-disable-next-line max-lines-per-function, max-statements
export const useWidgetMenuStore = defineStore('menu', () => {
    // Data
    const isMenuOpen: Ref<boolean> = ref(false);
    const isQuoteOpen: Ref<boolean> = ref(false);

    return {
        isMenuOpen,
        isQuoteOpen,
    };
});
