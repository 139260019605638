<script setup lang="ts">
    import { type CloudinaryImage } from '@cloudinary/url-gen/index';
    import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/vue';
    import { useMedia, useProfileImage } from '@shared/composables/useImage';
    import { type ImageTyping, type MediaTyping } from '@shared/typings';
    import { type ComputedRef } from 'vue';

    const props = defineProps<{
        media?: MediaTyping | null
        profileImage?: ImageTyping | null
        preview?: string | null
        aspectRatio?: string
        noCrop?: boolean
        assetWidth?: number
    }>();
    const plugins = [
        lazyload(), responsive({
            steps: [
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                800, 1000, 1400,
            ],
        }), placeholder({ mode: 'blur' }),
    ];

    const processedMedia: ComputedRef<CloudinaryImage | null> = computed(() => useMedia(
        props.media ?? null, props.aspectRatio, props.assetWidth, props.noCrop,
    ));
    const processedImage: ComputedRef<CloudinaryImage | null> = computed(() => useProfileImage(props.profileImage ?? null));
</script>

<template>
    <div class="advanced-image">
        <AdvancedImage
            v-if="media"
            class="advanced-image__inner"
            :cld-img="processedMedia"
            :plugins="plugins"
            :asset-width="assetWidth"
        />
        <AdvancedImage
            v-else-if="profileImage"
            class="advanced-image__inner"
            :cld-img="processedImage"
            :plugins="plugins"
            :asset-width="assetWidth"
        />
        <img
            v-else-if="preview"
            class="advanced-image__inner"
            :src="preview"
        >
        <slot name="overlay" />
    </div>
</template>

<style scoped lang="scss">
    .advanced-image {
        display: flex;
        overflow: hidden;
        block-size: 100%;
        inline-size: 100%;

        &__inner {
            block-size: 100%;
            inline-size: 100%;
            object-fit: cover; // Ensures the image covers the entire container
            object-position: center; // Centers the image within the container
        }

        /* Style the overlay slot content */
        :deep(.btn-overlay-content) {
            position: absolute;
            z-index: 1;
            inset-block-end: var(--spacing-04);
            inset-inline-end: var(--spacing-07);
        }
    }
</style>
