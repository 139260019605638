<script setup lang="ts">
    const props = withDefaults(defineProps<{
        size?: number; // The size of the loader in pixels
        thickness?: number; // The thickness of the loader's border
    }>(), {
        size: 70,
        thickness: 3,
    });

    // Computed styles for the loader
    const loaderStyle = computed(() => ({
        width: `${props.size}px`,
        height: `${props.size}px`,
        borderWidth: `${props.thickness}px`,
    }));
</script>

<template>
    <div
        class="circle-loader"
        :style="loaderStyle"
    />
</template>

<style scoped lang="scss">
    .circle-loader {
        display: inline-block;
        border: solid transparent;
        border-color: var(--background-primary-invert);
        border-radius: 50%;
        animation: spin 1s linear infinite;
        border-block-start-color: currentcolor;
        color: var(--background-primary);
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
