import { i18n } from '@/i18n';
import { plugin as formKitPlugIn } from '@formkit/vue';
import { init } from '@sentry/vue';
import { createPinia } from 'pinia';
import { type Component, createApp } from 'vue';
import { version } from '../package.json';
import AppLoader from './AppLoader.ce.vue';
import { defineCustomElement } from './defineCustomElementWithStyles';
import eventBusPlugin from './plugins/event-bus-plugin';

// Define release for Sentry
const release = `venue-manager-app@${version}`;

// Initialise Sentry
if (import.meta.env?.VITE_ENVIRONMENT && import.meta.env.VITE_SENTRY_DSN) {
    init({
        app: createApp(AppLoader as Component),
        dsn: String(import.meta.env.VITE_SENTRY_DSN ?? ''),
        release,
        environment: String(import.meta.env.VITE_ENVIRONMENT ?? ''),
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['*'],
    });
}

// Create Pinia instance
const pinia = createPinia();

// This will define our component as a WebComponent, with a small hack to get our styles in there:
customElements.define('constell-widget', defineCustomElement(AppLoader, {
    plugins: [
        { plugin: eventBusPlugin },
        { plugin: formKitPlugIn },
        { plugin: i18n },
        { plugin: pinia },
    ],
}));
