<script setup lang="ts">
    import formKitConfig from '@/formkit';
    import { FormKitProvider, FormKitRoot, defaultConfig } from '@formkit/vue';
    import Dropdown from '@shared/components/form/dropdown.vue';
    import NumberInput from '@shared/components/form/number-input.vue';
    import { computed, ref, watch, type Ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'selectTypes', type: number)
        (event: 'guestAmountChanges', type: string | null)
    }>();

    const { venueEventTypes } = storeToRefs(useWidgetVenueStore());
    const { eventTypeId } = storeToRefs(useWidgetPathStore());

    const filterForm: Ref<{
        guestAmount: string | null
        eventType: string | null
    }> = ref({
        guestAmount: null,
        eventType: null,
    });

    const eventTypes = computed(() => {
        const all = {
            value: '0',
            label: t('widget.form.all-events'),
        };

        // Map to object with value and label + sort alphabetically
        const sortedTypes = venueEventTypes.value
            .map(value => ({
                value: String(value.id),
                label: value.name,
            }))
            .sort((itemA, itemB) => itemA.label.localeCompare(itemB.label));

        // Add the 'all option' to the beginning
        return [all, ...sortedTypes];
    });

    onMounted(() => {
        if (eventTypes.value.length) {
            filterForm.value.eventType = eventTypes.value.find(type => type.value === String(eventTypeId.value))?.value ?? null;
        }
    });

    onBeforeUnmount(() => {
        filterForm.value = {
            guestAmount: null,
            eventType: null,
        };
    });

    watch(() => filterForm.value.eventType, newVal => {
        emit('selectTypes', Number(newVal));
    });

    watch(() => filterForm.value.guestAmount, newVal => {
        emit('guestAmountChanges', newVal);
    });

    watch(() => eventTypeId.value, (nVal: number) => {
        filterForm.value.eventType = eventTypes.value.find(type => type.value === String(nVal))?.value ?? null;
    });
</script>

<template>
    <div class="filter-bar">
        <FormKitProvider :config="defaultConfig(formKitConfig)">
            <FormKitRoot>
                <FormKit
                    v-model="filterForm"
                    type="form"
                    name="filterForm"
                    :actions="false"
                >
                    <Dropdown
                        name="eventType"
                        :placeholder="t('widget.form.all-events')"
                        :options="eventTypes"
                        label-hidden
                    />
                    <NumberInput
                        id="guestAmount"
                        name="guestAmount"
                        :placeholder="`${t('widget.form.guest-amount')}`"
                        validation="number"
                        secondary
                    />
                </FormKit>
            </FormKitRoot>
        </FormKitProvider>
    </div>
</template>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.filter-bar {
    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    .formkit-form {
        z-index: 2;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        border-block-end: 1px solid var(--border-tertiary);
        font-size: var(--font-medium);
        gap: var(--spacing-02);
        inline-size: 100%;
        inset-block-start: 0;
        padding-block: var(--spacing-04);
        padding-inline: var(--spacing-03);

        // eslint-disable-next-line vue-scoped-css/no-unused-selector
        .dropdown, .number-input {
            flex: 1;
            font-size: var(--font-regular);
            inline-size: 100%;
        }

        @include min-width(md) {
            flex-direction: row;
            padding-inline: var(--spacing-05);
        }

        @include min-width(lg) {
            padding-inline: var(--spacing-07);
        }
    }
}
</style>
