<script setup lang="ts">
    import formKitConfig from '@/formkit';
    import SubmitConfirmationView from '@/views/SubmitConfirmationView.ce.vue';
    import { type FormKitNode } from '@formkit/core';
    import { defaultConfig, FormKitProvider, FormKitRoot } from '@formkit/vue';
    import DatePicker from '@shared/components/form/date-picker.vue';
    import Dropdown from '@shared/components/form/dropdown.vue';
    import Email from '@shared/components/form/email.vue';
    import NumberInput from '@shared/components/form/number-input.vue';
    import PhoneNumber from '@shared/components/form/phone-number-input.vue';
    import TextInput from '@shared/components/form/text-input.vue';
    import TextArea from '@shared/components/form/textarea.vue';
    import Icon from '@shared/components/ui/icon/icon.vue';
    import { ConstantsDateTime } from '@shared/constants';
    import { DateTime } from '@shared/helpers';
    import axios from 'axios';
    import { type Ref } from 'vue';

    const props = defineProps<{
        venue: string
        locale: string
    }>();

    const emit = defineEmits<{(event: 'processRequest', type: unknown), (event: 'back' | 'scrollToTop')}>();

    const { t } = useI18n();

    // Stores
    const { venueEventTypes } = storeToRefs(useWidgetVenueStore());

    // Refs
    const form = ref(null);
    const formData: Ref<{
        name: string
        surname: string
        eventDate: string
        companyName: string | null
        email: string | null
        phoneNumber: string | null
        message: string
        phoneCountryPrefix: string | null
        guestAmount: number | string | null
        eventTypeId: number | null
        duration: string | null
        availability: string | null
    }> = ref({
        name: '',
        surname: '',
        eventDate: '',
        companyName: null,
        email: null,
        phoneNumber: null,
        message: '',
        phoneCountryPrefix: '+31',
        guestAmount: null,
        eventTypeId: null,
        duration: 'whole-day',
        availability: 'only',
    });
    const successSend: Ref<boolean> = ref(false);

    // Computed
    const eventTypes = computed(() => {
        // Map to object with value and label + sort alphabetically
        const sortedTypes = venueEventTypes.value
            .map(value => ({
                value: String(value.id),
                label: value.name,
            }))
            .sort((itemA, itemB) => itemA.label.localeCompare(itemB.label));

        // Add the 'all option' to the beginning
        return [...sortedTypes];
    });
    const durationOptions = computed(() => ([
        {
            label: t('widget.form.morning'),
            value: 'morning',
        },
        {
            label: t('widget.form.afternoon'),
            value: 'afternoon',
        },
        {
            label: t('widget.form.evening'),
            value: 'evening',
        },
        {
            label: t('widget.form.whole-day'),
            value: 'whole-day',
        },
        {
            label: t('widget.form.whole-evening'),
            value: 'whole-evening',
        },
        {
            label: t('widget.form.multiday'),
            value: 'multiday',
        },
    ]));
    const availabilityOptions = computed(() => ([
        {
            label: t('widget.form.only'),
            value: 'only',
        },
        {
            label: t('widget.form.around'),
            value: 'around',
        },
        {
            label: t('widget.form.negotiable'),
            value: 'negotiable',
        },
    ]));
    const durationType = computed(() => durationOptions.value.find(dur => dur.value === formData.value.duration)?.label ?? '');
    const availabilityType = computed(() => availabilityOptions.value.find(ava => ava.value === formData.value.availability)?.label ?? '');

    // Methods
    const messageBuilder = (): string => `
                - Duration: ${durationType.value}\n\n
                - Availability: ${availabilityType.value}
            `;

    // eslint-disable-next-line max-lines-per-function
    const processForm = async(submittedData: typeof formData.value, formNode: FormKitNode): Promise<void> => {
        const {
            name,
            surname,
            eventDate,
            companyName,
            email,
            phoneNumber,
            phoneCountryPrefix,
            guestAmount,
            eventTypeId,
        } = submittedData;

        const payload = {
            userName: `${name} ${surname}`,
            venuePackageId: '',
            eventDate,
            companyName,
            email,
            phoneNumber,
            phoneCountryPrefix,
            guestAmount,
            eventTypeId,
            message: messageBuilder() + submittedData.message,
        };

        try {
            await axios
                .post(
                    `${import.meta.env.VITE_API_BASE_URL}/widget/api/venue/${props.venue}/quote`, payload, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Accept-Language': props.locale,
                        },
                    },
                );
            successSend.value = true;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Posting request form failed: ', error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const msg = t('widget.messages.errors.request-failed');
            formNode.setErrors([msg]);
        }
    };

    // Hooks
    onMounted(() => {
        emit('scrollToTop');
    });
</script>

<template>
    <div class="full-form">
        <SubmitConfirmationView
            v-if="successSend"
            mode="wisk"
            @back-to-main="emit('back')"
        />
        <template v-else>
            <button
                class="full-form__back btn btn--icon btn btn--ghost"
                type="button"
                @click="emit('back')"
            >
                <Icon
                    name="chevronLeft"
                    size="medium"
                />
            </button>
            <FormKitProvider :config="defaultConfig(formKitConfig)">
                <FormKitRoot>
                    <FormKit
                        id="full-form"
                        ref="form"
                        v-model="formData"
                        :incomplete-message="false"
                        :submit-label="t('widget.buttons.send-request')"
                        :submit-attrs="{
                            inputClass: 'btn btn--primary',
                            wrapperClass: 'im-on-the-wrapper',
                            outerClass: 'book-it-btn'
                        }"
                        type="form"
                        @submit="processForm"
                    >
                        <div class="full-form__content">
                            <h1>
                                {{ t('widget.form.title') }}
                            </h1>
                            <span>
                                {{ t('widget.form.subtitle') }}
                            </span>
                            <div class="full-form__content__row">
                                <TextInput
                                    id="name"
                                    :placeholder="`${t('widget.form.your-name')}*`"
                                    :validation-messages="{
                                        required: `${t('widget.form.validations.required', { field: t('widget.form.your-name') })}`
                                    }"
                                    name="name"
                                    validation="required"
                                />
                                <TextInput
                                    id="surname"
                                    :placeholder="`${t('widget.form.your-surname')}*`"
                                    :validation-messages="{
                                        required: `${t('widget.form.validations.required', { field: t('widget.form.your-surname') })}`
                                    }"
                                    name="surname"
                                    validation="required"
                                />
                            </div>
                            <TextInput
                                id="companyName"
                                :placeholder="t('widget.form.company-name')"
                                name="companyName"
                            />
                            <Email
                                id="email"
                                :placeholder="`${t('widget.form.email-2')}*`"
                                :validation-messages="{
                                    required: `${t('widget.form.validations.required', { field: t('widget.form.email') })}`
                                }"
                                name="email"
                                validation="required"
                            />
                            <PhoneNumber
                                id="phoneNumber"
                                :validation-messages="{
                                    required: `${t('widget.form.validations.required', { field: t('widget.form.phone-number') })}`
                                }"
                                name="phoneNumber"
                                validation="required"
                                placeholder="0 000 000 00"
                            />

                            <div class="full-form__content__section">
                                <span>{{ t('widget.form.split') }}</span>
                                <Dropdown
                                    name="eventTypeId"
                                    :placeholder="`${t('widget.form.choose-event')}*`"
                                    :options="eventTypes"
                                    :validation-messages="{
                                        required: `${t('widget.form.validations.required', { field: t('widget.form.event-type') })}`
                                    }"
                                    validation="required"
                                    label-hidden
                                />
                                <Dropdown
                                    name="duration"
                                    :options="durationOptions"
                                    label-hidden
                                    can-not-deselect
                                />
                                <DatePicker
                                    id="eventDate"
                                    :placeholder="`${t('widget.form.event-date')}*`"
                                    :validation-messages="{
                                        required: `${t('widget.form.validations.required', { field: t('widget.form.event-date') })}`
                                    }"
                                    :min-date="
                                        DateTime.dayjsObject().format(ConstantsDateTime.dateFormatCalendar)
                                    "
                                    name="eventDate"
                                    validation="required"
                                />
                                <Dropdown
                                    name="availability"
                                    :options="availabilityOptions"
                                    label-hidden
                                    can-not-deselect
                                />
                                <NumberInput
                                    id="guestAmount"
                                    name="guestAmount"
                                    :placeholder="`${t('widget.form.guest-amount')}*`"
                                    :validation-messages="{
                                        required: `${t('widget.form.validations.required', { field: t('widget.form.guest-amount') })}`,
                                        min: `${t('widget.form.validations.min', { field: t('widget.form.guest-amount') })}`
                                    }"
                                    validation="number|required|min:1"
                                    secondary
                                />
                                <TextArea
                                    id="message"
                                    :placeholder="t('widget.form.description-2')"
                                    name="message"
                                />
                            </div>
                        </div>
                    </FormKit>
                </FormKitRoot>
            </FormKitProvider>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.full-form {
    position: relative;
    background-color: var(--background-secondary);
    inline-size: 100%;
    min-block-size: 100%;
    padding-block-end: var(--spacing-14);

    &__back {
        position: relative;
        inset-block-start: var(--spacing-01);
        inset-inline-start: var(--spacing-01);

        @include min-width(md) {
            position: sticky;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: var(--spacing-03);
        gap: var(--spacing-03);
        inline-size: 100%;
        margin-inline: auto;
        max-inline-size: 488px;
        padding-block-start: var(--spacing-01);

        @include min-width(lg) {
            padding-block-start: 0;
        }

        h1, span {
            text-align: center;
        }

        span {
            display: block;
            margin-block-end: var(--spacing-04);
        }

        &__row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: var(--spacing-03);

            @include min-width(md) {
                flex-direction: row;
            }

            > * {
                flex: 1;
            }
        }

        &__section {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-03);

            span {
                margin-block: var(--spacing-04) 0;
                text-align: start;
            }
        }
    }

    :deep(.formkit-actions) {
        position: absolute;
        z-index: 100;

        .book-it-btn {
            position: fixed;
            z-index: 100;
            inline-size: calc(100% - var(--size-16));
            inset-block-end: var(--spacing-05);
            inset-inline-end: 50%;
            transform: translateX(50%);

            button {
                color: var(--text-primary-invert);
                font-size: var(--font-medium);
                font-weight: var(--font-weight-medium);
                inline-size: 100%;
                letter-spacing: var(--letter-spacing-0);
                line-height: var(--line-height-150);
            }

            @include min-width(md) {
                inline-size: auto;
            }

            @include min-width(lg) {
                inset-inline-end: var(--spacing-07);
                transform: initial;
            }
        }
    }
}
</style>
