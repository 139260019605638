<script setup lang="ts">
    import { type TutorialStepTyping } from '@/typings/widget';
    import { ConstantsBreakpoints } from '@shared/constants';

    const props = defineProps<{
        step: TutorialStepTyping
        total: number
    }>();

    const emit = defineEmits<{(event: 'next')}>();

    const { width } = useWindowSize();

    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const imgUrl = computed(() => isMobile.value ? props.step.imageMobile : props.step.image);
</script>

<template>
    <div class="tutorial-card">
        <div class="tutorial-card__left">
            <div class="tutorial-card__info">
                <span class="tutorial-card__info__index">
                    {{ `${step.index}.` }}
                </span>
                <div class="tutorial-card__info__content">
                    <div class="tutorial-card__info__inner">
                        <h2>
                            {{ step.title }}
                        </h2>
                        <span>
                            {{ step.description }}
                        </span>
                        <button
                            type="button"
                            class="btn btn--primary tutorial-card__info__next"
                            @click="emit('next')"
                        >
                            <span>
                                {{ step.button }}
                            </span>
                        </button>
                    </div>
                    <div class="tutorial-card__info__bullets">
                        <span
                            v-for="n in total"
                            :key="`${n}`"
                            :class="{'tutorial-card__info__bullet--selected': n === step.index}"
                            class="tutorial-card__info__bullet"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="tutorial-card__right">
            <img :src="imgUrl">
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tutorial-card {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    block-size: 100%;
    gap: var(--spacing-05);
    inline-size: 100%;
    padding-block-start: var(--spacing-13);
    padding-inline: var(--spacing-05);

    @include min-width(md) {
        padding-block-start: var(--spacing-16);
    }

    @include min-width(lg) {
        flex-direction: row;
        padding: 0;
        inline-size: calc(100% - var(--spacing-16));
        margin-inline: var(--spacing-16) 0;
    }

    &__left {
        inline-size: 100%;

        @include min-width(lg) {
            inline-size: 380px;
            padding-block-start: var(--spacing-12);
        }
    }

    &__right {
        flex: 2;

        @include min-width(lg) {
            padding-block-start: var(--spacing-06);
        }

        img {
            position: relative;
            inset-block-end: calc(-1 * var(--spacing-13));
            inset-inline-start: 50%;
            max-inline-size: 320px;
            transform: translateX(-50%);

            @include min-width(md) {
                inset-block-end: calc(-1 * var(--spacing-11));
                max-inline-size: 550px;
            }

            @include min-width(lg) {
                position: static;
                block-size: auto;
                inline-size: 600px;
                inset-block-end: unset;
                max-inline-size: none;
                transform: none;
            }
        }
    }

    &__info,
    &__info__content {
        display: flex;
    }

    &__info {
        align-items: flex-start;
        justify-content: space-between;
        block-size: 100%;
        gap: var(--spacing-03);

        @include min-width(md) {
            max-inline-size: 55%;
            padding-inline-start: var(--spacing-06);
        }

        @include min-width(lg) {
            max-inline-size: 90%;
        }

        &__index {
            color: var(--text-primary);
            font-family: var(--font-secondary);
            font-size: var(--font-h2);
            font-weight: var(--font-weight-semi-bold);
            letter-spacing: var(--letter-spacing-sm);
            line-height: var(--line-height-120);
        }

        &__content {
            flex-direction: column;

            @include min-width(lg) {
                justify-content: space-between;
                block-size: 68%;
            }
        }

        &__inner {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-02);

            @include min-width(lg) {
                align-items: flex-start;
                justify-content: flex-start;
            }

            > span {
                font-size: var(--font-regular);
            }
        }

        &__bullets,
        &__next {
            position: fixed;
            z-index: var(--zindex-stack-twice);
            inset-inline-start: 50%;
            transform: translateX(-50%);

            @include min-width(lg) {
                position: static;
                inset-inline-start: 0;
                transform: none;
            }
        }

        &__bullets {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--spacing-02);
            inset-block-end: var(--spacing-03);

            @include min-width(md) {
                inset-block-end: var(--spacing-04);
            }

            @include min-width(lg) {
                justify-content: flex-start;
            }
        }

        &__bullet {
            border-radius: var(--border-radius-circle);
            background: var(--background-secondary);
            block-size: 8px;
            inline-size: 8px;

            &--selected {
                background: var(--background-primary-invert);
            }
        }

        &__next {
            inset-block-end: var(--spacing-05);
            min-inline-size: 174px;

            @include min-width(md) {
                inset-inline: auto var(--spacing-05);
                transform: none;
            }

            @include min-width(lg) {
                inset-inline: 0;
                margin-block-start: var(--spacing-04);
            }
        }
    }
}
</style>
