<script lang="ts" setup>
    const props = defineProps<{
        modelValue: string | number
        inputValue: string | number
        name: string
    }>();

    const nameModel = ref(props.modelValue ?? '');

    const emit = defineEmits<{(event: 'update:modelValue', value: string | number)}>();

    watch(() => nameModel.value, (nVal: string | number) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <label
        :class="{'active': inputValue === nameModel}"
        class="widget-radio-input"
    >
        <input
            v-model="nameModel"
            type="radio"
            :name="name"
            :value="inputValue"
        >
        <div class="widget-radio-input__label">
            <slot />
        </div>
    </label>
</template>

<style scoped lang="scss">
    .widget-radio-input {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: var(--spacing-03);

        &::before {
            box-sizing: border-box;
            border: solid var(--spacing-02);
            border-color: var(--background-primary);
            border-radius: 50%;
            background-color: var(--background-primary);
            block-size: var(--size-10);
            content: "";
            inline-size: var(--size-10);
            min-block-size: var(--size-10);
            min-inline-size: var(--size-10);
            transition: all 150ms ease-in-out;
        }

        &:focus-within,
        &:hover,
        &.active {
            &::before {
                border-color: var(--color-primary);
                background-color: var(--border-primary);
            }
        }

        &__label {
            overflow: hidden;
            flex-grow: 1;
            color: var(--text-primary);
            font-family: var(--font-secondary);
            font-size: var(--font-h2);
            font-weight: var(--font-weight-medium);
            letter-spacing: var(--letter-spacing-sm);
            line-height: var(--line-height-150);
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>
