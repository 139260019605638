/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-magic-numbers */
export const getContrastColor = (bgColor: string): string => {
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);

    // Calculate luminance
    const luminance = ((0.299 * r) + (0.587 * g) + (0.114 * b)) / 255;

    // Return black or white text based on brightness
    return luminance > 0.5 ? '#282829' : '#FCFCFC';
};

export const getInvertContrastColor = (bgColor: string): string => {
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);

    // Calculate luminance
    const luminance = ((0.299 * r) + (0.587 * g) + (0.114 * b)) / 255;

    // Return black or white text based on brightness
    return luminance < 0.5 ? '#282829' : '#FCFCFC';
};

export const getContrastBackground = (bgColor: string): string => {
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);

    // Calculate luminance
    const luminance = ((0.299 * r) + (0.587 * g) + (0.114 * b)) / 255;

    // Return black or white text based on brightness
    return luminance < 0.5 ? '#E2E2E2' : '#282829';
};
