<script setup lang="ts">
    import App from '@/App.ce.vue';
    import '@/assets/styles/main.scss';
    import { WidgetPositionEnum } from '@/constants/style';
    import { i18n, setI18nLanguage } from '@/i18n';
    import dayjs from 'dayjs';
    import 'dayjs/locale/en';
    import 'dayjs/locale/nl';
    import { defineProps, onBeforeMount, onMounted, onUpdated } from 'vue';

    const props = withDefaults(defineProps<{
        buttonLabel?: string,
        buttonLabelClose?: string,
        venue: string,
        locale?: string,
        position?: WidgetPositionEnum,
        offsetX?: number,
        offsetY?: number,
        brandColor?: string,
        maxGuestAmount?: number
        minGuestAmount?: number
        guestAmountStep?: number
        maxBudgetAmount?: number
        minBudgetAmount?: number
        budgetStep?: number
    }>(), {
        buttonLabel: '',
        buttonLabelClose: '',
        locale: 'nl',
        position: WidgetPositionEnum.BOTTOM_RIGHT,
        offsetX: 0,
        offsetY: 0,
        brandColor: '',
        minGuestAmount: 0,
        maxGuestAmount: 1000,
        guestAmountStep: 1,
        maxBudgetAmount: 25000,
        minBudgetAmount: 0,
        budgetStep: 100,
    });

    onMounted(() => {
        if (!props.venue) {
            // eslint-disable-next-line no-console
            console.error('Constell: Venue ID is required to render the widget');
        }
    });

    onUpdated(() => {
        // This should make sure that when the prop on the web component changes, we also update the i18n instance with the latest locale info.
        setI18nLanguage(i18n, props.locale);
    });

    // eslint-disable-next-line max-lines-per-function
    onBeforeMount(() => {
        // First, make sure we pass our locale to the i18n instance
        setI18nLanguage(i18n, props.locale);

        // eslint-disable-next-line import/no-named-as-default-member
        dayjs.locale(props.locale ?? 'nl');

        /*
         * Because we cannot rely on CSS inside the web component, we need to load the fonts manually here.
         * This is basically a copy from what's inside the index.css, but only grabbing the fonts we need currently.
         * It will load and attach the fonts on the host document, which allows us to use them in the web component.
         * Inspired by https://www.seeratawan.me/blog/lazy-load-fonts-in-nextjs-with-web-components/
         */
        const fonts = [
            {
                family: 'Cormorant Infant',
                url: '/assets/fonts/cormorant-infant-light.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '300',
            },
            {
                family: 'Cormorant Infant',
                url: '/assets/fonts/cormorant-infant-regular.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '400',
            },
            {
                family: 'Cormorant Infant',
                url: '/assets/fonts/cormorant-infant-medium.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '500',
            },
            {
                family: 'Cormorant Infant',
                url: '/assets/fonts/cormorant-infant-semi-bold.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '600',
            },
            {
                family: 'Cormorant Infant',
                url: '/assets/fonts/cormorant-infant-bold.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '700',
            },
            {
                family: 'Space Grotesk Variable',
                url: '/assets/fonts/space-grotesk-light.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '300',
            },
            {
                family: 'Space Grotesk Variable',
                url: '/assets/fonts/space-grotesk-regular.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '400',
            },
            {
                family: 'Space Grotesk Variable',
                url: '/assets/fonts/space-grotesk-medium.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '500',
            },
            {
                family: 'Space Grotesk Variable',
                url: '/assets/fonts/space-grotesk-semi-bold.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '600',
            },
            {
                family: 'Space Grotesk Variable',
                url: '/assets/fonts/space-grotesk-bold.ttf',
                style: 'normal',
                unicodeRange: 'U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD',
                weight: '700',
            },
        ];

        fonts.forEach(fontConfig => {
            const fontFace = new FontFace(
                fontConfig.family, `url(${import.meta.env.VITE_CDN_URL}${fontConfig.url})`, {
                    style: fontConfig.style,
                    unicodeRange: fontConfig.unicodeRange,
                    weight: fontConfig.weight,
                    display: 'swap',
                },
            );

            fontFace.load()
                .then(loadedFontFace => {
                    // Add the loaded font to the document fonts
                    document.fonts.add(loadedFontFace);
                })
                .catch(error => {
                    // Log any error occurred during the font loading
                    // eslint-disable-next-line no-console
                    console.log(`Failed to load font: ${error}`);
                });
        });
    });
</script>

<template lang="html">
    <div id="app-root">
        <App v-bind="props" />
    </div>
</template>

<style lang="scss" src="./assets/styles/main.scss"></style>
