import { dateFormatFormKit, dateFormatPrettyMinimizedYear, dateTimeFormatFormKit } from '@shared/constants/date-time';
import dayjs, { type Dayjs, type OpUnitType, extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';

extend(customParseFormat);
extend(isSameOrBefore);
extend(isSameOrAfter);
extend(weekday);
extend(weekOfYear);
extend(isBetween);
extend(isToday);
extend(isTomorrow);
extend(isYesterday);

export { type Dayjs };

/**
 * The name of this package does not make much sense
 * It used to be for timezone stuff but is not used to make sure we only use a single dayjs instance
 * The time-zone is always in the context of the venue
 * FormKit timezones and taken out and dayjs just uses the timezone of the user while calculating and takes it off again
 * Whenever you use a time in FormKit please use this formatting: .format(dateTimeFormatFormKit)
 */
export const dayjsNow = (): Dayjs =>
    dayjs();

export const dayjsObject = (time?: string | Dayjs | Date): Dayjs =>
    dayjs(time);

export const dayjsFormatter = (
    time?: string | Dayjs | Date,
    format?: string,
    strict?: boolean,
): Dayjs =>
    dayjs(
        time,
        format,
        strict,
    );

export const formatDateForForms = (time?: string | Dayjs | Date): string =>
    dayjs(time)
        .format(dateTimeFormatFormKit);

/**
 * Checks if a date is today or in the past
 * which would make it overdue.
 * @param time string | dayjs | Date
 * @returns boolean
 */
export const isOverdue = (date: string | Dayjs | Date): boolean =>
    dayjs(date)
        .isSameOrBefore(dayjsNow());

/**
 * Check if date is the same or before dateToCompare
 * @param date
 * @param dateToCompare
 */
export const isDateSameOrBefore = (
    date: string | Dayjs | Date,
    dateToCompare: string | Dayjs | Date,
    granularity: OpUnitType | undefined = 'minute',
): boolean =>
    dayjs(date)
        .isSameOrBefore(dayjs(dateToCompare), granularity);

/**
 * Check if date is the same or after dateToCompare
 */
export const isDateSameOrAfter = (
    date: string | Dayjs | Date,
    dateToCompare: string | Dayjs | Date,
    granularity: OpUnitType | undefined = 'minute',
): boolean =>
    dayjs(date)
        .isSameOrAfter(dayjs(dateToCompare), granularity);

/**
 * Check if date is between firstDate and secondDate
 * @param date
 * @param firstDate
 * @param secondDate
 */
export const isDateBetween = (
    date: string | Dayjs | Date,
    firstDate: string | Dayjs | Date,
    secondDate: string | Dayjs | Date,
    granularity: OpUnitType | undefined = 'day',
): boolean =>
    dayjs(date)
        .isBetween(
            dayjs(firstDate), dayjs(secondDate), granularity,
        );

/**
 * Merge two dates into one string (i.e. could return 13-14 May, 24)
 * When given 13 and 14th of may as separate dates
 * @param startDate string | Dayjs | Date
 * @param endDate string | Dayjs | Date
 * @returns string
 */
export const mergeDays = (startDate: string | Dayjs | Date, endDate: string | Dayjs | Date): string => {
    const dayjsStartDate = dayjs(startDate);
    const dayjsEndDate = dayjs(endDate);

    if (dayjsStartDate.isSame(dayjsEndDate, 'year')) {
        if (dayjsStartDate.isSame(dayjsEndDate, 'month')) {
            return `${dayjsStartDate.date()} - ${dayjsEndDate.format(dateFormatPrettyMinimizedYear)}`;
        }

        return `${dayjsStartDate.format('DD MMM')} - ${dayjsEndDate.format(dateFormatPrettyMinimizedYear)}`;
    }

    return `${dayjsStartDate.format(dateFormatPrettyMinimizedYear)} - ${dayjsEndDate.format(dateFormatPrettyMinimizedYear)}`;
};

/**
 * Get a number that represents the day of the week
 * @param date
 */
export const getWeekDay = (date: string | Dayjs | Date): number =>
    dayjs(date)
        .weekday();

/**
 * Return date as '12h30' string
 * @param date string | Dayjs | Date
 * @returns string
 */
export const getTimePerUnit = (date: string | Dayjs | Date): string =>
    `${dayjs(date)
        .hour()}h${dayjs(date)
        .minute()}`;

/**
 * Return if date is today
 * @param date string | Dayjs | Date
 * @returns boolean
 */
export const dateIsToday = (date: string | Dayjs | Date): boolean =>
    dayjs(date)
        .isToday();

/**
 * Return if date is yesterday
 * @param date string | Dayjs | Date
 * @returns boolean
 */
export const dateIsYesterday = (date: string | Dayjs | Date): boolean =>
    dayjs(date)
        .isYesterday();

/**
 * Return if date is tomorrow
 * @param date string | Dayjs | Date
 * @returns boolean
 */
export const dateIsTomorrow = (date: string | Dayjs | Date): boolean =>
    dayjs(date)
        .isTomorrow();

export const dateWeekOfYear = (date: string | Dayjs | Date, newWeek?: number): number | Dayjs => {
    if (newWeek) {
        return dayjs(date)
            .week(newWeek);
    }
    return dayjs(date)
        .week();
};

export const isValidDate = (
    date: string, format = dateFormatFormKit, strict = false,
): boolean =>
    dayjs(
        date, format, strict,
    )
        .isValid();
