<script setup lang="ts">
    import { type FormKitNode } from '@formkit/core';
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import { ConstantsBreakpoints } from '@shared/constants';
    import { FormSizeTypeEnum, FormStyleEnum } from '@shared/constants/form-style';

    defineProps<{
        name: string
        id?: string
        label?: string
        placeholder?: string
        validation?: string | [rule: string, ...args: unknown[]][]
        validationMessages?: Record<string, string>
        help?: string
        size?: FormSizeTypeEnum.NORMAL | FormSizeTypeEnum.EXTRA
        style?: FormStyleEnum.PRIMARY | FormStyleEnum.SECONDARY
        disabled?: boolean
        min?: number
        max?: number
        labelHidden?: boolean
        number?: 'integer' | 'float'
        secondary?: boolean
        isLoading?: boolean
        value?: number
    }>();

    const emit = defineEmits<{(event: 'suffixIconClick' | 'change' | 'blur' | 'enter')}>();

    const { width } = useWindowSize();

    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const isDesktop = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.DESKTOP));
    const skeletonHeight = computed(() => isMobile.value ? '56px' : isDesktop.value ? '64px' : '60px');

    const toNumber = (value: unknown): number => {
        if (typeof value === 'string') return parseInt(value);
        else if (typeof value === 'number') return value;
        return 0;
    };

    const handlePlusClick = async(node: FormKitNode): Promise<void> => {
        let { value } = node;
        // eslint-disable-next-line no-undefined
        if (value === undefined) { value = 0 }
        const numberValue: unknown = toNumber(value);
        if (typeof numberValue === 'number') { await node.input(numberValue + 1) }
    };

    const handleMinusClick = async(node: FormKitNode): Promise<void> => {
        let { value } = node;
        // eslint-disable-next-line no-undefined
        if (value === undefined) { value = 0 }
        const numberValue: number = toNumber(value);
        if (typeof numberValue === 'number') { await node.input(numberValue - 1) }
    };
</script>

<template>
    <div
        :class="{'number-input--secondary': secondary}"
        class="number-input"
    >
        <Skeleton
            v-if="isLoading"
            :height="skeletonHeight"
        />
        <FormKit
            v-else
            :id="id ?? name"
            :min="min"
            :max="max"
            :label="label"
            :name="name"
            :placeholder="placeholder"
            :validation="`number|${validation}`"
            :help="help"
            :disabled="disabled"
            :wrapper-class="size ?? FormSizeTypeEnum.NORMAL"
            :label-class="labelHidden ? 'formkit-label--hidden' : ''"
            :inner-class="style ?? FormStyleEnum.PRIMARY"
            :number="number"
            :validation-messages="validationMessages"
            :value="value"
            type="number"
            prefix-icon="less"
            suffix-icon="plus"
            @suffix-icon-click="handlePlusClick"
            @prefix-icon-click="handleMinusClick"
            @change="$emit('change')"
            @blur="$emit('blur')"
            @keydown.enter="$emit('enter')"
        />
    </div>
</template>

<style scoped lang="scss">
    .number-input:deep(.formkit-outer[data-type=number]) {
        .formkit-inner {
            position: relative;

            input {
                box-sizing: border-box;
                padding: var(--spacing-03);
                border: 1px solid var(--border-tertiary);
                border-radius: var(--border-radius-xs);
                background-color: var(--background-form-input);
                block-size: var(--size-11);
                font-size: var(--font-medium);
                inline-size: 100%;
                line-height: 1;
                padding-inline: var(--spacing-07);
                text-align: center;

                &::placeholder {
                    color: var(--text-placeholder);
                }

                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }

                @include min-width(md) {
                    block-size: var(--size-12);
                }

                @include min-width(lg) {
                    block-size: var(--size-13);
                }
            }

            .formkit-icon {
                position: absolute;
                display: inline-flex;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                block-size: var(--size-3);
                cursor: pointer;
                inline-size: var(--size-3);
                inset-block-start: 50%;
                transform: translateY(-50%);
            }

            .formkit-prefix-icon {
                inset-inline-start: var(--spacing-03);
            }

            .formkit-suffix-icon {
                inset-inline-end: var(--spacing-03);
            }

            .formkit-suffix-icon svg {
                block-size: var(--size-3);
                inline-size: var(--size-3);
            }
        }
    }

    .number-input--secondary:deep(.formkit-outer[data-type=number]) {
        .formkit-inner {
            input {
                padding-inline: var(--spacing-03);
                text-align: start;
            }

            .formkit-icon {
                display: none;
            }
        }
    }

    .number-input:deep(.formkit-outer[data-type=number]),
    .number-input--secondary:deep(.formkit-outer[data-type=number]) {
        &[data-invalid=true] .formkit-inner {
            input,
            &.secondary input {
                border-color: var(--border-negative);
            }
        }
    }
</style>
