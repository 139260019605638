import { ref, onUnmounted } from 'vue';

// eslint-disable-next-line max-lines-per-function
export const useSafeTimeout = (): {
  setSafeTimeout: (callbackFunc: () => void, delay?: number) => ReturnType<typeof setTimeout>;
  clearSafeTimeout: (id: ReturnType<typeof setTimeout>) => void;
  clearAllTimeouts: () => void;
} => {
    // Track active timeouts
    const timeouts = ref<Set<ReturnType<typeof setTimeout>>>(new Set());

    // Add a new timeout
    const setSafeTimeout = (callbackFunc: () => void, delay = 0): ReturnType<typeof setTimeout> => {
        const id = setTimeout(() => {
            callbackFunc();
            timeouts.value.delete(id); // Remove timeout once executed
        }, delay);
        timeouts.value.add(id);
        return id;
    };

    // Clear a specific timeout
    const clearSafeTimeout = (id: ReturnType<typeof setTimeout>): void => {
        clearTimeout(id);
        timeouts.value.delete(id); // Remove it from the active set
    };

    // Clear all timeouts
    const clearAllTimeouts = (): void => {
        timeouts.value.forEach(id => {
            clearTimeout(id);
        });
        timeouts.value.clear(); // Empty the set
    };

    // Automatically clear timeouts when the component unmounts
    onUnmounted(() => {
        clearAllTimeouts();
    });

    return {
        setSafeTimeout,
        clearSafeTimeout,
        clearAllTimeouts,
    };
};
