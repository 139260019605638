<script setup lang="ts">
    import { DEFAULT_AVATAR } from '@/constants/avatar';
    import { getContrastColor } from '@/helpers/text';
    import { type MenuOptionTyping, type VenueManagerTyping } from '@/typings/widget';
    import AdvancedImage from '@shared/components/ui/advanced-image.vue';

    defineProps<{
        option: MenuOptionTyping
        venueManager?: VenueManagerTyping
        brandColor?: string
    }>();

    const emit = defineEmits<{(event: 'click', value: string)}>();

    const TOOLTIP = '👋';
</script>

<template>
    <div
        class="menu-card"
        @click="emit('click', option.value)"
    >
        <div class="menu-card__info">
            <span
                :style="{ color: getContrastColor(brandColor ?? '#282829')}"
                class="menu-card__title"
            >
                {{ option.title }}
            </span>
            <span
                :style="{ color: getContrastColor(brandColor ?? '#282829')}"
                class="menu-card__subtitle"
            >
                {{ option.desc }}
            </span>
        </div>
        <div
            v-if="option.value === 'journey'"
            class="menu-card__image"
        >
            <AdvancedImage
                aspect-ratio="1:1"
                :preview="venueManager?.cover ? null : DEFAULT_AVATAR"
                :media="venueManager?.cover"
            />
            <span class="menu-card__image__tooltip">
                {{ TOOLTIP }}
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.menu-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-primary-invert);
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    gap: var(--spacing-04);
    inline-size: 100%;
    min-block-size: 104px;
    padding-inline: var(--spacing-05) var(--spacing-04);

    &:hover {
        background: rgb(255 255 255 / .25);
    }

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-size: var(--font-medium);
        font-weight: var(--font-weight-medium);
    }

    &__subtitle {
        font-size: var(--font-regular);
    }

    &__image {
        position: relative;
        inline-size: 84px;

        :deep(img) {
            border: 1px solid var(--border-primary);
            border-radius: var(--border-radius-circle);
            block-size: 84px;
            inline-size: 84px;
            min-inline-size: 84px;
        }

        &__tooltip {
            position: absolute;
            z-index: var(--zindex-stack-once);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--border-primary);
            border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 var(--border-radius-sm);
            background: var(--background-primary);
            block-size: var(--size-8);
            inline-size: var(--size-8);
            inset-block-start: calc(var(--spacing-01) * -1);
            inset-inline-start: calc(var(--spacing-04) * -1);
        }

    }
}
</style>

