<script setup lang="ts">
    import AppointmentForm from '@/components/AppointmentForm.ce.vue';
    import WidgetCalendar from '@/components/WidgetCalendar.ce.vue';
    import WidgetHours from '@/components/WidgetHours.ce.vue';
    import { DEFAULT_AVATAR } from '@/constants/avatar';
    import { type VenueManagerTyping } from '@/typings/widget';
    import AdvancedImage from '@shared/components/ui/advanced-image.vue';
    import Icon from '@shared/components/ui/icon/icon.vue';
    import { type Ref } from 'vue';

    defineProps<{
        venue: string
        locale: string
        venueManager?: VenueManagerTyping
    }>();

    const emit = defineEmits<{(event: 'back' | 'scrollToTop')}>();

    const { t } = useI18n();

    // Refs
    const calendarModel: Ref<string> = ref('');
    const hoursModel: Ref<string> = ref('');
    const appointmentFormView: Ref<boolean> = ref(false);

    // Hooks
    onMounted(() => {
        emit('scrollToTop');
    });
</script>

<template>
    <div class="appointment">
        <AppointmentForm
            v-if="appointmentFormView"
            :locale="locale"
            :venue="venue"
            :appointment-date="calendarModel"
            :appointment-hour="hoursModel"
            @back="appointmentFormView = false"
            @back-to-main="emit('back')"
        />
        <template v-else>
            <button
                class="appointment__back btn btn--icon btn btn--ghost"
                type="button"
                @click="emit('back')"
            >
                <Icon
                    name="chevronLeft"
                    size="medium"
                />
            </button>
            <div class="appointment__content">
                <div class="appointment__content__calendar">
                    <WidgetCalendar
                        v-model="calendarModel"
                        :offset-days="2"
                    />
                </div>
                <div class="appointment__content__info">
                    <div
                        v-if="calendarModel"
                        class="appointment__content__hours"
                    >
                        <WidgetHours v-model="hoursModel" />
                    </div>
                    <template v-else>
                        <span class="appointment__content__desc">
                            {{ t('widget.appointment.desc') }}
                        </span>
                        <div class="appointment__content__host">
                            <AdvancedImage
                                aspect-ratio="1:1"
                                :preview="venueManager?.cover ? null : DEFAULT_AVATAR"
                                :media="venueManager?.cover"
                            />
                            <span class="appointment__content__host__tooltip">
                                {{ t('widget.appointment.host') }}
                            </span>
                        </div>
                    </template>
                    <button
                        v-if="calendarModel && hoursModel"
                        type="button"
                        class="btn btn--primary appointment__content__make"
                        @click="appointmentFormView = true"
                    >
                        <span>
                            {{ t('widget.buttons.appointment') }}
                        </span>
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.appointment {
    position: relative;
    background-color: var(--background-secondary);
    inline-size: 100%;
    min-block-size: 100%;

    @include min-width(lg) {
        block-size: 100%;
    }

    &__back {
        position: relative;
        inset-block-start: var(--spacing-01);
        inset-inline-start: var(--spacing-01);

        @include min-width(md) {
            position: sticky;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-block: var(--spacing-03);

        @include min-width(md) {
            gap: var(--spacing-05);
        }

        @include min-width(lg) {
            overflow: hidden;
            flex-direction: row;
            block-size: calc(100% - 48px);
            gap: var(--spacing-10);
            padding-block: var(--spacing-01) var(--spacing-05);
            padding-inline: var(--spacing-09);
        }

        &__calendar {
            @include min-width(md) {
                margin-inline: auto;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-05);
            inline-size: 100%;
            padding-block: var(--spacing-03) var(--spacing-14);
            padding-inline: var(--spacing-03);

            @include min-width(md) {
                flex-direction: row;
                align-items: center;
                margin-inline: auto;
                padding-inline: 0;

                > * {
                    flex: 1;
                }
            }

            @include min-width(lg) {
                align-items: flex-start;
                padding: 0;
            }
        }

        &__desc {
            @include min-width(md) {
                inline-size: 100%;
                margin-inline: auto;
                max-inline-size: 468px;
                padding-block: var(--spacing-09);
                padding-inline-end: 234px;
            }

            @include min-width(lg) {
                max-inline-size: auto;
                padding-block: 0;
                padding-inline-end: 0;
            }
        }

        &__hours {
            @include min-width(md) {
                padding-inline: var(--spacing-06);
            }

            @include min-width(lg) {
                overflow: hidden;
                padding: 0;
                block-size: 100%;
            }
        }

        &__host {
            position: fixed;
            display: none;
            inset-block-end: var(--spacing-03);
            inset-inline-end: var(--spacing-03);

            @include min-width(md) {
                display: block;
            }

            :deep(img) {
                border: 1px solid var(--border-primary);
                border-radius: var(--border-radius-circle);
                block-size: 120px;
                inline-size: 120px;
                min-inline-size: 120px;

                @include min-width(lg) {
                    block-size: 80px;
                    inline-size: 80px;
                    min-inline-size: 80px;
                }
            }

            &__tooltip {
                position: absolute;
                z-index: var(--zindex-stack-once);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: var(--spacing-04);
                border: 1px solid var(--border-primary);
                border-radius: var(--border-radius-md) var(--border-radius-md) 0 var(--border-radius-md);
                background: var(--color-pure-white);
                font-weight: var(--font-weight-medium);
                inline-size: 208px;
                inset-block-end: calc(100% - var(--spacing-04));
                inset-inline-end: calc(100% - var(--spacing-05));
                min-inline-size: 208px;

                @include min-width(lg) {
                    font-size: var(--font-regular);
                    inline-size: 200px;
                    min-inline-size: 200px;
                    padding-block: var(--spacing-03);
                    padding-inline: var(--spacing-04);
                }
            }
        }

        &__make {
            position: fixed;
            z-index: 100;
            inline-size: calc(100% - var(--size-16));
            inset-block-end: var(--spacing-05);
            inset-inline-end: 50%;
            transform: translateX(50%);

            @include min-width(md) {
                inline-size: auto;
            }

            @include min-width(lg) {
                inset-inline-end: var(--spacing-07);
                transform: initial;
            }
        }
    }
}
</style>
