<script setup lang="ts">
    import '@/assets/images/illustration-datum.svg';
    import '@/assets/images/illustration-disk.svg';
    import '@/assets/images/illustration-glasses.svg';
    import { useI18n } from 'vue-i18n';

    const props = withDefaults(defineProps<{
        mode?: 'default' | 'wisk' | 'date'
    }>(), { mode: 'default' });

    defineEmits<{(event: 'backToMain')}>();

    const { t } = useI18n();

    const GLASSES_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illustration-glasses.svg`;
    const DISK_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illustration-disk.svg`;
    const DATUM_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illustration-datum.svg`;

    const imageSource = computed(() => {
        switch (props.mode) {
        case 'wisk':
            return DISK_IMG;
        case 'date':
            return DATUM_IMG;
        default:
            return GLASSES_IMG;
        }
    });

    const mainTitle = computed(() => {
        switch (props.mode) {
        case 'wisk':
            return t('widget.titles.dank');
        case 'date':
            return t('widget.titles.requested');
        default:
            return t('widget.titles.amazing');
        }
    });

    const mainSubtitle = computed(() => {
        switch (props.mode) {
        case 'wisk':
            return t('widget.messages.request-success-2');
        case 'date':
            return t('widget.messages.request-success-3');
        default:
            return t('widget.messages.request-success');
        }
    });
</script>

<template>
    <div class="complete-container">
        <img
            :class="{'second': mode === 'wisk', 'third': mode === 'date'}"
            :src="imageSource"
            class="illustration"
        >
        <h1>{{ mainTitle }}</h1>
        <p>{{ mainSubtitle }}</p>
        <a
            class="link-btn"
            @click="$emit('backToMain')"
        >
            {{ t('widget.buttons.back-main') }}
        </a>
    </div>
</template>

<style scoped lang="scss">
.complete-container {
    position: absolute;
    z-index: var(--zindex-stack-twice);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    block-size: 100%;
    inline-size: 100%;
    inset-inline: 0;
    margin-inline: auto;
    max-inline-size: 380px;
    padding-inline: var(--spacing-05);

    @include min-width(md) {
        max-inline-size: 488px;
    }

    .illustration {
        block-size: 180px;
        inline-size: 130px;
        margin-block-end: var(--spacing-03);
        will-change: transform;

        &.second {
            block-size: 130px;
            inline-size: 130px;
        }

        &.third {
            block-size: 139px;
            inline-size: 139px;
        }
    }

    h1 {
        margin-block-end: var(--spacing-02);
    }

    p {
        margin-block-end: var(--spacing-10);
        text-align: center;
    }

    a {
        font-weight: var(--font-weight-medium);
        text-decoration: underline;
    }
}
</style>
