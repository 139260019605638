/*
 * Send warnings and errors to Sentry or whatever error reporting tool we are using.
 * This without having to include the tool in this repo and without having to attach an event to an html element.
 */
export const eventDispatcher = new EventTarget();

/*
 * Types of events we can dispatch
 * Because we use an enum we only need to listen to these events-types
 */
export enum customEventTypesEnum {
    WARNING = 'custom-warning',
    ERROR = 'custom-error',
}

// Dispatch a custom event
export const dispatchCustomEvent = (type: customEventTypesEnum, message: string): void => {
    eventDispatcher.dispatchEvent(new CustomEvent(type, { detail: message }));
};
