<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    const props = defineProps<{
        modelValue: number
        min: number
        max: number
        step: number
        currency?: boolean
    }>();

    const emit =defineEmits<{(event: 'update:modelValue', value: number)}>();

    const trackRef: Ref<HTMLElement | null> = ref(null);
    const rangeModel = ref(props.modelValue ?? 0);
    const rangePercentage = ref(0);
    const inputWidth = ref<number>(0);
    const valueWidth = ref<HTMLElement | null>(null);

    const calculatePercentage = (): void => {
        if (trackRef.value) rangePercentage.value = ((rangeModel.value - props.min) / (props.max - props.min)) * 100;
    };

    const handleInput = ():void => {
        const newValue = Number(rangeModel.value); // Explicitly cast to number

        if (newValue > props.max) {
            rangeModel.value = props.max;
        } else if (newValue < props.min) {
            rangeModel.value = props.min;
        } else {
            rangeModel.value = newValue;
        }

        emit('update:modelValue', rangeModel.value);
    };

    const updateInputWidth = async():Promise<void> => {
        await nextTick(() => {
            if (valueWidth.value) {
                inputWidth.value = valueWidth.value.offsetWidth + 20; // Add some padding
            }
        });
    };

    onMounted(async() => {
        calculatePercentage();
        await updateInputWidth();
    });

    watch(() => props.modelValue, nVal => {
        rangeModel.value = Number(nVal);
    });

    watch(() => rangeModel.value, async() => {
        calculatePercentage();
        await updateInputWidth();
    });
</script>

<template>
    <div class="range-slider">
        <div class="range-slider__text">
            <span
                v-if="currency"
                class="range-slider__text__currency"
            >
                €
            </span>
            <input
                v-model="rangeModel"
                :min="min"
                :max="max"
                :style="{ inlineSize: inputWidth + 'px' }"
                class="range-slider__input"
                type="number"
                @input="handleInput"
            >
            <!-- Hack to dynamically add width to input -->
            <span
                ref="valueWidth"
                class="hidden-value"
            >
                {{ rangeModel }}
            </span>
            <span
                v-if="rangeModel >= max"
                class="range-slider__text__plus"
            >
                +
            </span>
        </div>
        <div
            ref="trackRef"
            :style="{background: `linear-gradient(to right, var(--background-primary-invert) ${rangePercentage}%, var(--background-primary) ${rangePercentage}%)`}"
            class="range-slider__track"
        >
            <input
                v-model="rangeModel"
                class="range-slider__range"
                type="range"
                :min="min"
                :max="max"
                :step="step"
                @input="handleInput"
            >
        </div>
    </div>
</template>

<style lang="scss" scoped>
.range-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-inline-size: 300px;

    @include min-width(md) {
        min-inline-size: 532px;
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;

        &__plus,
        &__currency {
            line-height: 1;
        }

        &__plus {
            font-size: 40px;

            @include min-width(md) {
                font-size: 80px;
            }
        }

        &__currency {
            font-family: var(--font-secondary);
            font-size: 76px;
            margin-inline-end: var(--spacing-02);

            @include min-width(md) {
                font-size: 105px;
            }
        }
    }

    &__input {
        border: none;
        font-family: var(--font-secondary);
        font-size: 76px;
        font-weight: var(--font-weight-medium);
        outline: none;
        text-align: center;

        @include min-width(md) {
            font-size: 105px;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            margin: 0;
            appearance: none;
        }
    }

    &__track {
        display: flex;
        justify-content: center;
        border-radius: var(--border-radius-rounded);
        background: var(--background-primary); /* Initial track color */
        block-size: var(--size-1);
        inline-size: 100%;
        transition: background 0.3s ease;
    }

    &__range {
        border-radius: var(--border-radius-rounded);
        appearance: none;
        background: transparent;
        block-size: var(--size-1);
        inline-size: 100%;
        outline: none;
        transition: all 0.3s ease-in-out;

        &::-webkit-slider-thumb {
            border: 3px solid var(--background-secondary);
            border-radius: var(--border-radius-circle);
            appearance: none;
            background: var(--background-primary-invert);
            block-size: var(--size-8);
            cursor: pointer;
            inline-size: var(--size-8);
            transition: all 0.2s ease-in-out;

            &:hover {
                box-shadow: 0 0 10px rgb(0 0 0 / 0.5);
            }

            &:active {
                transform: scale(1.2);
            }
        }

        &:active::-webkit-slider-thumb {
            transform: scale(1.2);
            transition: transform 0.2s ease;
        }
    }

    .hidden-value {
        position: absolute;
        font-family: var(--font-secondary);
        font-size: 76px;
        visibility: hidden;
        white-space: nowrap;

        @include min-width(md) {
            font-size: 105px;
        }
    }
}
</style>
