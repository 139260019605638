<template>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.37617 2C4.74531 2 4.23389 2.51142 4.23389 3.14229V12.8517H12.2299V3.14229C12.2299 2.51142 11.7185 2 11.0876 2H5.37617ZM5.94729 6.56932C5.63185 6.56932 5.37614 6.82503 5.37614 7.14046V7.71161C5.37614 8.02704 5.63185 8.28275 5.94729 8.28275C6.26272 8.28275 6.51843 8.02704 6.51843 7.71161V7.14046C6.51843 6.82503 6.26272 6.56932 5.94729 6.56932Z"
    />
    <rect
        x="3.09131"
        y="12.1738"
        width="10.2806"
        height="1.14229"
        rx="0.571144"
    />
</template>
