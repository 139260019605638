export enum StyleEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary'
}

export enum OverlayStylesEnum {
    FULLSCREEN = 'fullscreen',
    DRAWER = 'drawer',
    POPUP = 'popup'
}

export enum WidgetPositionEnum {
    TOP_RIGHT = 'top-right',
    TOP_LEFT = 'top-left',
    TOP_CENTER = 'top-center',
    BOTTOM_RIGHT = 'bottom-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_CENTER = 'bottom-center',
}

export enum WidgetTextColorEnum {
    DARK = 'dark',
    LIGHT = 'light',
}
