/*
 * A simple, strongly-typed EventBus utility for a Vue 3 application
 * This uses TypeScript to ensure type safety
 */
export class EventBus<T> {
    private listeners: { [K in keyof T]?: ((payload?: T[K]) => void)[] } = {};

    // Register a listener for an event
    on<K extends keyof T>(event: K, listener: (payload?: T[K]) => void): void {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        (this.listeners[event] ??= []).push(listener);
    }

    // Remove a listener for an event
    off<K extends keyof T>(event: K, listener: (payload: T[K]) => void): void {
        if (!this.listeners[event]) return;
        this.listeners[event] = (this.listeners[event]?.filter(lst => lst !== listener)) ?? [];
    }

    // Emit an event with a payload
    emit<K extends keyof T>(event: K, payload?: T[K]): void {
        if (!this.listeners[event]) return;
        this.listeners[event]?.forEach(listener => { listener(payload) });
    }
}

