<script setup lang="ts">
    import emptyCompany from '@shared/assets/images/empty-business.svg';
    import emptyUser from '@shared/assets/images/empty-user.svg';
    import AdvancedImage from '@shared/components/ui/advanced-image.vue';
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import { type SizeEnum } from '@shared/constants/form-style';
    import { type ImageTyping, type TeamTyping } from '@shared/typings';

    const props = defineProps<{
        isLoading?: boolean
        userInitials?: string | null
        userImage: ImageTyping | null | ''
        size?: SizeEnum
        disabled?: boolean
        unreadNotifications?: boolean
        isMiniature?: boolean
        isUser?: boolean
        isTeam?: boolean
        isCompany?: boolean
        team?: TeamTyping | null
        companyLogo?: ImageTyping | null;
        maxInitialLength?: number;
    }>();

    const { t } = useI18n();

    const MAX_INITIAL_LENGTH = 2;

    const backgroundTeamColor = computed(() => {
        if (props.isTeam && props.team?.color) return props.team.color;
        return '';
    });
</script>

<template>
    <div
        v-if="!isLoading"
        class="avatar"
        :class="{'avatar--miniature': isMiniature }"
    >
        <div
            :class="`avatar__content avatar--${size}`"
            :data-disabled="disabled"
            :style="{'background-color': backgroundTeamColor }"
        >
            <picture v-if="userImage && typeof userImage !== 'string' && userImage.path">
                <AdvancedImage
                    :preview="null"
                    :media="userImage"
                    aspect-ratio="1:1"
                />
            </picture>

            <span
                v-else-if="userInitials && !isTeam"
                class="avatar__initials"
                :class="{'avatar--miniature__initials': isMiniature }"
            >
                <strong>
                    {{ String(userInitials).toUpperCase().substring(0, props.maxInitialLength ?? MAX_INITIAL_LENGTH) }}
                </strong>
            </span>
            <span
                v-else-if="isTeam && team?.abbreviation"
                class="avatar__initials"
                :class="{'avatar--miniature__initials': isMiniature }"
            >
                <strong>
                    {{ team.abbreviation }}
                </strong>
            </span>
            <picture v-else-if="!userImage && !userInitials">
                <img :src="isUser ? emptyUser : emptyCompany">
            </picture>
            <picture v-else-if="isCompany && companyLogo">
                <img
                    :src="companyLogo.path"
                    :alt="t('vma.company.logo')"
                >
            </picture>
        </div>
        <span
            v-if="unreadNotifications && !disabled"
            class="avatar__notification"
            :class="{'avatar--miniature__notification': isMiniature }"
        />
    </div>
    <div
        v-else
        class="avatar"
    >
        <div
            :class="`avatar__content avatar--${size}`"
            :data-loading="isLoading"
        >
            <Skeleton
                height="100%"
                width="100%"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
    // eslint-disable vue-scoped-css/require-selector-used-inside
    .avatar {
        --notification-xl: 12px;
        --notification-standard: 8px;

        position: relative;
        display: flex;
        cursor: pointer;
        max-inline-size: fit-content;

        &__initials {
            display: flex;
            align-items: center;
            justify-content: center;

            @include typography-body-big;
        }

        &__notification {
            position: absolute;
            z-index: var(--zindex-stack-twice);
            border: 1px solid var(--border-primary);
            border-radius: var(--border-radius-circle);
            background-color: var(--background-negative-1);
            block-size: var(--notification-standard);
            inline-size: var(--notification-standard);
            inset-block-start: calc(var(--spacing-02) - 2px); // we need to take out the borders from avatar and notification
            inset-inline-end: 0;
        }

        &__content {
            position: relative;
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--border-primary);
            border-radius: var(--border-radius-circle);
            background-color: var(--background-primary);
            inline-size: var(--avatar-sm);

            &:focus {
                border-width: 2px;
            }

            &::after {
                display: block;
                content: '';
                padding-block-end: 100%; /* Maintain a 1:1 aspect ratio */
            }

            &[data-loading=true] {
                border: 2px solid var(--color-white);
            }

            &[data-disabled=true] {
                border-color: var(--border-disabled-1);
                background-color: var(--background-disabled-1);
                cursor: not-allowed;
                pointer-events: none;

                .avatar__initials {
                    color: var(--text-disabled-1);
                }

                &:not(.standard-avatar__initials) {
                    img {
                        opacity: .4;
                    }
                }
            }

            img,
            span {
                position: absolute;
                block-size: 100%;
                color: var(--text-primary);
                inline-size: 100%;
                inset-block-start: 0;
                inset-inline-start: 0;
                object-fit: cover;
            }
        }

        &__content.avatar--xl,
        &__content.avatar--xxl  {
            .avatar__initials {
                @include typography-subtitle;
            }

            + .avatar__notification {
                block-size: var(--notification-xl);
                inline-size: var(--notification-xl);
                inset-inline-end: 2px;
            }
        }

        // eslint-disable vue-scoped-css/require-selector-used-inside
        .avatar__content.avatar--xxsm {
            block-size: var(--avatar-xxsm);
            inline-size: var(--avatar-xxsm);

            .avatar__initials {
                font-size: var(--avatar-font-sm);
            }
        }

        .avatar__content.avatar--xsm {
            block-size: var(--avatar-xsm);
            inline-size: var(--avatar-xsm);

            .avatar__initials {
                font-size: var(--avatar-font-sm);
            }
        }

        .avatar__content.avatar--sm {
            block-size: var(--avatar-sm);
            inline-size: var(--avatar-sm);

            .avatar__initials {
                font-size: var(--avatar-font-sm);
            }
        }

        .avatar__content.avatar--md {
            block-size: var(--avatar-md);
            inline-size: var(--avatar-md);

            .avatar__initials {
                font-size: var(--avatar-font-md);
            }
        }

        .avatar__content.avatar--lg {
            block-size: var(--avatar-lg);
            inline-size: var(--avatar-lg);

            .avatar__initials {
                font-size: var(--avatar-font-lg);
            }
        }

        .avatar__content.avatar--xl {
            block-size: var(--avatar-xl);
            inline-size: var(--avatar-xl);

            .avatar__initials {
                font-size: var(--avatar-font-xl);
            }
        }

        .avatar__content.avatar--xxl {
            block-size: var(--avatar-xxl);
            inline-size: var(--avatar-xxl);

            .avatar__initials {
                font-size: var(--avatar-font-xxl);
            }
        }

        @include min-width(md) {
            .avatar__content { inline-size: var(--avatar-md); }
        }

        @include min-width(lg) {
            .avatar__content { inline-size: var(--avatar-lg); }
        }
    }

    .avatar.avatar--miniature {
        --avatar-xl: 32px;
        --avatar-lg: 32px;
        --avatar-md: 28px;
        --avatar-sm: 24px;
        --notification-standard: 8px;

        &__initials {
            @include typography-body-normal
        }

        &__notification {
            inset-block-start: 0; // we need to take out the borders from avatar and notification
        }
    }

    // eslint-enable vue-scoped-css/require-selector-used-inside
</style>
