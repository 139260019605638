<template>
    <path d="M14.7164 7.57143C12.6727 6.4981 11.6423 4.55524 11.1402 2.6806C11.055 2.41738 10.7772 2 10.4992 2C10.2214 2 9.94362 2.41738 9.85825 2.6806C9.35616 4.55536 8.32726 6.4981 6.28357 7.57143C6.1151 7.66429 6 7.82512 6 7.99929C6 8.17345 6.1154 8.33429 6.28372 8.42714C8.32741 9.50048 9.35646 11.4448 9.85855 13.3194C9.94377 13.5827 10.2217 14 10.4995 14C10.7774 14 11.0552 13.5826 11.1405 13.3194C11.6426 11.4448 12.673 9.50048 14.7167 8.42714C14.8852 8.33429 15.0002 8.17345 15 7.99929C15 7.82512 14.8849 7.66429 14.7164 7.57143Z" />

    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.99973 9C3.85115 9 3.7477 9.06517 3.69708 9.10535C3.64452 9.14706 3.60884 9.19294 3.58685 9.22458C3.54209 9.28897 3.50858 9.36152 3.48537 9.41706C3.44896 9.50416 3.41166 9.61803 3.39697 9.66291L3.39696 9.66292L3.39089 9.68123L3.38775 9.68998L3.38512 9.69886C3.22153 10.2511 2.90265 10.7669 2.31298 11.0468L2.3059 11.0504C2.15261 11.1268 2 11.2836 2 11.4998C2 11.7162 2.15303 11.873 2.30578 11.9492L2.31308 11.9526C2.90267 12.2326 3.22161 12.7489 3.38523 13.3011L3.38783 13.3099L3.39092 13.3185L3.39713 13.3373C3.41192 13.3825 3.44897 13.4957 3.48547 13.583C3.50869 13.6385 3.54221 13.7111 3.58698 13.7755C3.60897 13.8071 3.64465 13.853 3.69722 13.8947C3.74784 13.9348 3.85128 14 3.99984 14C4.14842 14 4.25186 13.9348 4.30249 13.8947C4.35505 13.8529 4.39073 13.8071 4.41272 13.7754C4.45748 13.711 4.49098 13.6385 4.5142 13.5829C4.55061 13.4958 4.58791 13.382 4.6026 13.3371L4.60868 13.3188L4.61182 13.31L4.61445 13.3011C4.77802 12.749 5.09742 12.2327 5.68712 11.9527L5.69421 11.9492C5.84759 11.8727 6.00006 11.7159 6 11.4998C6 11.2836 5.84743 11.1267 5.69414 11.0503L5.68698 11.0469C5.09719 10.7669 4.77788 10.2509 4.61434 9.69886L4.61173 9.69007L4.60863 9.68141L4.60248 9.66284C4.58773 9.61777 4.5506 9.5043 4.51413 9.41706C4.49091 9.36151 4.45739 9.28895 4.41261 9.22454C4.39061 9.19289 4.35492 9.14701 4.30234 9.1053C4.25168 9.06511 4.14825 9 3.99973 9Z"
    />

    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.99973 2C3.85115 2 3.7477 2.06517 3.69708 2.10535C3.64452 2.14706 3.60884 2.19294 3.58685 2.22458C3.54209 2.28897 3.50858 2.36152 3.48537 2.41706C3.44896 2.50416 3.41166 2.61803 3.39697 2.66291L3.39696 2.66292L3.39089 2.68123L3.38775 2.68998L3.38512 2.69886C3.22153 3.25108 2.90265 3.76689 2.31298 4.04685L2.3059 4.05038C2.15261 4.12676 2 4.28359 2 4.49977C2 4.71625 2.15303 4.87299 2.30578 4.94916L2.31308 4.95263C2.90267 5.23256 3.22161 5.74886 3.38523 6.30114L3.38783 6.30991L3.39092 6.31855L3.39713 6.33729C3.41192 6.38248 3.44897 6.4957 3.48547 6.58298C3.50869 6.63852 3.54221 6.71107 3.58698 6.77546C3.60897 6.8071 3.64465 6.85297 3.69722 6.89468C3.74784 6.93485 3.85128 7 3.99984 7C4.14842 7 4.25186 6.93483 4.30249 6.89465C4.35505 6.85294 4.39073 6.80706 4.41272 6.77542C4.45748 6.71103 4.49098 6.63848 4.5142 6.58294C4.55061 6.49584 4.58791 6.38195 4.6026 6.33708L4.60868 6.31877L4.61182 6.31002L4.61445 6.30114C4.77802 5.74902 5.09742 5.23268 5.68712 4.95269L5.69421 4.94916C5.84759 4.87274 6.00006 4.71586 6 4.49977C6 4.28359 5.84743 4.12669 5.69414 4.05031L5.68698 4.04691C5.09719 3.76689 4.77788 3.25086 4.61434 2.69886L4.61173 2.69007L4.60863 2.68141L4.60248 2.66284C4.58773 2.61777 4.5506 2.5043 4.51413 2.41706C4.49091 2.36151 4.45739 2.28895 4.41261 2.22454C4.39061 2.19289 4.35492 2.14701 4.30234 2.1053C4.25168 2.06511 4.14825 2 3.99973 2Z"
    />
</template>
