<script setup lang="ts">

    import '@/assets/images/illu_step1.svg';
    import '@/assets/images/illu_step1_mob.svg';
    import '@/assets/images/illu_step2.svg';
    import '@/assets/images/illu_step2_mob.svg';
    import '@/assets/images/illu_step3.svg';
    import '@/assets/images/illu_step3_mob.svg';
    import { type TutorialStepTyping } from '@/typings/widget';
    import { ConstantsBreakpoints } from '@shared/constants';
    import TutorialCard from './TutorialCard.ce.vue';

    const emit = defineEmits<{(event: 'next')}>();

    const { t } = useI18n();
    const { width } = useWindowSize();

    // Constants
    const STEP_1_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illu_step1.svg`;
    const STEP_1_IMG__MOB = `${import.meta.env.VITE_CDN_URL}/assets/illu_step1_mob.svg`;
    const STEP_2_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illu_step2.svg`;
    const STEP_2_IMG__MOB = `${import.meta.env.VITE_CDN_URL}/assets/illu_step2_mob.svg`;
    const STEP_3_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illu_step3.svg`;
    const STEP_3_IMG__MOB = `${import.meta.env.VITE_CDN_URL}/assets/illu_step3_mob.svg`;

    // Refs
    const activeStep = ref(1);

    // Computed
    const steps: ComputedRef<TutorialStepTyping[]> = computed(() => [
        {
            index: 1,
            title: t('widget.tutorial.step-1.title'),
            description: t('widget.tutorial.step-1.desc'),
            image: STEP_1_IMG,
            imageMobile: STEP_1_IMG__MOB,
            button: t('widget.tutorial.step-1.button'),
        },
        {
            index: 2,
            title: t('widget.tutorial.step-2.title'),
            description: t('widget.tutorial.step-2.desc'),
            image: STEP_2_IMG,
            imageMobile: STEP_2_IMG__MOB,
            button: t('widget.tutorial.step-2.button'),
        },
        {
            index: 3,
            title: t('widget.tutorial.step-3.title'),
            description: t('widget.tutorial.step-3.desc'),
            image: STEP_3_IMG,
            imageMobile: STEP_3_IMG__MOB,
            button: t('widget.tutorial.step-3.button'),
        },
    ]);
    const isTablet = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.TABLET));

    // Methods
    const handleNextStep = (): void => {
        if (activeStep.value === steps.value.length) {
            emit('next');
            return;
        }
        activeStep.value = activeStep.value + 1;
    };

    onBeforeUnmount(() => {
        activeStep.value = 1;
    });
</script>

<template>
    <div class="tutorial">
        <template
            v-for="step in steps"
            :key="step.index"
        >
            <TutorialCard
                v-if="activeStep === step.index"
                :step="step"
                :total="steps.length"
                @next="handleNextStep"
            />
        </template>
        <button
            v-if="activeStep !== steps.length"
            type="button"
            :class="{'btn--thin': !isTablet}"
            class="btn btn--secondary tutorial__skip"
        >
            <span>
                {{ t('widget.tutorial.skip') }}
            </span>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.tutorial {
    position: relative;
    background: var(--background-primary);
    block-size: 100%;
    inline-size: 100%;

    &__skip {
        position: absolute;
        inset-block: var(--spacing-04) auto;
        inset-inline-end: var(--spacing-03);

        @include min-width(lg) {
            inset-block: auto var(--spacing-06);
            inset-inline-end: var(--spacing-05);
        }
    }
}
</style>
