<script setup lang="ts">
    import { DESCRIPTIONS } from '@/constants/descriptions';
    import { SURVEY_APPOINTMENT, SURVEY_ORGANIZER, SURVEY_THEME } from '@/constants/survey';
    import formKitConfig from '@/formkit';
    import { formatCurrencyNoCent } from '@/helpers/formatting';
    import { type FormKitNode } from '@formkit/core';
    import { defaultConfig, FormKitMessages, FormKitProvider, FormKitRoot } from '@formkit/vue';
    import DatePicker from '@shared/components/form/date-picker.vue';
    import Email from '@shared/components/form/email.vue';
    import NumberInput from '@shared/components/form/number-input.vue';
    import PhoneNumber from '@shared/components/form/phone-number-input.vue';
    import TextInput from '@shared/components/form/text-input.vue';
    import { ConstantsDateTime } from '@shared/constants';
    import { DateTime } from '@shared/helpers';
    import { dayjsObject } from '@shared/helpers/date-time';
    import { type IdTyping } from '@shared/typings';
    import axios from 'axios';
    import { type Ref } from 'vue';

    const props = defineProps<{
        venue: string
        packageId: IdTyping | string
        locale: string
        guestAmountFilter: string | number
    }>();

    const emit = defineEmits<{(event: 'processRequest', type: unknown), (event: 'scrollToTop')}>();

    const { t } = useI18n();

    // Stores
    const {
        bookerName,
        selectedDate,
        guestAmount,
        surveyOrganizer,
        surveyTheme,
        descriptionIds,
        budgetAmount,
        appointmentDate,
        surveyAppointment,
    } = storeToRefs(useWidgetPathStore());

    // Refs
    const formContainer = ref(null);
    const form = ref(null);
    const formData: Ref<{
        eventDate: string | null
        guestAmount: number | string | null
        name: string | null
        surname: string | null
        companyName: string | null
        email: string | null
        phoneCountryPrefix: string | null
        phoneNumber: string | null
    }> = ref({
        eventDate: selectedDate.value ?? null,
        guestAmount: guestAmount.value ?? props.guestAmountFilter ?? null,
        name: bookerName.value ?? null,
        surname: null,
        companyName: null,
        email: null,
        phoneCountryPrefix: '+31',
        phoneNumber: null,
    });

    // Computed
    const organizationType = computed(() => t(SURVEY_ORGANIZER.find(org => org.value === surveyOrganizer.value)?.label ?? ''));
    const theme = computed(() => t(SURVEY_THEME.find(thm => thm.value === surveyTheme.value)?.label ?? ''));
    const flexibility = computed(() => t(SURVEY_APPOINTMENT.find(app => app.value === surveyAppointment.value)?.label ?? ''));
    const descriptions = computed(() =>
        descriptionIds.value
            .map(id => t(DESCRIPTIONS.find(desc => desc.id === id)?.name ?? ''))
            .join(', ') ?? '');
    const budget = computed(() => formatCurrencyNoCent(
        Number(budgetAmount.value), 'EUR', props.locale,
    ));
    const appointment = computed(() => appointmentDate.value
        ? dayjsObject(appointmentDate.value)
            .format('DD MMMM, YYYY')
        : '');

    // Methods
    const messageBuilder = (): string => `
                - Organization type: ${organizationType.value}\n\n
                - Theme: ${theme.value}\n\n
                - Flexibility: ${flexibility.value}\n\n
                - Descriptions: ${descriptions.value}\n\n
                - Budget: ${budget.value}\n\n
                - Appointment Date: ${appointment.value}\n\n
            `;

    const processForm = async(submittedData: typeof formData.value, formNode: FormKitNode): Promise<void> => {
        const payload = {
            ...submittedData,
            venuePackageId: props.packageId,
            message: messageBuilder(),
            userName: `${submittedData.name} ${submittedData.surname}`,
            name: null,
            surname: null,
        };

        try {
            const response = await axios
                .post(
                    `${import.meta.env.VITE_API_BASE_URL}/widget/api/venue/${props.venue}/quote`, payload, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Accept-Language': props.locale,
                        },
                    },
                );
            emit('processRequest', response.data);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Posting request form failed: ', error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const msg = t('widget.messages.errors.request-failed');
            formNode.setErrors([msg]);
        }
    };

    onMounted(() => {
        emit('scrollToTop');
    });
</script>

<template>
    <div
        ref="formContainer"
        class="book-form-view"
    >
        <div class="book-form-view-header">
            <div class="book-form-view-title">
                <h1>{{ t('widget.titles.sent-us-request') }}</h1>
            </div>
            <div class="book-form-view-description">
                {{ t('widget.messages.sent-us-request') }}
            </div>
        </div>
        <div class="book-form-view-body">
            <FormKitProvider :config="defaultConfig(formKitConfig)">
                <FormKitRoot>
                    <FormKit
                        id="request-form"
                        ref="form"
                        v-model="formData"
                        :incomplete-message="false"
                        :submit-label="t('widget.buttons.send')"
                        :submit-attrs="{
                            inputClass: 'btn btn--primary',
                            wrapperClass: 'im-on-the-wrapper',
                            outerClass: 'book-it-btn'
                        }"
                        type="form"
                        @submit="processForm"
                    >
                        <DatePicker
                            id="eventDate"
                            :label="t('widget.form.event-date')"
                            :validation-messages="{
                                required: `${t('widget.form.validations.required', { field: t('widget.form.event-date') })}`
                            }"
                            :min-date="
                                DateTime.dayjsObject().format(ConstantsDateTime.dateFormatCalendar)
                            "
                            name="eventDate"
                            validation="required"
                        />
                        <NumberInput
                            id="guestAmount"
                            name="guestAmount"
                            :placeholder="`${t('widget.form.guest-amount')}*`"
                            :value="Number(props.guestAmountFilter)"
                            :validation-messages="{
                                required: `${t('widget.form.validations.required', { field: t('widget.form.guest-amount') })}`,
                                min: `${t('widget.form.validations.min', { field: t('widget.form.guest-amount') })}`
                            }"
                            validation="number|required|min:1"
                            secondary
                        />
                        <div class="form-section-hrz">
                            <TextInput
                                id="name"
                                :placeholder="t('widget.form.your-name')"
                                :validation-messages="{
                                    required: `${t('widget.form.validations.required', { field: t('widget.form.your-name') })}`
                                }"
                                name="name"
                                validation="required"
                            />
                            <TextInput
                                id="surname"
                                :placeholder="t('widget.form.your-surname')"
                                :validation-messages="{
                                    required: `${t('widget.form.validations.required', { field: t('widget.form.your-surname') })}`
                                }"
                                name="surname"
                                validation="required"
                            />
                        </div>
                        <TextInput
                            id="companyName"
                            :placeholder="t('widget.form.company-name')"
                            name="companyName"
                        />
                        <Email
                            id="email"
                            :placeholder="t('widget.form.email')"
                            :validation-messages="{
                                required: `${t('widget.form.validations.required', { field: t('widget.form.email') })}`
                            }"
                            name="email"
                            validation="required"
                        />
                        <PhoneNumber
                            id="phoneNumber"
                            :validation-messages="{
                                required: `${t('widget.form.validations.required', { field: t('widget.form.phone-number') })}`
                            }"
                            name="phoneNumber"
                            validation="required"
                            placeholder="0 000 000 00"
                        />
                        <FormKitMessages />
                    </FormKit>
                </FormKitRoot>
            </FormKitProvider>
        </div>
    </div>
</template>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.book-form-view {
    margin-block: 48px 0;
    margin-inline: 16px;
    max-inline-size: 488px;
    padding-block-end: var(--spacing-14);

    .book-form-view-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-end: 48px;
    }

    .book-form-view-title {
        font-weight: lighter;
        margin-block-end: 16px;;
        text-align: center;
    }

    .book-form-view-description {
        padding-inline: var(--spacing-07);
        text-align: center;
    }

    .formkit-form > div {
        margin-block-end: var(--spacing-2x);
    }

    .book-form-view-body {
        .form-section-hrz {
            > div:first-child {
                margin-block-end: var(--spacing-2x);
            }

            @include min-width(md) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: var(--spacing-03);

                > div {
                    flex-grow: 1;

                    &:first-child {
                        margin-block-end: 0;
                    }
                }
            }
        }

        :deep(.formkit-actions) {
            position: absolute;
            z-index: 100;

            .book-it-btn {
                position: fixed;
                z-index: 100;
                inline-size: calc(100% - var(--size-16));
                inset-block-end: var(--spacing-05);
                inset-inline-end: 50%;
                transform: translateX(50%);

                button {
                    color: var(--text-primary-invert);
                    font-size: var(--font-medium);
                    font-weight: var(--font-weight-medium);
                    inline-size: 100%;
                    letter-spacing: var(--letter-spacing-0);
                    line-height: var(--line-height-150);
                }

                @include min-width(md) {
                    inline-size: auto;
                }

                @include min-width(lg) {
                    inset-inline-end: var(--spacing-07);
                    transform: initial;
                }
            }
        }

        @include min-width(md) {
            padding-block-end: 0;
        }
    }

    @include min-width(md) {
        margin-inline: auto;
    }
}
</style>
