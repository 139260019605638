<!-- eslint-disable @typescript-eslint/no-unsafe-assignment -->
<script lang="ts" setup>
    import AlignCenter from './paths/align-center.vue';
    import AlignLeft from './paths/align-left.vue';
    import AlignRight from './paths/align-right.vue';
    import ArrowLeft from './paths/arrow-left.vue';
    import ArrowRight from './paths/arrow-right.vue';
    import ArrowWidgetRight from './paths/arrow-widget-right.vue';
    import ArrowsCircle from './paths/arrows-circle.vue';
    import Attached from './paths/attached.vue';
    import Bold from './paths/bold.vue';
    import Burger from './paths/burger.vue';
    import CalculateOutlined from './paths/calculate-outlined.vue';
    import CalendarFilled from './paths/calendar-filled.vue';
    import CalendarOutlined from './paths/calendar-outlined.vue';
    import CallFilled from './paths/call-filled.vue';
    import CallOutlined from './paths/call-outlined.vue';
    import CantseeFilled from './paths/cantsee-filled.vue';
    import CantseeOutlined from './paths/cantsee-outlined.vue';
    import ChatFilled from './paths/chat-filled.vue';
    import ChatOutlined from './paths/chat-outlined.vue';
    import Check from './paths/check.vue';
    import ChevronBottom from './paths/chevron-bottom.vue';
    import ChevronLeft from './paths/chevron-left.vue';
    import ChevronRight from './paths/chevron-right.vue';
    import ChevronTop from './paths/chevron-top.vue';
    import ConstellContrast from './paths/constell-contrast.vue';
    import Constell from './paths/constell.vue';
    import ContactFilled from './paths/contact-filled.vue';
    import ContactOutlined from './paths/contact-outlined.vue';
    import ConversationFilled from './paths/conversation-filled.vue';
    import ConversationOutlined from './paths/conversation-outlined.vue';
    import Crop from './paths/crop.vue';
    import Cross from './paths/cross.vue';
    import Cut from './paths/cut.vue';
    import DateFilled from './paths/date-filled.vue';
    import DateOutlined from './paths/date-outlined.vue';
    import Download from './paths/download.vue';
    import Drag from './paths/drag.vue';
    import Duplicate from './paths/duplicate.vue';
    import EditFilled from './paths/edit-filled.vue';
    import EditOutlined from './paths/edit-outlined.vue';
    import ErrorFilled from './paths/error-filled.vue';
    import ErrorOutlined from './paths/error-outlined.vue';
    import EventFilled from './paths/event-filled.vue';
    import EventsFilled from './paths/events-filled.vue';
    import EventsOutlined from './paths/events-outlined.vue';
    import ExtendOutlined from './paths/extend-outlined.vue';
    import EyeDropper from './paths/eye-dropper.vue';
    import Filter from './paths/filter.vue';
    import FinanceEuroFilled from './paths/finance-eur-filled.vue';
    import FinanceEuroOutlined from './paths/finance-eur-outlined.vue';
    import FinancePoundFilled from './paths/finance-gbp-filled.vue';
    import FinancePoundOutlined from './paths/finance-gbp-outlined.vue';
    import FinanceDollarFilled from './paths/finance-usd-filled.vue';
    import FinanceDollarOutlined from './paths/finance-usd-outlined.vue';
    import Font from './paths/font.vue';
    import Formitable from './paths/formitable.vue';
    import GuestFilled from './paths/guest-filled.vue';
    import GuestOutlined from './paths/guest-outlined.vue';
    import HelpFilled from './paths/help-filled.vue';
    import HelpOutlined from './paths/help-outlined.vue';
    import HourFilled from './paths/hour-filled.vue';
    import HourOutlined from './paths/hour-outlined.vue';
    import ImageFilled from './paths/image-filled.vue';
    import ImageOutlined from './paths/image-outlined.vue';
    import Italic from './paths/italic.vue';
    import Kebab from './paths/kebab.vue';
    import LayoutBoardFilled from './paths/layout-board-filled.vue';
    import LayoutBoardOutlined from './paths/layout-board-outlined.vue';
    import LayoutGridFilled from './paths/layout-grid-filled.vue';
    import LayoutGridOutlined from './paths/layout-grid-outlined.vue';
    import LayoutListFilled from './paths/layout-list-filled.vue';
    import LayoutListingOutlined from './paths/layout-listing-outlined.vue';
    import Less from './paths/less.vue';
    import LocationFilled from './paths/location-filled.vue';
    import LocationOutlined from './paths/location-outlined.vue';
    import LockFilled from './paths/lock-filled.vue';
    import LockOutlined from './paths/lock-outlined.vue';
    import LogOut from './paths/log-out.vue';
    import MailFilled from './paths/mail-filled.vue';
    import MailOutlined from './paths/mail-outlined.vue';
    import Meatballs from './paths/meatballs.vue';
    import NoteFilled from './paths/note-filled.vue';
    import NoteOutlined from './paths/note-outlined.vue';
    import NotesFilled from './paths/notes-filled.vue';
    import NotesOutlined from './paths/notes-outlined.vue';
    import NotificationFilled from './paths/notification-filled.vue';
    import NotificationOutlined from './paths/notification-outlined.vue';
    import PackageFilled from './paths/package-filled.vue';
    import PaymentFilled from './paths/payment-filled.vue';
    import Plus from './paths/plus.vue';
    import ProductFilled from './paths/product-filled.vue';
    import Reinitiate from './paths/reinitiate.vue';
    import RemoveFilled from './paths/remove-filled.vue';
    import RemoveOutlined from './paths/remove-outlined.vue';
    import Safe from './paths/safe.vue';
    import Search from './paths/search.vue';
    import SeeFilled from './paths/see-filled.vue';
    import SeeOutlined from './paths/see-outlined.vue';
    import Sort from './paths/sort.vue';
    import SpaceFilled from './paths/space-filled.vue';
    import Success from './paths/success.vue';
    import Switch from './paths/switch.vue';
    import TaskFilled from './paths/task-filled.vue';
    import TaskOutlined from './paths/task-outlined.vue';
    import TemplateFilled from './paths/template-filled.vue';
    import TicketFilled from './paths/ticket-filled.vue';
    import TodayFilled from './paths/today-filled.vue';
    import TodayOutlined from './paths/today-outlined.vue';
    import Underline from './paths/underline.vue';
    import UnlockFilled from './paths/unlock-filled.vue';
    import UnlockOutlined from './paths/unlock-outlined.vue';
    import Upload from './paths/upload.vue';
    import VenueFilled from './paths/venue-filled.vue';
    import VenueOutlined from './paths/venue-outlined.vue';
    import WatchOutFilled from './paths/watch-out-filled.vue';
    import WatchOutOutlined from './paths/watch-out-outlined.vue';
    import ZoomIn from './paths/zoom-in.vue';
    import ZoomOut from './paths/zoom-out.vue';

    defineProps<{
        name: string
        size?: 'large' | 'medium' | 'small'
        scale?: boolean
        viewBoxWidth?: number
        viewBoxHeight?: number
        positionX?: number
        positionY?: number
    }>();

    const iconComponent = {
        alignCenter: AlignCenter,
        alignLeft: AlignLeft,
        alignRight: AlignRight,
        arrowLeft: ArrowLeft,
        arrowRight: ArrowRight,
        arrowWidgetRight: ArrowWidgetRight,
        arrowsCircle: ArrowsCircle,
        attached: Attached,
        bold: Bold,
        burger: Burger,
        calendarFilled: CalendarFilled,
        calendarOutlined: CalendarOutlined,
        callFilled: CallFilled,
        callOutlined: CallOutlined,
        cantseeFilled: CantseeFilled,
        cantseeOutlined: CantseeOutlined,
        chatFilled: ChatFilled,
        chatOutlined: ChatOutlined,
        check: Check,
        chevronBottom: ChevronBottom,
        chevronLeft: ChevronLeft,
        chevronRight: ChevronRight,
        chevronTop: ChevronTop,
        constell: Constell,
        constellContrast: ConstellContrast,
        contactFilled: ContactFilled,
        contactOutlined: ContactOutlined,
        conversationFilled: ConversationFilled,
        conversationOutlined: ConversationOutlined,
        crop: Crop,
        cross: Cross,
        cut: Cut,
        dateFilled: DateFilled,
        dateOutlined: DateOutlined,
        download: Download,
        drag: Drag,
        duplicate: Duplicate,
        editFilled: EditFilled,
        editOutlined: EditOutlined,
        errorFilled: ErrorFilled,
        errorOutlined: ErrorOutlined,
        eventsFilled: EventsFilled,
        eventsOutlined: EventsOutlined,
        eyeDropper: EyeDropper,
        filter: Filter,
        financeDollarFilled: FinanceDollarFilled,
        financeDollarOutlined: FinanceDollarOutlined,
        financeEuroFilled: FinanceEuroFilled,
        financeEuroOutlined: FinanceEuroOutlined,
        financePoundFilled: FinancePoundFilled,
        financePoundOutlined: FinancePoundOutlined,
        font: Font,
        formitable: Formitable,
        guestFilled: GuestFilled,
        guestOutlined: GuestOutlined,
        helpFilled: HelpFilled,
        helpOutlined: HelpOutlined,
        imageFilled: ImageFilled,
        imageOutlined: ImageOutlined,
        italic: Italic,
        kebab: Kebab,
        layoutBoardFilled: LayoutBoardFilled,
        layoutBoardOutlined: LayoutBoardOutlined,
        layoutGridFilled: LayoutGridFilled,
        layoutGridOutlined: LayoutGridOutlined,
        layoutListFilled: LayoutListFilled,
        layoutListingOutlined: LayoutListingOutlined,
        less: Less,
        logOut: LogOut,
        meatballs: Meatballs,
        notificationFilled: NotificationFilled,
        notificationOutlined: NotificationOutlined,
        plus: Plus,
        reinitiate: Reinitiate,
        removeFilled: RemoveFilled,
        removeOutlined: RemoveOutlined,
        safe: Safe,
        search: Search,
        seeFilled: SeeFilled,
        seeOutlined: SeeOutlined,
        sort: Sort,
        success: Success,
        switch: Switch,
        todayFilled: TodayFilled,
        todayOutlined: TodayOutlined,
        underline: Underline,
        upload: Upload,
        venueFilled: VenueFilled,
        venueOutlined: VenueOutlined,
        watchOutFilled: WatchOutFilled,
        watchOutOutlined: WatchOutOutlined,
        zoomIn: ZoomIn,
        zoomOut: ZoomOut,
        calculateOutlined: CalculateOutlined,
        eventFilled: EventFilled,
        extendOutlined: ExtendOutlined,
        hourFilled: HourFilled,
        hourOutlined: HourOutlined,
        locationFilled: LocationFilled,
        locationOutlined: LocationOutlined,
        lockFilled: LockFilled,
        lockOutlined: LockOutlined,
        mailFilled: MailFilled,
        mailOutlined: MailOutlined,
        noteFilled: NoteFilled,
        noteOutlined: NoteOutlined,
        notesFilled: NotesFilled,
        notesOutlined: NotesOutlined,
        packageFilled: PackageFilled,
        paymentFilled: PaymentFilled,
        productFilled: ProductFilled,
        spaceFilled: SpaceFilled,
        taskFilled: TaskFilled,
        taskOutlined: TaskOutlined,
        templateFilled: TemplateFilled,
        ticketFilled: TicketFilled,
        unlockFilled: UnlockFilled,
        unlockOutlined: UnlockOutlined,
    };
</script>

<template>
    <div
        class="icon"
        :class="[size, {'scale': scale}]"
    >
        <svg
            :viewBox="`${positionX ?? 0} ${positionY ?? 0} ${viewBoxWidth ?? '16'} ${viewBoxHeight ?? '16'}`"
            xmlns="http://www.w3.org/2000/svg"
            class="icon__svg"
        >
            <Component :is="iconComponent[name]" />
        </svg>
    </div>
</template>

<style lang="scss" scoped>
    // eslint-disable-next-line vue-scoped-css/require-selector-used-inside
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        block-size: var(--size-7);
        color: currentcolor;
        fill: currentcolor;
        inline-size: var(--size-7);
        min-inline-size: var(--size-7);

        svg {
            block-size: var(--size-7);
            inline-size: var(--size-7);
            min-inline-size: var(--size-7);
        }

        &.large {
            block-size: (var(--size-9));
            inline-size: var(--size-9);
            min-inline-size: var(--size-9);

            svg {
                block-size: var(--size-9);
                inline-size: var(--size-9);
                min-inline-size: var(--size-9);
            }
        }

        &.medium {
            block-size: var(--size-3);
            inline-size: var(--size-3);
            min-inline-size: var(--size-3);

            svg {
                block-size: var(--size-3);
                inline-size: var(--size-3);
                min-inline-size: var(--size-3);
            }
        }

        &.small {
            block-size: var(--size-1);
            inline-size: var(--size-1);
            min-inline-size: var(--size-1);

            svg {
                block-size: var(--size-1);
                inline-size: var(--size-1);
                min-inline-size: var(--size-1);
            }
        }
    }
</style>
