/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable complexity */
import { crop, fill, scale } from '@cloudinary/url-gen/actions/resize';
import { Cloudinary, type CloudinaryImage } from '@cloudinary/url-gen/index';
import { ar1X1 } from '@cloudinary/url-gen/qualifiers/aspectRatio';
import { xyCenter } from '@cloudinary/url-gen/qualifiers/gravity';
import { type ImageTyping, type MediaTyping } from '@shared/typings/general';

// eslint-disable-next-line max-statements
export const useMedia = (
    media: MediaTyping | null, aspectRatio?: string, assetWidth?: number, noCrop?: boolean,
): CloudinaryImage | null => {
    if (!media) return null;

    // Init Cloudinary Image
    const cld = new Cloudinary({ cloud: { cloudName: String(import.meta.env.VITE_CLOUDINARY_CLOUDNAME ?? '') } });
    const image: CloudinaryImage | null = cld.image(media.path);

    // Set Actions
    const cropAction = crop();
    const scaleAction = scale(assetWidth);

    // Validate Crop
    if (!noCrop) {
        if (media.width) cropAction.width(media.width);
        if (media.height) cropAction.height(media.height);
        if (media.x) cropAction.x(media.x);
        if (media.y) cropAction.y(media.y);
    }

    if (aspectRatio) cropAction.aspectRatio(aspectRatio);

    // Validate Scale
    if (assetWidth) {
        image.resize(cropAction)
            .resize(scaleAction);
    } else {
        image.resize(cropAction);
    }

    return image;
};

export const useProfileImage = (image: ImageTyping | null): CloudinaryImage | null => {
    if (!image) return null;
    const cld = new Cloudinary({ cloud: { cloudName: String(import.meta.env.VITE_CLOUDINARY_CLOUDNAME ?? '') } });

    const resizeAction = fill()
        .aspectRatio(ar1X1());

    const profileImage = cld.image(image.path)
        .resize(resizeAction.gravity(xyCenter()));

    return profileImage;
};
