<script setup lang="ts">
    import { ConstantsUi } from '@shared/constants';

    /* eslint-disable vue/max-len */
    const props = withDefaults(defineProps<{
        width?: string,
        height?: string,
        isImage?: boolean,
        style?: ConstantsUi.SkeletonStyleEnum.PARAGRAPH | ConstantsUi.SkeletonStyleEnum.HEADER | ConstantsUi.SkeletonStyleEnum.CIRCLE | ConstantsUi.SkeletonStyleEnum.SQUARE | null,
    }>(), {
        width: '100%',
        height: '1rem',
        style: null,
    });
    /* eslint-enable vue/max-len */

    // Add modifier classes to skeleton
    const modifiers = computed(() => {
        let modifierClasses = '';
        if (props.isImage) modifierClasses += ' skeleton--image';
        if (props.style === ConstantsUi.SkeletonStyleEnum.HEADER) modifierClasses += ' skeleton--header';
        if (props.style === ConstantsUi.SkeletonStyleEnum.PARAGRAPH) modifierClasses += ' skeleton--paragraph';
        if (props.style === ConstantsUi.SkeletonStyleEnum.CIRCLE) modifierClasses += ' skeleton--circle';
        if (props.style === ConstantsUi.SkeletonStyleEnum.SQUARE) modifierClasses += ' skeleton--square';
        return modifierClasses;
    });

    const isShape = computed(() => props.style === ConstantsUi.SkeletonStyleEnum.SQUARE || props.style === ConstantsUi.SkeletonStyleEnum.CIRCLE);
</script>

<template>
    <div
        data-component="skeleton"
        class="skeleton"
        :style="{blockSize: height}"
    >
        <span
            v-if="isShape"
            class="skeleton__inner"
            :class="modifiers"
            :style="{'--width-height': width}"
        />
        <span
            v-else
            class="skeleton__inner"
            :class="modifiers"
            :style="{'--width': width, '--height': height}"
        />
    </div>
</template>

<style scoped lang="scss">
.skeleton {
    display: inline-block;
    block-size: inherit;
    inline-size: 100%;

    &__inner {
        display: inline-block;
        border-radius: var(--border-radius-xs);
        /* stylelint-disable-next-line no-unknown-animations */
        animation: skeleton-loading 3s linear infinite;
        background-color: var(--color-neutral-70);
        background-image: linear-gradient(45deg, var(--color-neutral-70), var(--color-neutral-90), var(--color-neutral-70));
        background-repeat: no-repeat;
        background-size: 2000px 100%;
        block-size: var(--height); /* Default to width if height is not provided */
        inline-size: var(--width);
        line-height: 1;
        max-block-size: var(--height);

        &.skeleton--circle {
            border-radius: 50%; /* Make it a circle */
            aspect-ratio: 1 / 1;
            block-size: var(--width-height);
            inline-size: var(--width-height);
        }

        &.skeleton--image {
            block-size: 100%;
        }

        // TODO check this to be consistent header depending on device
        &.skeleton--header {
            block-size: 26px;
            inline-size: 200px;

            @include min-width(lg) {
                block-size: 46px;
                inline-size: 300px;
            }
        }

        &.skeleton--paragraph {
            block-size: var(--size-1);
        }

        &.skeleton--square {
            // Additional styles for square modifier
            position: relative;
            aspect-ratio: 1 / 1;
            block-size: var(--width-height);
            inline-size: var(--width-height);
            padding-block-end: 100%;
        }
    }
}
</style>
