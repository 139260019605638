<script setup lang="ts">
    // eslint-disable vue/no-useless-template-attributes
    import { type FormKitNode } from '@formkit/core';
    import { type FormKitOptionsProp } from '@formkit/inputs';
    import { getValidationMessages } from '@formkit/validation';
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import Avatar from '@shared/components/user/avatar/avatar.vue';
    import { ConstantsBreakpoints } from '@shared/constants';
    import { FormSizeTypeEnum, FormStyleEnum, SizeEnum } from '@shared/constants/form-style';
    import { type Ref } from 'vue';

    const props = defineProps<{
        name: string
        label?: string
        options?: FormKitOptionsProp
        placeholder?: string
        help?: string
        validation?: string | [rule: string, ...args: unknown[]][]
        disabled?: boolean
        style?: FormStyleEnum.PRIMARY | FormStyleEnum.SECONDARY | FormStyleEnum.TERTIARY
        size?: FormSizeTypeEnum
        multiple?: boolean
        prefixIcon?: string
        labelHidden?: boolean
        canNotDeselect?: boolean
        extractErrorMessages?: boolean
        validationMessages?: Record<string, string>
        isLoading?: boolean
        id?: string
        rightDisplay?: boolean
        defaultValue?: string
    }>();

    const emit = defineEmits<{(event: 'errorFound' | 'commit' | 'input', value: (string | number | boolean)[])}>();

    const { width } = useWindowSize();

    const isRequired = computed(() =>
        Array.isArray(props.validation) ? props.validation[0].includes('required') : props.validation?.includes('required'));
    const dropdownInput = ref();
    const errorMessages: Ref<(string | number | boolean)[]> = ref([]);
    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const isDesktop = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.DESKTOP));
    const skeletonHeight = computed(() => isMobile.value ? '56px' : isDesktop.value ? '64px' : '60px');

    const extractErrors = (): void => {
        if (props.extractErrorMessages && 'node' in dropdownInput.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const validations = getValidationMessages(dropdownInput.value.node as FormKitNode);
            errorMessages.value = [];
            validations.forEach(inputMessages => {
                errorMessages.value = errorMessages.value.concat(inputMessages.map(message => message.value) as (string | number | boolean)[]);
            });
            emit('errorFound', errorMessages.value);
        }
    };

    const onInput = (): void => {
        emit('input', []);
        extractErrors();
    };
</script>

<template>
    <div
        class="dropdown"
        :data-required="(isRequired as boolean)"
        :style="{minBlockSize: skeletonHeight}"
    >
        <Skeleton
            v-if="isLoading"
            :height="skeletonHeight"
        />
        <FormKit
            v-else
            :id="id"
            ref="dropdownInput"
            type="dropdown"
            :label="label"
            :name="name"
            :options="options"
            :placeholder="placeholder"
            :help="help"
            :validation="validation"
            :inner-class="style ?? FormStyleEnum.PRIMARY"
            :wrapper-class="size ?? FormSizeTypeEnum.NORMAL"
            :disabled="disabled"
            :multiple="multiple"
            :prefix-icon="prefixIcon"
            :label-class="labelHidden ? 'formkit-label--hidden' : ''"
            :messages-class="extractErrorMessages ? 'formkit-messages--hidden' : ''"
            :deselect="canNotDeselect ? false : true"
            :dropdown-wrapper-class="rightDisplay ? 'dropdown__list right-display' : 'dropdown__list'"
            :value="multiple ? defaultValue ? [defaultValue] : [] : defaultValue"
            :validation-messages="validationMessages"
            placeholder-class="dropdown__placeholder"
            select-icon="chevronBottom"
            selected-icon="check"
            selector-class="dropdown__selector"
            select-icon-class="dropdown__icon"
            select-class="test"
            option-class="dropdown__option"
            @blur="extractErrors"
            @input="onInput"
        >
            <!-- eslint-disable vue/no-useless-template-attributes -->
            <template
                #selection="{ option }"
                selected-icon="check"
            >
                <div
                    v-if="option.asset"
                    class="user-image"
                >
                    <Avatar
                        :size="SizeEnum.XSMALL"
                        :user-initials="option.initials"
                        :user-image="option.asset"
                    />
                </div>
                <span>{{ option.label }}</span>
            </template>
            <template
                #option="{ option }"
                selected-icon="check"
            >
                <div
                    v-if="option?.asset"
                    class="user-image"
                >
                    <Avatar
                        :user-initials="option.initials"
                        :user-image="option.asset"
                    />
                </div>
                <div
                    v-if="option?.writtenAsset || option?.label"
                    class="option"
                >
                    <span v-if="option?.label">{{ option?.label }}</span>
                    <div
                        v-if="option?.writtenAsset"
                        class="ml-3"
                    >
                        <span>{{ option.writtenAsset }}</span>
                    </div>
                </div>
            </template>
            <!-- eslint-enable vue/no-useless-template-attributes -->
        </FormKit>
    </div>
</template>

<style lang="scss" scoped>
    .dropdown :deep(.formkit-outer) {
        * {
            box-sizing: border-box;
        }

        .formkit-inner {
            button {
                position: relative;
                box-sizing: border-box;
                padding: 0;
                block-size: var(--size-11);
                inline-size: 100%;

                .formkit-selections-wrapper,
                .formkit-placeholder {
                    flex: 2;
                    font-size: var(--font-medium);
                    text-align: start;
                }

                .formkit-selections-wrapper {
                    overflow: hidden;
                    background-color: var(--background-form-input-active);
                    inline-size: 85%;
                }

                .formkit-selections {
                    display: inline-flex;
                    align-items: center;
                    gap: var(--spacing-01);
                }

                .formkit-selections-item,
                .formkit-truncation-count {
                    font-size: var(--font-medium);
                }

                .formkit-truncation-count {
                    margin-inline-end: var(--spacing-01);
                }

                .formkit-placeholder,
                .formkit-selection-wrapper,
                .formkit-selections-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: var(--spacing-03);
                    border: 1px solid var(--border-tertiary);
                    border-radius: var(--border-radius-xs);
                    background-color: var(--background-form-input);
                    block-size: 100%;
                    inline-size: 100%;
                    padding-inline-end: var(--spacing-07);
                }

                .formkit-placeholder {
                    color: var(--text-placeholder);
                    white-space: nowrap;
                }

                .formkit-selection-wrapper {
                    background-color: var(--background-form-input-active);

                    span {
                        font-size: var(--font-medium);
                        line-height: 1;
                    }
                }

                .formkit-select-icon {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    inset-block-start: 50%;
                    inset-inline-end: var(--spacing-03);
                    transform: translateY(-50%);
                }

                &:hover {
                    .formkit-placeholder,
                    .formkit-selection-wrapper,
                    .formkit-selections-wrapper {
                        background-color: var(--background-form-input-active);
                    }
                }

                @include min-width(md) {
                    block-size: var(--size-12);
                }

                @include min-width(lg) {
                    block-size: var(--size-13);
                }
            }

            &.secondary button {
                &:hover {
                    .formkit-placeholder,
                    .formkit-selection-wrapper,
                    .formkit-selections-wrapper {
                        background-color: var(--background-form-input-hover);
                    }
                }
            }

            .dropdown__list {
                overflow: hidden;
                border: 1px solid var(--border-primary);
                border-radius: var(--border-radius-xs);
                background-color: var(--background-primary);
                margin-block: var(--spacing-02);
                visibility: hidden;

                ul {
                    min-inline-size: 19rem;
                }

                ul li {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    min-block-size: var(--size-11);
                    padding-block: 0;
                    padding-inline: var(--spacing-03);

                    .option {
                        display: flex;
                        flex: 2;
                        align-items: center;
                        justify-content: flex-start;
                    }

                    .option span {
                        font-size: var(--font-regular);
                    }

                    &:not(:last-child) {
                        border-block-end: 1px solid var(--border-quaternary);
                    }

                    &[aria-selected=true] {
                        background-color: var(--background-primary-highlight);
                        padding-inline-end: var(--spacing-01);
                    }

                    &[aria-selected=true] .formkit-selected-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &[aria-selected=true] .formkit-selected-icon > svg {
                        block-size: var(--size-9);
                        inline-size: var(--size-9);
                    }

                    &:hover {
                        background-color: var(--background-primary-highlight);
                    }
                }

                &.right-display {
                    inset-inline-end: 0;
                }
            }
        }

        &[data-expanded=true] .formkit-inner {
            /* stylelint-disable-next-line no-descending-specificity */
            button {
                .formkit-placeholder,
                .formkit-selection-wrapper,
                .formkit-selections-wrapper {
                    background-color: var(--background-form-input-active);
                }
            }
            /* stylelint-disable-next-line no-descending-specificity */
            button .formkit-select-icon > svg {
                transform: rotate(180deg);
            }

            .dropdown__list {
                visibility: visible;
            }
        }

        &[data-invalid=true] .formkit-inner {
            button,
            &.secondary button {
                .formkit-placeholder,
                .formkit-selection-wrapper,
                .formkit-selections-wrapper {
                    border-color: var(--border-negative);
                }
            }
        }

        &[data-disabled=true] .formkit-inner {
            button,
            &.secondary button {
                .formkit-placeholder,
                .formkit-selection-wrapper,
                .formkit-selections-wrapper {
                    border-color: var(--border-disabled-1);
                    background-color: var(--background-disabled-1);
                    cursor: not-allowed;

                    /* stylelint-disable-next-line no-descending-specificity */
                    span {
                        color: var(--text-disabled-1);
                    }
                }

                .formkit-select-icon  {
                    display: none;
                }
            }
        }
    }

    .dropdown[data-required=true] :deep(.formkit-outer) {
        .formkit-label::after {
            color: var(--text-secondary);
            content:" *";
        }
    }
</style>
