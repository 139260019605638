<script setup lang="ts">
    import Icon from '@shared/components/ui/icon/icon.vue';
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import { ConstantsBreakpoints } from '@shared/constants';
    import { FormSizeTypeEnum, FormStyleEnum } from '@shared/constants/form-style';
    import { useWindowSize } from '@vueuse/core';
    import { computed, ref, watch } from 'vue';

    const { t } = useI18n();

    const props = defineProps<{
        name: string
        label?: string
        placeholder?: string
        validation?: string | [rule: string, ...args: unknown[]][]
        help?: string
        disabled?: boolean
        style?: FormStyleEnum.PRIMARY | FormStyleEnum.SECONDARY
        size?: FormSizeTypeEnum.NORMAL | FormSizeTypeEnum.EXTRA
        labelHidden?: boolean
        emptyTextArea?: boolean
        isLoading?: boolean
        autoHeight?: boolean
    }>();

    const { width } = useWindowSize();

    const localEmptyTextArea = ref(false);
    const height = ref(false);

    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const isDesktop = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.DESKTOP));
    const skeletonHeight = computed(() => isMobile.value ? '10rem' : isDesktop.value ? '12rem' : '11rem');

    const enableAutoheight = ():void => {
        if (props?.autoHeight) height.value = true;
    };

    const disableAutoheight = ():void => {
        if (props?.autoHeight) height.value = false;
    };

    watch(() => props.emptyTextArea, (nVal: boolean) => {
        localEmptyTextArea.value = nVal;
    });
</script>

<template>
    <div v-if="localEmptyTextArea">
        <button
            class="btn btn--ghost btn--icon-left"
            type="button"
            @click="localEmptyTextArea = false"
        >
            <Icon
                name="plus"
                size="medium"
            />
            {{ t('vma.global.add-description') }}
        </button>
    </div>

    <div
        v-else
        class="textarea"
    >
        <Skeleton
            v-if="isLoading"
            :height="skeletonHeight"
        />
        <FormKit
            v-else
            type="textarea"
            :label="label"
            :name="name"
            :placeholder="placeholder"
            :validation="validation"
            :help="help"
            :inner-class="`${style ?? FormStyleEnum.PRIMARY} ${height ? 'active-height' : ''}`"
            :wrapper-class="size ?? FormSizeTypeEnum.NORMAL"
            :disabled="disabled"
            :label-class="labelHidden ? 'formkit-label--hidden' : ''"
            auto-height
            @click="enableAutoheight"
            @blur="disableAutoheight"
        />
    </div>
</template>

<style lang="scss" scoped>
    .textarea :deep(.formkit-outer) {
        .formkit-inner {
            textarea {
                box-sizing: border-box;
                padding: var(--spacing-03);
                border: 1px solid var(--border-tertiary);
                border-radius: var(--border-radius-xs);
                background-color: var(--background-form-input);
                /* stylelint-disable-next-line declaration-no-important */
                block-size: 10rem !important;
                font-family: var(--font-primary);
                font-size: var(--font-medium);
                font-weight: var(--font-weight-normal);
                inline-size: 100%;
                line-height: 1.3;
                resize: none;

                &::placeholder {
                    color: var(--text-placeholder);
                }

                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }

                @include min-width(md) {
                    /* stylelint-disable-next-line declaration-no-important */
                    block-size: 11rem !important;
                }

                @include min-width(lg) {
                    /* stylelint-disable-next-line declaration-no-important */
                    min-block-size: 12rem !important;
                }
            }

            &.secondary textarea {
                &:hover {
                    background-color: var(--background-form-input-hover);
                }

                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }
            }
        }

        &[data-invalid=true] .formkit-inner {
            textarea,
            &.secondary textarea {
                border-color: var(--border-negative);
            }
        }

        &[data-required=true] {
            .formkit-label::after {
                color: var(--text-secondary);
                content:" *";
            }
        }
    }
</style>
