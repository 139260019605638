<script setup lang="ts">
    import { type VenueTyping } from '@/typings/widget';
    import AdvancedImage from '@shared/components/ui/advanced-image.vue';
    import Icon from '@shared/components/ui/icon/icon.vue';

    defineProps<{
        venueData: VenueTyping | null
    }>();

    const emit = defineEmits<{(event: 'back' | 'scrollToTop' | 'openContact')}>();

    const { t } = useI18n();

    // Hooks
    onMounted(() => {
        emit('scrollToTop');
    });
</script>

<template>
    <div class="call-us">
        <button
            class="call-us__back btn btn--icon btn btn--ghost"
            type="button"
            @click="emit('back')"
        >
            <Icon
                name="chevronLeft"
                size="medium"
            />
        </button>
        <div class="call-us__content">
            <div class="call-us__content__logo">
                <AdvancedImage
                    aspect-ratio="1:1"
                    :preview="null"
                    :media="venueData?.profileImage.cover"
                />
            </div>
            <div class="call-us__content__info">
                <div
                    v-if="venueData?.phoneCountryPrefix && venueData?.phoneNumber"
                    class="call-us__content__phone"
                >
                    <span class="call-us__content__title">{{ t('widget.call-us.phone') }}</span>
                    <span>{{ `${venueData?.phoneCountryPrefix} ${venueData?.phoneNumber}` }}</span>
                </div>
                <div
                    v-if="venueData?.email"
                    class="call-us__content__email"
                    @click="emit('openContact')"
                >
                    <span class="call-us__content__title">{{ t('widget.call-us.email') }}</span>
                    <span>{{ venueData?.email }}</span>
                </div>
                <div
                    v-if="venueData?.address?.addressLine || venueData?.address?.postalCode || venueData?.address.locality"
                    class="call-us__content__address"
                >
                    <span class="call-us__content__title">{{ t('widget.call-us.address') }}</span>
                    <span v-if="venueData?.address?.addressLine">
                        {{ venueData?.address?.addressLine }}
                    </span>
                    <span v-if="venueData?.address?.postalCode || venueData?.address.locality">
                        {{ `${venueData?.address?.postalCode}, ${venueData?.address.locality}` }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.call-us {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--background-secondary);
    block-size: 100%;
    inline-size: 100%;

    &__back {
        position: absolute;
        inset-block-start: var(--spacing-01);
        inset-inline-start: var(--spacing-01);
    }

    &__content {
        display: flex;
        flex: 2;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
        gap: var(--spacing-05);
        inline-size: 100%;

        @include min-width(md) {
            flex-direction: row;
            justify-content: space-between;
            padding-inline: var(--spacing-10);
        }

        @include min-width(lg) {
            justify-content: space-around;
            padding-inline: var(--spacing-07);
        }

        &__logo {
            overflow: hidden;
            border-radius: var(--border-radius-circle);
            inline-size: 100%;
            max-inline-size: 160px;

            @include min-width(md) {
                max-inline-size: 210px;
            }

            @include min-width(lg) {
                max-inline-size: 244px;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            padding: var(--spacing-05);
            border-radius: var(--border-radius-sm);
            background: var(--background-secondary-highlight);
            gap: var(--spacing-03);
            inline-size: 100%;
            max-inline-size: 223px;

            @include min-width(md) {
                max-inline-size: 244px;
            }

            @include min-width(lg) {
                gap: var(--spacing-04);
                max-inline-size: 387px;
            }
        }

        &__phone,
        &__email,
        &__address {
            display: flex;
            flex-direction: column;
        }

        &__email {
            cursor: pointer;
        }

        &__title {
            display: block;
            font-weight: var(--font-weight-medium);
        }
    }

}
</style>
