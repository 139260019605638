<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import { computed } from 'vue';

    const props = withDefaults(defineProps<{
        width?: number
    }>(), { width: 30 });

    const height = computed(() => props.width * (960 / 594));
</script>

<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 594 960"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M574.8 444C440.03 353.84 372.08 190.64 338.97 33.17C333.35 11.06 315.03 0
        296.7 0C278.38 0 260.06 11.06 254.43 33.17C221.32 190.65 153.47 353.84 18.7 444C7.59 451.8 0 465.31 0 479.94C0 494.57 7.61 508.08 18.71
        515.88C153.48 606.04 221.34 769.36 254.45 926.83C260.07 948.95 278.4 960 296.72 960C315.04 960 333.36 948.94 338.99 926.83C372.1
        769.36 440.05 606.04 574.82 515.88C585.93 508.08 593.51 494.57 593.5 479.94C593.5 465.31 585.91 451.8 574.8 444Z"
        />
    </svg>
</template>

