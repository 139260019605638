export enum SelectFieldStylesEnum {
    DEFAULT = 'default',
    LOCATION = 'location',
}

export enum AlignLeftRightEnum {
    ALIGN_LEFT = 'align-left',
    ALIGN_RIGHT = 'align-right',
}

export enum AlignLeftRightCenterEnum {
    ALIGN_LEFT = 'align-left',
    ALIGN_RIGHT = 'align-right',
    ALIGN_CENTER = 'align-center',
}

export enum ButtonIconShapeEnum {
    CIRCLE = 'circle',
    SQUARE = 'square',
}

export enum ButtonIconStyleEnum {
    DEFAULT = 'default',
    USER_SETTINGS = 'user-settings',
    GUEST = 'guest',
}

export enum TabStylesEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    MINIMALIST = 'minimalist'
}

export enum SkeletonStyleEnum {
    SQUARE = 'square',
    CIRCLE = 'circle',
    PARAGRAPH = 'paragraph',
    HEADER = 'header'
}
