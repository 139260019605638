export enum FormStyleEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary'
}

export const enum SizeEnum {
    XXSMALL = 'xxsm',
    XSMALL = 'xsm',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    XLARGE = 'xl',
    XXLARGE = 'xxl'
}

export enum FormSizeTypeEnum {
    NORMAL = 'normal',
    EXTRA = 'extra'
}

export const enum AvatarIconEnum {
    PLUS = 'plus',
    MEATBALLS = 'meatballs'
}

export const enum InputTypeEnum {
    CHECKBOX = 'chceckbox',
    TEXT = 'text',
    NUMBER = 'number',
}
