/* eslint-disable id-length */
import { type EventTypeTyping, type VenueTyping } from '@/typings/widget';
import { type ComputedRef, type Ref } from 'vue';

export const useWidgetVenueStore = defineStore('venue', () => {
    // Data
    const venue: Ref<VenueTyping | null> = ref(null);

    // Computed
    const venueEventTypes: ComputedRef<EventTypeTyping[]> = computed(() => {
        if (!venue.value?.eventTypes) return [];

        return [...venue.value.eventTypes]
            .sort((a, b) => a.name.localeCompare(b.name));
    });

    return {
        venue,
        venueEventTypes,
    };
});
