<script setup lang="ts">
    import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon.vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    defineProps<{
        onClick:() => void
    }>();
</script>

<template>
    <a
        class="link-btn link-btn-with-icon"
        @click="() => onClick()"
    >
        <ChevronLeftIcon />
        {{ t('widget.navigation.back') }}
    </a>
</template>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.link-btn-with-icon {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    padding-inline-end: 10px;

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    svg {
        margin-block-start: 1px;
    }
}
</style>
