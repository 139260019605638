<script setup lang="ts">
    import Burger from '@/components/Burger.ce.vue';
    import { getContrastBackground, getInvertContrastColor } from '@/helpers/text';
    import { type MenuOptionTyping, type VenueManagerTyping } from '@/typings/widget';
    import NavigationButton from '@shared/components/navigation/navigation-button.vue';
    import { type ComputedRef } from 'vue';
    import MenuCard from './MenuCard.ce.vue';

    defineProps<{
        venueManager?: VenueManagerTyping
        brandColor?: string
    }>();

    const emit = defineEmits<{(event: 'selectOption', value: string), (event: 'contactSelected' | 'callSelected' | 'appointmentSelected')}>();

    const { t } = useI18n();

    const menuOptions: ComputedRef<MenuOptionTyping[]> = computed(() => [
        /*
         * {
         *     value: 'quote',
         *     title: t('widget.selection.quote'),
         *     desc: t('widget.selection.quote-desc'),
         * },
         */
        {
            value: 'packages',
            title: t('widget.selection.packages'),
            desc: t('widget.selection.packages-desc'),
        },
        {
            value: 'journey',
            title: t('widget.selection.journey'),
            desc: t('widget.selection.journey-desc-2'),
        },
    ]);
</script>

<template>
    <div class="menu">
        <div class="menu__header">
            <Burger
                :brand-color="brandColor"
                is-ghost
            />
        </div>
        <div class="menu__options">
            <MenuCard
                v-for="option in menuOptions"
                :key="option.value"
                :option="option"
                :venue-manager="venueManager"
                :brand-color="brandColor"
                @click="emit('selectOption', $event)"
            />
        </div>
        <div class="menu__items">
            <!-- Calendar Page -->
            <NavigationButton
                :page-name="t('widget.navigation.appointment')"
                :style="{
                    color: getInvertContrastColor(brandColor ?? '#282829'),
                    background: getContrastBackground(brandColor ?? '#E2E2E2')
                }"
                main-icon="dateOutlined"
                secondary-icon="dateFilled"
                tooltip-direction="right"
                icon-size="medium"
                class="menu__items__nav-btn btn"
                is-button
                rounded
                @click="emit('appointmentSelected')"
            />

            <!-- Contact Page -->
            <NavigationButton
                :page-name="t('widget.navigation.contact')"
                :style="{
                    color: getInvertContrastColor(brandColor ?? '#282829'),
                    background: getContrastBackground(brandColor ?? '#E2E2E2')
                }"
                main-icon="chatOutlined"
                secondary-icon="chatFilled"
                tooltip-direction="right"
                icon-size="medium"
                class="menu__items__nav-btn btn"
                is-button
                rounded
                @click="emit('contactSelected')"
            />

            <!-- Call Page -->
            <NavigationButton
                :page-name="t('widget.navigation.call')"
                :style="{
                    color: getInvertContrastColor(brandColor ?? '#282829'),
                    background: getContrastBackground(brandColor ?? '#E2E2E2')
                }"
                main-icon="callOutlined"
                secondary-icon="callFilled"
                tooltip-direction="right"
                icon-size="medium"
                class="menu__items__nav-btn btn"
                is-button
                rounded
                @click="emit('callSelected')"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.menu {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-05);
    background: var(--widget-brand-color);
    inline-size: 100%;

    @include max-width(md) {
        gap: var(--spacing-02);
    }

    @include max-width(lg) {
        gap: var(--spacing-04);
    }

    &__header {
        display: flex;
        justify-content: flex-end;

        @include min-width(lg) {
            display: none;
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-02);
    }

    &__items {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-03);
        inline-size: 100%;

        @include min-width(lg) {
            display: none;
        }

        &__nav-btn {
            block-size: var(--size-14);
            inline-size: var(--size-14);
            max-block-size: var(--size-14);
            max-inline-size: var(--size-14);
            min-block-size: var(--size-14);
            min-inline-size: var(--size-14);
        }

    }
}
</style>
