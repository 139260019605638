<!-- eslint-disable complexity -->
<!-- eslint-disable max-lines-per-function -->
<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<!-- eslint-disable no-undefined -->
<script lang="ts" setup>
    import { VENUE_HOST_EVENT, VENUE_HOST_RESET } from '@/constants/events';
    import { type EventBusType } from '@/plugins/event-bus-plugin';
    import { type VenueManagerTyping } from '@/typings/widget';
    import { useSafeTimeout } from '@shared/composables/useTimeout';
    import VenueHostImage from './VenueHostImage.ce.vue';
    import VenueHostMessage from './VenueHostMessage.ce.vue';
    const WELCOME_HOST_TARGET = '#welcome-host-target';
    const WELCOME_HOST_EXIT_DELAY = 2000;
    const MAIN_HOST_TARGET = '#main-host-target';
    const MAIN_CONTENT = '#main-content';
    const BOTTOM_RIGHT_DELAY = 600;
    const PATH_SELECTION_DELAY = 1750;
    const PATH_SELECTION_EXIT_DELAY = 50;
    const PATH_SELECTION_TARGET = '#venue-host-path';
    const LARGE_DEVICE_WIDTH = 900;
    const TOOLTIP_DELAY = 500;
    const SELECT_DATE_DELAY = 200;
    const SMALL_DEVICE_WIDTH = 576;
    const SELECT_EVENT_TYPE_DELAY = 3000;

    export interface HostEventType {
        title: string
        text: string
        enabled: boolean,
        days: number,
        tooltip: boolean
    }

    const screens = {
        welcomeMessage: [0, 0.5],
        selectEventType: [2, 2.5],
        pathSelection: [3, 3.5],
        selectDate: [4, 4.5],
        survey: [
            5, 5.5, 6, 6.5, 7, 7.5,
        ],
        amount: [8],
        appointmentDate: [12, 12.5],
    };

    const init = {
        title: '',
        text: '',
        enabled: false,
        days: 0,
        tooltip: true,
    };

    const props = defineProps<{
        venueManager?: VenueManagerTyping
    }>();

    const { t } = useI18n();
    const safeTimeout = useSafeTimeout();
    const eventBus = inject<EventBusType>('eventBus');
    const mount: Ref<Element|null|undefined|string> = ref('body');
    const mountTooltip: Ref<Element|null|undefined|string> = ref('');
    const step: Ref<number> = ref(-1);
    const tooltip: Ref<HTMLDivElement|undefined> = ref();
    const tooltipTimeout: Ref<ReturnType<typeof setTimeout>|undefined> = ref();
    const target: Ref<HTMLDivElement|undefined> = ref();
    const host: Ref<HostEventType> = ref(init);
    const selectedDate: Ref<boolean> = ref(false);

    const querySelector = <T>(selector: string): T | null => {
        const shadowRoot = document.querySelector('constell-widget')?.shadowRoot;

        if (!shadowRoot) return null;

        return shadowRoot.querySelector(selector) as T | null;
    };

    const disableScroll = (): void => {
        const mainContent: HTMLDivElement | null = querySelector(MAIN_CONTENT);

        if (mainContent) {
            mainContent.style.overflow = 'hidden';
            mainContent.style.pointerEvents = 'none';
        }
    };

    const enableScroll = (): void => {
        const mainContent: HTMLDivElement | null = querySelector<HTMLDivElement>(MAIN_CONTENT);
        if (mainContent) {
            mainContent.style.overflow = '';
            mainContent.style.pointerEvents = '';
        }
    };

    const resetState = (): void => {
        safeTimeout.clearAllTimeouts();
        host.value = init;
        mount.value = null;
        tooltip.value = undefined;
        target.value = undefined;
        tooltipTimeout.value = undefined;
        step.value = -1;
        enableScroll();
    };

    const hideWelcomeToBottom = (): void => {
        disableScroll();
        safeTimeout.setSafeTimeout(() => {
            if (host.value && target.value) {
                target.value.style.insetBlockStart = '75%';
                target.value.style.insetInlineStart ='75%';
                target.value.style.transform = 'translate(400%, 400%)';

                safeTimeout.setSafeTimeout(() => {
                    enableScroll();
                    host.value.enabled = false;
                    mount.value = null;
                }, WELCOME_HOST_EXIT_DELAY);
            }
        }, WELCOME_HOST_EXIT_DELAY);
    };

    const handleWelcomeScreen = (next: number): void => {
        if (next === screens.welcomeMessage[0]) {
            const mountElement = querySelector<HTMLElement>(WELCOME_HOST_TARGET);
            mount.value = mountElement;
        } else if (next === screens.welcomeMessage[1]) {
            hideWelcomeToBottom();
        }
    };

    const hideToBottomRight = (): void => {
        if (target.value) {
            target.value.style.insetBlockEnd = '-100%';
            target.value.style.insetInlineEnd = '-100%';
        }
    };

    const mountToTarget = (): void => {
        const mountElement = querySelector<HTMLElement>(MAIN_HOST_TARGET);
        if (mount.value !== mountElement) mount.value = mountElement;
    };

    const showFromBottomRight = (callbackFunc?: () => void, delay?: number): void => {
        mountToTarget();

        safeTimeout.setSafeTimeout(() => {
            if (target.value) {
                target.value.style.insetBlockEnd = 'var(--spacing-03)';
                target.value.style.insetInlineEnd = 'var(--spacing-03)';
                safeTimeout.setSafeTimeout(() => {
                    host.value.tooltip = true;
                    if (typeof callbackFunc === 'function') callbackFunc();
                }, TOOLTIP_DELAY);
            }
        }, delay ?? BOTTOM_RIGHT_DELAY);
    };

    // eslint-disable-next-line max-statements
    const removeHostPath = (targetPoint: HTMLElement): void => {
        const mainContent = querySelector<HTMLElement>(MAIN_HOST_TARGET);

        if (!mainContent || !target.value) return;

        const mainContentRect = mainContent.getBoundingClientRect();
        const targetPointRect = targetPoint.getBoundingClientRect();
        const insetBlockEnd = mainContentRect.bottom - targetPointRect.bottom;
        const insetInlineEnd = mainContentRect.right - targetPointRect.right;

        mount.value = mainContent;

        target.value.style.blockSize = `${targetPoint.clientHeight}px`;
        target.value.style.inlineSize = `${targetPoint.clientWidth}px`;
        target.value.style.insetBlockEnd = `${insetBlockEnd}px`;
        target.value.style.insetInlineEnd = `${insetInlineEnd}px`;

        disableScroll();

        safeTimeout.setSafeTimeout(() => {
            if (target.value) {
                host.value.tooltip = false;
                target.value.style.insetBlockEnd = '-135px';
                target.value.style.insetInlineEnd = '-135px';
                safeTimeout.setSafeTimeout(() => {
                    if (target.value) {
                        host.value.enabled = false;
                        target.value.removeAttribute('style');
                        hideToBottomRight();
                    }
                }, PATH_SELECTION_DELAY);
            }
            enableScroll();
        }, PATH_SELECTION_EXIT_DELAY);
    };

    // eslint-disable-next-line max-statements
    const updateHostPath = (targetPoint: HTMLElement): void => {
        const mainContent = querySelector<HTMLElement>(MAIN_CONTENT);

        if (!mainContent || !target.value) return;

        disableScroll();

        const mainContentRect = mainContent.getBoundingClientRect();
        const targetPointRect = targetPoint.getBoundingClientRect();
        const insetBlockEnd = mainContentRect.bottom - targetPointRect.bottom;
        const insetInlineEnd = mainContentRect.right - targetPointRect.right;

        target.value.style.blockSize = `${targetPoint.clientHeight}px`;
        target.value.style.inlineSize = `${targetPoint.clientWidth}px`;
        target.value.style.insetBlockEnd = `${insetBlockEnd}px`;
        target.value.style.insetInlineEnd = `${insetInlineEnd}px`;

        safeTimeout.setSafeTimeout(() => {
            mount.value = targetPoint;
            if (target.value) {
                target.value.style.position = 'absolute';
                target.value.style.insetBlockEnd = '0px';
                target.value.style.insetInlineEnd = '0px';
                target.value.style.blockSize = '100%';
                target.value.style.inlineSize = '100%';

                host.value.title = t('widget.host.title-3');
            }
            enableScroll();
        }, PATH_SELECTION_DELAY / 2);
    };

    const handlePathSelection = (next: number): void => {
        const targetPoint = querySelector<HTMLElement>(PATH_SELECTION_TARGET);

        if (!targetPoint) return;

        if (next === screens.pathSelection[0]) {
            disableScroll();
            safeTimeout.setSafeTimeout(() => {
                updateHostPath(targetPoint);
                enableScroll();
            }, PATH_SELECTION_DELAY / 2);
        } else if (next === screens.pathSelection[1]) {
            safeTimeout.setSafeTimeout(() => {
                removeHostPath(targetPoint);
            }, PATH_SELECTION_DELAY);
        }
    };

    const handleSelectEventType = (next: number): void => {
        if (next === screens.selectEventType[0]) {
            hideToBottomRight();
            showFromBottomRight();
            safeTimeout.setSafeTimeout(() => {
                hideToBottomRight();
            }, SELECT_EVENT_TYPE_DELAY);
        } else if (next === screens.selectEventType[1]) {
            showFromBottomRight(undefined, SELECT_DATE_DELAY);
        }
    };

    const handleSelectDate = (next: number): void => {
        if (selectedDate.value) hideToBottomRight();
        if (screens.selectDate[0] === next) {
            showFromBottomRight(() => {
                selectedDate.value = true;
                if (target.value) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    calculateCoordinates(target.value);
                }
            }, selectedDate.value ? SELECT_DATE_DELAY : 1);
        }
    };

    const handleSurvey = (next: number): void => {
        if (next === screens.survey[0]) {
            showFromBottomRight(() => {
                host.value.tooltip = true;
                safeTimeout.setSafeTimeout(() => {
                    if (tooltip.value) tooltip.value.style.opacity = '1';
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    if (target.value) calculateCoordinates(target.value);
                }, BOTTOM_RIGHT_DELAY);
            }, BOTTOM_RIGHT_DELAY / 3);
        } else if (next === screens.survey[1] || next === screens.survey[4]) {
            hideToBottomRight();
        } else if (next === screens.survey[2]) {
            showFromBottomRight();
        } else {
            hideToBottomRight();
            showFromBottomRight();
        }
    };

    const handleAmount = (next: number): void => {
        if (next === screens.amount[0]) {
            hideToBottomRight();
        }
    };

    const handleAppointmentDate = (next: number): void => {
        if (next === screens.appointmentDate[0]) {
            showFromBottomRight();
        } else {
            hideToBottomRight();
        }
    };

    // eslint-disable-next-line max-statements
    const updateVenueHost = (next: number): void => {
        if (screens.welcomeMessage.includes(next)) {
            handleWelcomeScreen(next);
        } else if (screens.selectEventType.includes(next)) {
            handleSelectEventType(next);
        } else if (screens.pathSelection.includes(next)) {
            handlePathSelection(next);
        } else if (screens.selectDate.includes(next)) {
            handleSelectDate(next);
        } else if (screens.survey.includes(next)) {
            handleSurvey(next);
        } else if (screens.amount.includes(next)) {
            handleAmount(next);
        } else if (screens.appointmentDate.includes(next)) {
            handleAppointmentDate(next);
        } else {
            // eslint-disable-next-line no-console
            console.warn(`no handler for step: ${next}`);
        }

        if (!mountTooltip.value) {
            mountTooltip.value = querySelector<HTMLElement>(MAIN_HOST_TARGET);
        }
    };

    // eslint-disable-next-line max-statements
    const getSetting = (next: number): Partial<HostEventType> => {
        if (screens.welcomeMessage.includes(next)) {
            return {
                title: t('widget.host.title-1', { name: props.venueManager?.displayName }),
                text: t('widget.host.text-1'),
                enabled: true,
            };
        } else if (next === screens.selectEventType[0]) {
            return {
                title: t('widget.host.title-2'),
                text: '',
                enabled: true,
                tooltip: false,
            };
        } else if (next === screens.selectEventType[1]) {
            return {
                title: t('widget.host.title-2-5'),
                text: '',
                enabled: true,
                tooltip: false,
            };
        } else if (next === screens.pathSelection[0]) {
            return {
                enabled: true,
                title: t('widget.host.title-2-5'),
            };
        } else if (next === screens.pathSelection[1]) {
            return {
                enabled: true,
                title: t('widget.host.title-4'),
            };
        } else if (screens.selectDate.includes(next)) {
            return {
                enabled: true,
                tooltip: false,
            };
        } else if (next === screens.survey[0]) {
            return {
                enabled: true,
                tooltip: false,
                title: t('widget.host.title-6-5'),
            };
        } else if (next === screens.survey[1]) {
            return {
                enabled: true,
                tooltip: false,
                title: t('widget.host.title-7'),
            };
        } else if (next === screens.survey[2]) {
            return {
                enabled: true,
                tooltip: false,
                title: t('widget.host.title-7'),
            };
        } else if (next === screens.survey[3]) {
            return {
                enabled: true,
                tooltip: false,
                title: t('widget.host.title-8'),
            };
        } else if (next === screens.survey[4]) {
            return {
                enabled: true,
                tooltip: false,
                title: t('widget.host.title-8'),
            };
        } else if (next === screens.survey[5]) {
            return {
                enabled: true,
                tooltip: false,
                title: t('widget.host.title-9'),
            };
        } else if (next === screens.amount[0]) {
            return {
                enabled: true,
                tooltip: false,
                title: t('widget.host.title-9'),
            };
        } else if (screens.appointmentDate.includes(next)) {
            return {
                title: t('widget.host.title-10'),
                enabled: true,
                tooltip: false,
            };
        }
        return {
            enabled: false,
            tooltip: false,
        };
    };

    const updateSetting = (next: number, param: Partial<HostEventType>): void => {
        host.value = {
            ...host.value,
            ...param,
            ...getSetting(next),
        };

        step.value = next;

        if (step.value === screens.selectDate[0]) {
            if (host.value.days) {
                host.value.title = t(`widget.host.title-${host.value.days === 1 ? '5' : '6'}`, { days: host.value.days });
            } else {
                host.value.title = '';
            }
        }
    };

    const onVenueHostEvent = (event?: {step: number, param?: Partial<HostEventType>}): void => {
        if (!event) return;
        const param = event.param ?? {};
        const next = event.step ?? -1;
        updateSetting(next, param);
    };

    const isElementOutside = (child: HTMLElement, parent: HTMLElement): boolean => {
        const childRect = child.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        return (
            childRect.top < parentRect.top
            || childRect.left < parentRect.left
            || childRect.bottom > parentRect.bottom
            || childRect.right > parentRect.right
        );
    };

    // eslint-disable-next-line max-statements
    const calculateCoordinates = (targetEl: HTMLElement): void => {
        if (!tooltip.value) return;
        const mainTarget = querySelector<HTMLElement>(MAIN_HOST_TARGET);

        const boxRect = mainTarget?.getBoundingClientRect();
        const targetRect = targetEl.getBoundingClientRect();

        const tooltipWidth = tooltip.value?.offsetWidth;
        const tooltipHeight = tooltip.value?.offsetHeight;

        const computedStyle = window.getComputedStyle(targetEl as Element);
        const { borderRadius } = computedStyle;
        const isSquare = Math.abs(parseInt(borderRadius)) <= 30;

        let X_OFFSET = 0.10,
            Y_OFFSET = 0.50;

        if (isSquare) {
            if (window.innerWidth >= LARGE_DEVICE_WIDTH) {
                X_OFFSET = 0.5;
                Y_OFFSET = 0.25;
            } else {
                X_OFFSET = 0.25;
                Y_OFFSET = 0.48;
            }
        } else if (window.innerWidth <= SMALL_DEVICE_WIDTH && screens.welcomeMessage.includes(step.value)) {
            X_OFFSET = 0.25;
            Y_OFFSET = 0.85;
        }

        if ([
            ...screens.selectDate,
            ...screens.appointmentDate,
        ].includes(step.value) && window.innerWidth >= LARGE_DEVICE_WIDTH) {
            X_OFFSET = 0.25;
            Y_OFFSET = 0.85;
        }

        if (screens.pathSelection.includes(step.value)
            && mount.value
            && (mount.value as HTMLDivElement).id === 'venue-host-path') {
            if (screens.pathSelection[0] === step.value) {
                const targetHalfWidth = targetRect.width / 2;
                const tooltipHalfWidth = tooltipWidth / 2;
                const targetX = targetRect.left - (boxRect?.left ?? 0);
                const calcX = targetX - tooltipHalfWidth + targetHalfWidth;
                const journeyCard = querySelector<HTMLElement>('#journey-path-card');
                const journeyCardRect = journeyCard?.getBoundingClientRect();
                const tooltipHalfHeight = tooltipHeight / 2;
                const calcY = (journeyCardRect?.top ?? 0) - (boxRect?.top ?? 0) - tooltipHalfHeight;
                tooltip.value.style.transform = `translate(${calcX}px, ${calcY}px)`;
                tooltip.value.classList.add('venue-host__tooltip--paths');
                return;
            }
            X_OFFSET = 0.30;
            Y_OFFSET = 0.35;
        }

        const xAdjustment = tooltipWidth * X_OFFSET;
        const yAdjustment = tooltipHeight * Y_OFFSET;

        const left = targetRect.left - (boxRect?.left ?? 0) - tooltipWidth + xAdjustment;
        const top = targetRect.top - (boxRect?.top ?? 0) - yAdjustment;

        tooltip.value.classList.remove('venue-host__tooltip--paths');
        tooltip.value.style.transform = `translate(${left}px, ${top}px)`;

        if (mainTarget && isElementOutside(target.value as HTMLElement, mainTarget)) {
            tooltip.value.style.opacity = '0';
        } else {
            tooltip.value.style.opacity = '1';
        }
    };

    const onHostImageChanged = (targetEl: HTMLElement | undefined): void => {
        if (tooltipTimeout.value) {
            safeTimeout.clearSafeTimeout(tooltipTimeout.value);
        }
        if (tooltip.value && targetEl && target.value) {
            tooltipTimeout.value = safeTimeout.setSafeTimeout(() => {
                if (!tooltip.value) return;
                calculateCoordinates(targetEl);
            });
        }
    };

    const onVenueHostReset = (): void => { resetState() };

    const isRounded = computed<boolean>(() => [
        screens.welcomeMessage[1],
        screens.selectDate[0],
        ...screens.selectEventType,
        ...screens.survey,
        ...screens.pathSelection,
        ...screens.amount,
        ...screens.appointmentDate,
    ].includes(step.value));

    const isBottomRight = computed<boolean>(() => [
        screens.pathSelection[0],
        ...screens.selectEventType,
        ...screens.selectDate,
        ...screens.survey,
        ...screens.amount,
        ...screens.appointmentDate,
    ].includes(step.value));

    const isCalendar = computed<boolean>(() => [
        ...screens.selectDate,
        ...screens.appointmentDate,
    ].includes(step.value));

    onMounted(() => {
        eventBus?.on(VENUE_HOST_EVENT, onVenueHostEvent);
        eventBus?.on(VENUE_HOST_RESET, onVenueHostReset);
    });

    onBeforeUnmount(() => {
        resetState();
        eventBus?.off(VENUE_HOST_EVENT, onVenueHostEvent);
        eventBus?.off(VENUE_HOST_RESET, onVenueHostReset);
    });

    watch(step, value => { updateVenueHost(value) });

    watch(host, async() => {
        await nextTick();
        if (target.value) calculateCoordinates(target.value);
    });

    watch(() => host.value.days, () => {
        updateVenueHost(step.value);
    });

    watch(() => props.venueManager, () => {
        if (screens.welcomeMessage.includes(step.value) && props.venueManager) {
            host.value.title = t('widget.host.title-1', { name: props.venueManager?.displayName });
        }
    });
</script>

<template>
    <Teleport
        v-if="mountTooltip && host.enabled && mount"
        :to="mountTooltip"
    >
        <div
            ref="tooltip"
            class="venue-host__tooltip"
            :class="{'venue-host__tooltip--hidden': !host.tooltip, 'venue-host__tooltip--calendar': isCalendar}"
        >
            <VenueHostMessage
                v-if="host.title"
                :title="host.title"
                :text="host.text"
            />
        </div>
    </Teleport>
    <Teleport
        v-if="mount"
        :to="mount"
    >
        <div
            ref="target"
            class="venue-host"
            :class="{
                'venue-host--rounded': isRounded,
                'venue-host__welcome': screens.welcomeMessage.includes(step),
                'venue-host__welcome--centered': step === screens.welcomeMessage[1],
                'venue-host__bottom-right': isBottomRight,
                'venue-host__calendar': isCalendar
            }"
        >
            <VenueHostImage
                v-if="host.enabled"
                :cover="venueManager?.cover"
                @changed="onHostImageChanged"
            />
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.venue-host {
    position: absolute;
    z-index: var(--zindex-popover);
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    inset-block-end: 0;
    inset-inline-end: 0;
    transition: all 500ms ease-in-out;
    will-change: auto;

    &__tooltip {
        position: absolute;
        z-index: var(--zindex-overlay);
        overflow: hidden;
        max-inline-size: 192px;
        transition: opacity 100ms ease-in-out;
        will-change: auto;

        @include min-width(md) {
            max-inline-size: 218px;
        }

        @include min-width(lg) {
            max-inline-size: 243px;
        }

        &--hidden {
            color: transparent;
            opacity: 0;
            visibility: hidden;
        }

        &--calendar {
            @include min-width(lg) {
                max-inline-size: 148px;
            }
        }

        // eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
        &--paths {
            :deep(.venue-host-message){
                border-end-end-radius: var(--size-1);
            }
        }
    }

    &--rounded {
        border-radius: 50%;

        :deep(.venue-host-img) {
            border-radius: 50%;
        }
    }

    &__welcome {
        position: absolute;
        inline-size: 158px;
        inset-block-start: 210px;
        inset-inline-end: var(--spacing-04);
        transition: all 1.2s ease-in-out;
        will-change: auto;

        @include min-width(md) {
            inline-size: 272px;
            inset-block-start: var(--spacing-13);
            inset-inline-end: var(--spacing-07);
            max-inline-size: 41.2%;
        }

        @include min-width(lg) {
            inline-size: 372px;
            inset-block-start: var(--spacing-08);
        }

        &--centered {
            inline-size: var(--size-16);
            inset-block-start: 40%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);

            @include min-width(md) {
                inline-size: calc(var(--size-12) * 2);
                inset-block-start: 50%;
            }
        }
    }

    &__bottom-right {
        position: fixed;
        inline-size: calc(var(--spacing-05) * 2);
        inset-block-end: calc(calc(-1 * var(--spacing-16)) * 4);
        inset-inline-end: calc(calc(-1 * var(--spacing-16)) * 4);
        transition: all 1s ease-in-out;

        @include min-width(lg) {
            inline-size:  var(--spacing-11);
        }
    }

    &__calendar {
        transition: all 400ms ease-in-out;
    }
}
</style>
