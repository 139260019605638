<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script lang="ts" setup>
    import WidgetInputRadio from '@/components/WidgetInputRadio.ce.vue';

    const props = defineProps<{
        modelValue: string
        items: {value: string, label: string, notranslate?: boolean}[]
        title: string
        subtitle: string
        step: number
        startDelay?: boolean
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue' | 'next', value: string): void
        (event: 'next', value: number): void
        (event: 'starting' | 'started' | 'exiting' | 'exited'): void
    }>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const DELAY = 1000;
    const DELAY_MULTIPLIER = 0.5;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;

    const selectedModel: Ref<string> = ref(props.modelValue ?? '');
    const showElement: Ref<boolean> = ref(false);
    const showIntro: Ref<boolean> = ref(false);
    const showItems: Ref<boolean> = ref(false);
    const exitElement: Ref<boolean> = ref(false);
    const exiting: Ref<boolean> = ref(false);

    const handleNextStep = ():void => {
        if (exiting.value) return;

        exiting.value = true;

        emit('exiting');

        setTimeout(() => {
            setTimeout(() => {
                exitElement.value = true;
                emit('next', props.step + STEP_MIDDLE);
                emit('exited');
                setTimeout(() => {
                    emit('next', props.step + STEP_NEXT);
                }, DELAY);
            }, DELAY * 2);
        }, DELAY);
    };

    const resetAnimationState = ():void => {
        showElement.value = false;
        showIntro.value = false;
        exitElement.value = false;
        emit('starting');
        setTimeout(() => {
            showElement.value = true;
            setTimeout(() => {
                showIntro.value = true;
                emit('started');
                setTimeout(() => {
                    showItems.value = true;
                }, DELAY * DELAY_MULTIPLIER);
            }, DELAY);
        }, ENTRY_DELAY);
    };

    onMounted(() => {
        resetAnimationState();
    });

    watch(() => selectedModel.value, (nVal: string) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{
            'slide-bottom-up': showElement && !exitElement,
            'slide-up': exitElement,
            'start-delay': showElement && !exitElement && props.startDelay
        }"
        class="survey-card"
    >
        <div
            class="survey-card__content"
            :class="{'survey-card__content--show': showItems}"
        >
            <div
                class="survey-card__intro"
                :class="{'survey-card__intro--show': showIntro, 'survey-card__intro--show-items': showItems}"
            >
                <h2 class="survey-card__title">
                    {{ title }}
                </h2>
                <p class="survey-card__subtitle">
                    {{ subtitle }}
                </p>
            </div>
            <div
                class="survey-card__list"
                :class="{'survey-card__list--show': showItems}"
            >
                <div class="survey-card__list-content">
                    <template
                        v-for="item in items"
                        :key="item.value"
                    >
                        <WidgetInputRadio
                            v-model="selectedModel"
                            :input-value="item.value"
                            name="survey"
                            @click="handleNextStep"
                        >
                            {{ item.notranslate ? item.label : t(item.label) }}
                        </WidgetInputRadio>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .survey-card {
        position: absolute;
        display: flex;
        justify-content: center;
        block-size: 100%;
        inline-size: 100%;
        inset-block-start: 0;
        transform: translateY(100%);
        transition: all 0.75s ease-in-out;

        &__intro {
            transform: translateY(180%);
            transition: all 0.75s ease-in-out;

            &--show-items {
                transform: translateY(30%);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 100%;
            inline-size: 100%;
            padding-block: var( --spacing-07);
            padding-inline: calc(var(--spacing-04) + var(--spacing-01));
            transition: all 1s ease-in-out;

            &--show {
                gap: var(--size-7);
            }
        }

        &__title {
            color: var(--text-primary);
            font-family: var(--font-secondary);
            font-size: var(--font-h2);
            font-weight: var(--font-weight-semi-bold);
            letter-spacing: var(--letter-spacing-sm);
            line-height: var(--line-height-150);
            margin-block-end: var(--spacing-02);
            padding-inline: var(--spacing-03);
            text-align: center;

            @include min-width(md) {
                font-size: var(--font-h1);
            }
        }

        &__subtitle {
            color: var(--text-primary);
            font-family: var(--font-primary);
            font-size: var(--font-regular);
            font-weight: var(--font-weight-light);
            line-height: var(--line-height-150);
            padding-inline: var(--spacing-03);
            text-align: center;

            @include min-width(md) {
                font-size: var(--font-h4);
            }
        }

        &__list {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__list-content {
            display: flex;
            overflow: hidden;
            flex-direction: column;
            gap: var(--spacing-03);
            inset-block-start: 0;
            padding-block-start: var(--spacing-05);
            padding-inline: var(--spacing-02);

            @include min-width(md) {
                padding-inline: var(--spacing-07);
            }
        }

        &.start-delay {
            transition-delay: 2s;
        }

        &.slide-bottom-up{
            transform: translateY(0);
        }

        &.slide-up{
            transform: translateY(-100%);
        }
    }
</style>
