// EventBusPlugin.ts
import { type HostEventType } from '@/components/VenueHost.ce.vue';
import { type VENUE_HOST_RESET, type VENUE_HOST_EVENT } from '@/constants/events';
import { EventBus } from '@shared/helpers/event-bus';
import { type App } from 'vue';

// Define your event map
interface WidgetEventMap {
    [VENUE_HOST_EVENT]: {step: number, param?: Partial<HostEventType>},
    [VENUE_HOST_RESET]: undefined
}

// Create an instance of the event bus
const eventBus: EventBus<WidgetEventMap> = new EventBus();

export type EventBusType = typeof eventBus;

// Create the plugin
export default {
    install(app: App): void {
        // Make the event bus available globally
        app.config.globalProperties.$eventBus = eventBus;

        // Optionally, provide it for injection
        app.provide('eventBus', eventBus);
    },
};

// Export the event bus instance for optional direct imports
export { eventBus };
