<script setup lang="ts">
    import { getContrastColor } from '@/helpers/text';
    import Icon from '@shared/components/ui/icon/icon.vue';

    defineProps<{
        isGhost?: boolean
        brandColor?: string
    }>();

    const { isMenuOpen } = storeToRefs(useWidgetMenuStore());
</script>

<template>
    <button
        :class="{'btn--ghost': isGhost, 'is-open': isMenuOpen}"
        :style="{ color: getContrastColor(brandColor ?? '#282829')}"
        type="button"
        class="btn btn--icon burger-menu"
        @click="isMenuOpen = !isMenuOpen;"
    >
        <Icon :name="isMenuOpen ? 'cross' : 'burger'" />
    </button>
</template>

<style lang="scss" scoped>
.burger-menu {
    border-radius: var(--border-radius-md);
    background: var(--widget-brand-color);
    block-size: var(--size-9);
    inline-size: var(--size-9);

    @include min-width(md) {
        block-size: var(--size-11);
        inline-size: var(--size-11);
    }

    &.is-open {
        @include max-width(lg) {
            background: rgb(255 255 255 / .25);
        }
    }
}
</style>
