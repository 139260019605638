<script setup lang="ts">
    import Burger from '@/components/Burger.ce.vue';
    import ConstellIcon from '@/components/icons/ConstellIcon.ce.vue';
    import { ConstantsBreakpoints } from '@shared/constants';

    defineProps<{
        brandColor?: string
    }>();

    const { width } = useWindowSize();

    const { isQuoteOpen } = storeToRefs(useWidgetMenuStore());

    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
</script>

<template>
    <div
        :class="{quote: isQuoteOpen}"
        class="widget-header"
    >
        <div class="widget-header__title">
            <ConstellIcon
                :width="isMobile ? 13 : 20"
                class="widget-header__icon"
            />
        </div>
        <Burger
            :brand-color="brandColor"
            class="widget-header__menu"
        />
    </div>
</template>

<style scoped lang="scss">
.widget-header {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    background: var(--background-secondary);
    block-size: var(--widget-header-height-mobile);
    border-block-end: 1px solid var(--border-tertiary);
    box-shadow: 0 4px 104px 0 rgb(23 23 29 / 0.04);
    inline-size: 100%;
    margin-inline-start: -1px;
    max-inline-size: var(--widget-max-width);
    padding-block: 0;
    padding-inline: var(--spacing-02);

    @include min-width(md) {
        block-size: var(--widget-header-height-tablet);
        padding-inline: var(--spacing-03);
    }

    @include min-width(lg) {
        block-size: var(--widget-header-height);
        padding-inline: var(--spacing-04);
    }

    &.quote {
        @include min-width(md) {
            max-inline-size: var(--widget-max-width-quote-mobile);
        }

        @include min-width(lg) {
            max-inline-size: var(--widget-max-width-quote);
        }
    }

    &__title {
        display: flex;
        align-items: center;
        color: inherit;
        padding-inline-start: var(--spacing-02);
        white-space: nowrap;

        :deep(.widget-header-icon) {
            color: var(--text-primary);
        }
    }

    &__icon {
        :deep(svg) {
            color: var(--text-primary);
        }
    }

    &__menu {
        @include min-width(lg) {
            display: none;
        }
    }
}
</style>
