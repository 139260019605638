import { createI18n, type I18n, type Locale, type I18nOptions, type Composer } from 'vue-i18n';
import nl from '@/translations/nl.json';
import en from '@/translations/en.json';

export const setI18nLanguage = (i18n: I18n, locale: Locale): void => {
    const htmlElement: HTMLHtmlElement | null = document.querySelector('html');

    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale;
    } else {
        ((i18n.global as unknown) as Composer).locale.value = locale;
    }
    if (htmlElement) htmlElement.setAttribute('lang', locale);
};

export const setupI18n = (options: I18nOptions): I18n => {
    if (!options?.locale) options.locale = 'nl';
    const i18n = createI18n(options);
    setI18nLanguage(i18n, options.locale);
    return i18n;
};

// Create Vue I18n instance.
export const i18n = setupI18n({
    legacy: false,
    locale: 'nl',
    fallbackLocale: 'nl',
    messages: {
        nl,
        en,
    },
});

/*
 * TODO: Make a language switcher and actually load a different locale with function below. This should be done in the routing file.
 * Check: https://github.com/intlify/vue-i18n-next/blob/69ce8dc95af4222c8da45155f4a65e5e8d7ef37f/examples/lazy-loading/vite/src/router.ts
 * The english translations are now never loaded because this function is never called
 */

// For the future when we want to load a different locale
export const loadLocaleMessages = async(i18nObject: I18n, locale: Locale): Promise<void> => {
    // Load locale messages
    if (!i18nObject.global.availableLocales.includes(locale)) {
        const messages = await import(`./translations/${locale}.json`) as string;
        i18nObject.global.setLocaleMessage(locale, messages);
    }
};
