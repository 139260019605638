<script setup lang="ts">
    import Appointment from '@/components/Appointment.ce.vue';
    import CallUs from '@/components/CallUs.ce.vue';
    import CustomTitle from '@/components/CustomTitle.ce.vue';
    import FullForm from '@/components/FullForm.ce.vue';
    import Menu from '@/components/menu/Menu.ce.vue';
    import PackageSelection from '@/components/PackageSelection.ce.vue';
    import SideBar from '@/components/SideBar.ce.vue';
    import VenueHost from '@/components/VenueHost.ce.vue';
    import WelcomeScreen from '@/components/WelcomeScreen.ce.vue';
    import { VENUE_HOST_EVENT, VENUE_HOST_RESET } from '@/constants/events';
    import { type EventBusType } from '@/plugins/event-bus-plugin';
    import axios from 'axios';
    import PathView from './PathView.ce.vue';
    import QuoteView from './QuoteView.ce.vue';

    const props = defineProps<{
        venue: string
        locale: string
        initialized: boolean
        maxGuestAmount?: number
        minGuestAmount?: number
        guestAmountStep?: number
        maxBudgetAmount?: number
        minBudgetAmount?: number
        budgetStep?: number
        isOpen?: boolean
        brandColor?: string
    }>();

    const { t } = useI18n();

    const eventBus = inject<EventBusType>('eventBus');

    const { venue: venueData, venueEventTypes } = storeToRefs(useWidgetVenueStore());
    const { isMenuOpen, isQuoteOpen } = storeToRefs(useWidgetMenuStore());

    // Refs
    const mainViewContainer: Ref<HTMLElement | null> = ref(null);
    const packagesContainer:Ref<HTMLElement | null> = ref(null);

    // Data
    const error = ref(false);
    const pathView = ref(false);
    const homeView = ref(false);
    const congratsView = ref(false);
    const comingFromMenu = ref(false);
    const appointmentView = ref(false);
    const fullFormView = ref(false);
    const callUsView = ref(false);
    const quoteView = ref(false);

    // Methods
    const scrollToTop = ():void => {
        if (mainViewContainer.value) mainViewContainer.value.scrollTop = 0;
    };

    const scrollToPackages = (): void => {
        if (packagesContainer.value && mainViewContainer.value) {
            const anchorOffsetTop = packagesContainer.value.getBoundingClientRect().top;
            const containerOffsetTop = mainViewContainer.value.getBoundingClientRect().top;

            // Calculate the scroll position relative to the scroll container
            const scrollPosition = mainViewContainer.value.scrollTop + anchorOffsetTop - containerOffsetTop;

            // Smooth scrolling
            mainViewContainer.value.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    };

    // eslint-disable-next-line max-statements
    const fetchPackages = async(): Promise<void> => {
        /*
         * The component will already render even though the FAB is not clicked yet,
         * therefore we need to check if it's open before loading actual data.
         */
        if (!props.initialized) return;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        venueData.value = null;
        homeView.value = true;
        pathView.value = false;
        congratsView.value = false;

        try {
            const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/widget/api/venue/${props.venue}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': props.locale,
                },
            });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            venueData.value = response.data?.venue || null;
        } catch (err) {
            error.value = true;
            // eslint-disable-next-line no-console
            console.error('Backend call failed', err);
        }
    };

    const openPath = ():void => {
        pathView.value = true;
        scrollToTop();
    };

    const openQuote = ():void => {
        quoteView.value = true;
        scrollToTop();
    };

    const resetVenueHost = (): void => {
        eventBus?.emit(VENUE_HOST_RESET);
        eventBus?.emit(VENUE_HOST_EVENT, { step: 0 });
    };

    // eslint-disable-next-line max-statements
    const resetToMain = ():void => {
        homeView.value = true;
        error.value = false;
        pathView.value = false;
        congratsView.value = false;
        comingFromMenu.value = false;
        appointmentView.value = false;
        fullFormView.value = false;
        callUsView.value = false;
        quoteView.value = false;
        resetVenueHost();
        scrollToTop();
    };

    const resetViews = (): void => {
        appointmentView.value = false;
        fullFormView.value = false;
        callUsView.value = false;
        quoteView.value = false;
        isMenuOpen.value = false;
        isQuoteOpen.value = false;
        scrollToTop();
    };

    const handleSelectMenuOption = (value: string): void => {
        resetToMain();
        isMenuOpen.value = false;
        isQuoteOpen.value = false;

        switch (value) {
        case 'packages':
            comingFromMenu.value = true;
            break;
        case 'journey':
            openPath();
            break;

        default:
            isQuoteOpen.value = true;
            openQuote();
            break;
        }
    };

    watch(() => props.locale, fetchPackages);

    watch(() => props.initialized, fetchPackages);

    watch(homeView, (nVal: boolean) => {
        if (nVal && props.isOpen) {
            resetVenueHost();
        }
    });

    watch(() => props.isOpen, nVal => {
        if (nVal) {
            resetViews();
            resetToMain();
        }
    });
</script>

<template>
    <div class="main-view">
        <div class="main-view__container">
            <Menu
                v-if="isMenuOpen"
                :venue-manager="venueData?.venueManager"
                :brand-color="brandColor"
                @select-option="handleSelectMenuOption"
                @appointment-selected="resetViews(); appointmentView = true;"
                @contact-selected="resetViews(); fullFormView = true;"
                @call-selected="resetViews(); callUsView = true;"
            />
            <div
                v-else
                class="main-view__container__main"
            >
                <CustomTitle :brand-color="brandColor" />
                <div
                    id="main-content"
                    ref="mainViewContainer"
                    class="main-view__content"
                >
                    <Appointment
                        v-if="appointmentView"
                        :locale="locale"
                        :venue="venue"
                        :venue-manager="venueData?.venueManager"
                        @back="resetToMain"
                        @scroll-to-top="scrollToTop"
                    />
                    <FullForm
                        v-else-if="fullFormView"
                        :venue="venue"
                        :locale="locale"
                        @back="resetToMain"
                        @scroll-to-top="scrollToTop"
                    />
                    <CallUs
                        v-else-if="callUsView"
                        :venue-data="venueData"
                        @back="resetToMain"
                        @scroll-to-top="scrollToTop"
                        @open-contact="resetViews(); fullFormView = true;"
                    />
                    <QuoteView v-else-if="quoteView" />
                    <template v-else>
                        <div class="main-host-target">
                            <div
                                id="main-host-target"
                                class="main-host-target__inner"
                            />
                        </div>
                        <VenueHost
                            v-if="isOpen"
                            :venue-manager="venueData?.venueManager"
                        />
                        <div
                            v-if="pathView"
                            class="main-view__path-section"
                        >
                            <PathView
                                :max-guest-amount="maxGuestAmount"
                                :min-guest-amount="minGuestAmount"
                                :guest-amount-step="guestAmountStep"
                                :max-budget-amount="maxBudgetAmount"
                                :min-budget-amount="minBudgetAmount"
                                :budget-step="budgetStep"
                                :locale="locale"
                                :error="error"
                                :venue="venue"
                                :brand-color="brandColor"
                                @back-to-main="resetToMain"
                            />
                        </div>
                        <div
                            v-else
                            class="main-view__overview-section"
                        >
                            <WelcomeScreen
                                v-if="homeView && isOpen && !comingFromMenu"
                                :venue-name="venueData?.name ?? null"
                                :venue-event-types="venueEventTypes"
                                :brand-color="brandColor"
                                @next="openPath"
                                @go-to-packages="scrollToPackages"
                            />
                            <div ref="packagesContainer">
                                <PackageSelection
                                    :locale="locale"
                                    :venue="venue"
                                    :error="error"
                                    :coming-from-menu="comingFromMenu"
                                    :is-open="isOpen"
                                    @scroll-to-top="scrollToTop"
                                    @toggle-home="homeView = $event"
                                    @process-request="congratsView = true"
                                    @back-to-main="resetToMain"
                                />
                            </div>
                        </div>
                        <div
                            v-if="homeView"
                            class="main-view__footer"
                        >
                            {{ t('widget.global.made-constell') }}
                        </div>
                    </template>
                </div>
                <div class="main-view__footer-shadow" />
            </div>
            <SideBar
                :brand-color="brandColor"
                :in-appointment="appointmentView"
                :in-contact="fullFormView"
                :in-call="callUsView"
                @appointment-selected="resetViews(); appointmentView = true;"
                @contact-selected="resetViews(); fullFormView = true;"
                @call-selected="resetViews(); callUsView = true;"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.main-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--border-radius-xs);
    block-size: 100%;
    transition: background-color 300ms linear;

    .main-host-target {
        position: absolute;
        z-index: var(--zindex-stack-thrice);
        overflow: hidden;
        block-size: 100%;
        inline-size: 100%;
        inset-block: 0 0;
        inset-inline: 0 0;
        pointer-events: none;

        &__inner {
            position: relative;
            overflow: hidden;
            block-size: 100%;
            inline-size: 100%;
        }
    }

    &__container {
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        block-size: 100%;

        &__main {
            flex: 2;
            background-color: var(--background-secondary);
            block-size: 100%;
            inline-size: 100%;
            transform: scale(1);
        }
    }

    &__content {
        position: relative;
        z-index: var(--zindex-stack-twice);
        block-size: calc(100% - var(--widget-header-height-mobile));
        -ms-overflow-style: none; /* IE & Edge */
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */

        @include min-width(md) {
            block-size: calc(100% - var(--widget-header-height-tablet));
        }

        @include min-width(lg) {
            block-size: calc(100% - var(--widget-header-height));
        }

        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari */
        }
    }

    &__overview-section,
    &__path-section {
        display: flex;
        flex-direction: column;
    }

    &__path-section {
        position: relative;
        overflow: hidden;
        block-size: 100%;
        min-block-size: 100%;
    }

    &__footer-shadow {
        position: fixed;
        z-index: var(--zindex-stack-twice);
        background: linear-gradient(0deg, rgb(0 0 0 / 0.30) 0%, rgb(239 239 239 / 0) 100%);
        block-size: var(--size-10);
        inline-size: 100%;
        inset-block-end: 0;
        inset-inline-start: 0;
        pointer-events: none;
    }

    &__footer {
        padding: var(--spacing-02);
        margin: var(--spacing-02);
        color: var(--text-tertiary);
        font-size: var(--font-regular);
        letter-spacing: 0.5px;
        text-align: center;
        text-transform: uppercase;

        @include min-width(md) {
            padding: var(--spacing-04);
            margin: var(--spacing-04);
        }
    }

    @include min-width(md) {
        border-radius: var(--border-radius-md);
    }
}

</style>
