{
  "localize": {
    "hours": {
      "short": "{hours}h"
    },
    "hoursAndMinutes": {
      "short": "{hours}h{minutes}m"
    }
  },
  "widget": {
    "titles": {
      "sent-us-request": "Sent us your request",
      "amazing": "Amazing!!",
      "ourPackages": "Our packages",
      "ask-name": "What is your name?",
      "event-type-title": "Nice {name}!",
      "event-type-subtitle": "What event would you like to organize?",
      "survey-organizer-title": "What kind of organizer are you?",
      "survey-organizer-subtitle": "Select what suits you best.",
      "survey-theme-title": "And what atmosphere are you thinking of for your event?",
      "survey-theme-subtitle": "Select one of these themes",
      "survey-appointment-title": "Is your date flexible?",
      "survey-appointment-subtitle": "Select your preference",
      "dank": "Thank you very much!",
      "requested": "Requested!"
    },
    "global": {
      "from": "From",
      "guest": "guest",
      "made-constell": "Made by Constell with love",
      "loading": "Loading...",
      "error-wrong": "Something went wrong",
      "guests": "guests"
    },
    "package-list": {
      "no-results": "No packages found"
    },
    "package": {
      "included": "Included",
      "generic-timeslot": "General",
      "all-day": "All day"
    },
    "buttons": {
      "plan-event": "Plan an event",
      "close": "Close",
      "book-it": "Request",
      "send-request": "Send request",
      "back-main": "Back to main",
      "continue": "Continue",
      "next": "Next",
      "send": "Send",
      "appointment": "Make an appointment"
    },
    "messages": {
      "sent-us-request": "Please mention us anything you want us to know, before a first appointment",
      "request-success": "Your quote request has been received.",
      "request-success-2": "We will now prepare your quote and contact you as soon as possible.",
      "request-success-3": "Please note! This is an appointment request. We will contact you to confirm. We look forward to meeting you soon and discussing your event in detail!",
      "errors": {
        "list-failed": "Something went wrong fetching the venue packages.",
        "request-failed": "Something went wrong sending your request."
      }
    },
    "navigation": {
      "back": "Back",
      "appointment": "Appointment",
      "contact": "Contact",
      "call": "Call us"
    },
    "form": {
      "title": "Quotation form",
      "subtitle": "We are happy to help you with your event. Fill out this form and we will contact you.",
      "all-events": "All events",
      "choose-event": "Choose your event",
      "event-type": "Event type",
      "event-date": "Event date wish",
      "guest-amount": "Guest amount",
      "your-name": "Name",
      "your-surname": "Surname",
      "email": "Your e-mail",
      "email-2": "Enter your email here",
      "company-name": "Company name",
      "phone-number": "Phone number",
      "name": "Name",
      "description": "Is there anything else we need to know?",
      "description-2": "Describe your event and wishes",
      "morning": "Morning",
      "afternoon": "Afternoon",
      "evening": "Evening",
      "whole-day": "Whole day",
      "whole-evening": "Whole day + evening",
      "multiday": "Multi-day event",
      "only": "Only this date",
      "around": "Around this peroid",
      "negotiable": "Everything is still negotiable",
      "split": "Tell us more about your event",
      "validations": {
        "required": "{field} is required",
        "min": "{field} must be at least 1"
      },
      "appointment-title": "Request an appointment",
      "appointment-desc": "Please note! We will contact you first to confirm."
    },
    "welcome": {
      "hello": "Hello!",
      "by": "Welcome to",
      "desc": "Make, seek & book here your",
      "start": "Start quote request",
      "see": "See packages"
    },
    "selection": {
      "quick": "Quick and easy",
      "quick-desc": "View our packages and ready-made events",
      "quick-action": "View packages",
      "journey": "Continue your journey",
      "journey-desc": "Your tailor-made quote & event with my advice and help",
      "journey-desc-2": "Discuss your wishes together",
      "journey-action": "Continue together",
      "packages": "Packages",
      "packages-desc": "Book ready-made events",
      "quote": "Make your own quote",
      "quote-desc": "Choose your program, snacks, rooms and dinner."
    },
    "survey-organizer": {
      "pete-precise": "Pete-precise",
      "perfectionist": "Practical",
      "delightfully-flexible": "Delightfully flexible",
      "practical": "Chaoot / YOLO"
    },
    "survey-theme": {
      "intieme-gezellig": "Intimate & cozy",
      "chic-formeel": "Chic & Formal",
      "feest-dansen": "Party & Dancing",
      "informeel-gezellig": "Informal & cozy"
    },
    "survey-appointment": {
      "date-only": "Only {weekday} {day} {month} {year}",
      "date-some": "A {weekday} in {month} {year}",
      "around-period": "Around that period",
      "negotiable": "Everything can still be discussed."
    },
    "date": {
      "when": "When is the great day?",
      "available": "(subject to availability)"
    },
    "amount": {
      "great": "Nice {name}. I already know some more!",
      "now": "Now let's talk about your guests...",
      "how-many": "How many guests are you expecting at your {event}?"
    },
    "descriptions": {
      "title": "How would you describe them?",
      "subtitle": "Select different options",
      "eaters": "Big eaters",
      "small": "Small stomach",
      "improv": "King of improv",
      "party": "Party animals",
      "diet": "Special diet",
      "vegetarian": "Vegetarian",
      "vegan": "Vegan",
      "sporty": "Sporty",
      "old": "Senior",
      "adult": "Adults",
      "kid": "Kids",
      "pregnant": "Pregnant",
      "handicap": "Minder valide",
      "dutch": "Dutch",
      "english": "International guests",
      "alcohol": "Alcohol free",
      "gourmand": "Gourmand",
      "drinkers": "Drinkers"
    },
    "budget": {
      "title": "What is your budget?"
    },
    "host": {
      "title-1": "I am {name},",
      "text-1": "your event manager, let's create your perfect event.",
      "title-2": "Nice to get to know you!",
      "title-2-5": "How nice! Congratulations!",
      "title-3": "How do you want to proceed?",
      "title-4": "Fantastic!",
      "title-5": "That's {days} day to go",
      "title-6": "That's {days} days to go",
      "title-6-5": "I will see what I can do",
      "title-7": "To get to know you!",
      "title-8": "Good to know!",
      "title-9": "What a good choice!",
      "title-10": "Please provide an appointment suggestion here in advance"
    },
    "appointment": {
      "title": "Would you like to make an appointment in advance?",
      "subtitle": "to discuss the quotation",
      "yes": "Yes",
      "no": "No",
      "desc": "Suggest a date to discuss your wishes with us.",
      "host": "I will let you know soon if I am available!"
    },
    "loading": {
      "title": "Great!!",
      "subtitle": "Everything has been completed and the results are being created."
    },
    "summary": {
      "title": "{name}, you have selected",
      "desc": "We have put together your perfect {event}…"
    },
    "results": {
      "title": "Your results",
      "subtitle": "Congratulations, a unique event for you!",
      "packages": "{number} packages!",
      "disc": "You might like these arrangements",
      "options": {
        "intimate": "Intimate",
        "big": "Big",
        "festive": "Festive",
        "elegant": "Elegant",
        "simple": "Simple",
        "unique": "Unique",
        "international": "International",
        "dutch": "Dutch",
        "talk": "Talk",
        "dance": "Dance"
      },
      "form": {
        "title": "Make your dream come true now",
        "desc": "Send your answers to us and receive a unique quote.",
        "name": "Name",
        "company": "Company name",
        "email": "Your e-mail",
        "description": "Is there anything else we need to know?"
      }
    },
    "call-us": {
      "phone": "Phone number",
      "email": "E-mail",
      "address": "Find us"
    },
    "tutorial": {
      "skip": "Skip explanation",
      "step-1": {
        "title": " Determine your wishes",
        "desc": "Select appetizers, drinks, dinner and more. And create a preliminary quote.",
        "button": "Next"
      },
      "step-2": {
        "title": " Adjust quotation",
        "desc": "Adjust the quote to your budget. So that you know exactly what your event will cost.",
        "button": "Next"
      },
      "step-3": {
        "title": " Send!",
        "desc": "Completely happy? Then we will discuss your event further together.",
        "button": "Start!!"
      }
    }
  },
  "countries": {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AX": "Åland Islands",
    "AZ": "Azerbaijan",
    "BS": "Bahamas (the)",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory (the)",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands (the)",
    "CF": "Central African Republic (the)",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands (the)",
    "CO": "Colombia",
    "KM": "Comoros (the)",
    "CD": "Congo (the Democratic Republic of the)",
    "CG": "Congo (the)",
    "CK": "Cook Islands (the)",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "CI": "Côte d'Ivoire",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic (the)",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (the) [Malvinas]",
    "FO": "Faroe Islands (the)",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories (the)",
    "GA": "Gabon",
    "GM": "Gambia (the)",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See (the)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (the Democratic People's Republic of)",
    "KR": "Korea (the Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic (the)",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands (the)",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (the Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands (the)",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger (the)",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands (the)",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines (the)",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "MK": "Republic of North Macedonia",
    "RO": "Romania",
    "RU": "Russian Federation (the)",
    "RW": "Rwanda",
    "RE": "Réunion",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan (the)",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands (the)",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates (the)",
    "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
    "UM": "United States Minor Outlying Islands (the)",
    "US": "United States of America (the)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  }
}