<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import { computed } from 'vue';

    const props = withDefaults(defineProps<{
        width?: number;
    }>(), { width: 30 });

    const height = computed(() => props.width * (26 / 24));
</script>

<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 24 26"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.5 17.5859C13.4531 17.5859 13.4062 17.5781 13.3594 17.5625C13.3125 17.5391 13.2695 17.5117
        13.2305 17.4805L9.97266 14.2109C9.70703 13.9453 9.57422 13.625 9.57422 13.25C9.57422 12.875 9.70703
        12.5547 9.97266 12.2891L13.2305 9.01953C13.3086 8.94922 13.3984 8.91406 13.5 8.91406C13.6016 8.91406
        13.6914 8.94922 13.7695 9.01953C13.8398 9.09766 13.875 9.1875 13.875 9.28906C13.875 9.39062 13.8398
        9.48047 13.7695 9.55859L10.5 12.8164C10.3828 12.9336 10.3242 13.0781 10.3242 13.25C10.3242 13.4219
        10.3828 13.5664 10.5 13.6836L13.7695 16.9414C13.8398 17.0195 13.875 17.1094 13.875 17.2109C13.875
        17.3125 13.8398 17.4023 13.7695 17.4805C13.7305 17.5117 13.6875 17.5391 13.6406 17.5625C13.5938 17.5781
        13.5469 17.5859 13.5 17.5859Z"
        />
    </svg>
</template>
