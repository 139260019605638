<script setup lang="ts">
    import Burger from '@/components/Burger.ce.vue';
    import { getContrastColor } from '@/helpers/text';
    import NavigationButton from '@shared/components/navigation/navigation-button.vue';

    defineProps<{
        brandColor?: string
        inAppointment?: boolean
        inContact?: boolean
        inCall?: boolean
    }>();

    const emit = defineEmits<{(event: 'contactSelected' | 'callSelected' | 'appointmentSelected')}>();

    const { t } = useI18n();
</script>

<template>
    <div class="side-bar">
        <div class="side-bar__top">
            <Burger
                :brand-color="brandColor"
                is-ghost
            />
        </div>
        <div class="side-bar__bottom">
            <!-- Calendar Page -->
            <NavigationButton
                :page-name="t('widget.navigation.appointment')"
                :style="{ color: getContrastColor(brandColor ?? '#282829')}"
                :is-active="inAppointment"
                main-icon="dateOutlined"
                secondary-icon="dateFilled"
                tooltip-direction="right"
                icon-size="medium"
                class="side-bar__bottom__nav-btn"
                is-button
                @click="emit('appointmentSelected')"
            />

            <!-- Contact Page -->
            <NavigationButton
                :page-name="t('widget.navigation.contact')"
                :style="{ color: getContrastColor(brandColor ?? '#282829')}"
                :is-active="inContact"
                main-icon="chatOutlined"
                secondary-icon="chatFilled"
                tooltip-direction="right"
                icon-size="medium"
                class="side-bar__bottom__nav-btn"
                is-button
                @click="emit('contactSelected')"
            />

            <!-- Call Page -->
            <NavigationButton
                :page-name="t('widget.navigation.call')"
                :style="{ color: getContrastColor(brandColor ?? '#282829')}"
                :is-active="inCall"
                main-icon="callOutlined"
                secondary-icon="callFilled"
                tooltip-direction="right"
                icon-size="medium"
                class="side-bar__bottom__nav-btn"
                is-button
                @click="emit('callSelected')"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .side-bar {
        z-index: var(--zindex-overlay);
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: var(--widget-brand-color);
        block-size: 100%;
        border-inline-start: 1px solid var(--border-secondary);
        inline-size: var(--size-12);
        padding-block: var(--spacing-03) var(--spacing-04);

        &__top,
        &__bottom {
            display: flex;
            inline-size: 100%;
        }

        &__top {
            justify-content: center;
        }

        &__bottom {
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: var(--spacing-03);
        }

        @include min-width(lg) {
            display: flex;
        }
    }
</style>
