<script setup lang="ts">
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import { type FormKitNode } from '@formkit/core';
    import { type Panels } from '@formkit/pro';
    import { ConstantsBreakpoints, ConstantsDateTime } from '@shared/constants';
    import { FormSizeTypeEnum, FormStyleEnum } from '@shared/constants/form-style';

    type DisabledDayCallbackTyping = ((node: FormKitNode, date: Date) => boolean) | undefined

    const props = defineProps<{
        name: string
        id?: string
        label?: string
        placeholder?: string
        format?: string
        sequence?: Panels[]
        minDate?: string | Date
        maxDate?: string | Date
        disabledDays?: DisabledDayCallbackTyping
        help?: string
        style?: FormStyleEnum.PRIMARY | FormStyleEnum.SECONDARY | FormStyleEnum.TERTIARY
        size?: FormSizeTypeEnum
        validation?: string | [rule: string, ...args: unknown[]][]
        validationMessages?: Record<string, string>
        hasWeekMode?: boolean
        isIconFilled?: boolean
        labelHidden?: boolean
        defaultValue?: string
        isLoading?: boolean
        isPickerOnly?: boolean
        disabled?: boolean
        isRight?: boolean
    }>();
    defineEmits<{(event: 'blur')}>();

    const { width } = useWindowSize();

    const isRequired = computed(() =>
        Array.isArray(props.validation) ? props.validation[0].includes('required') : props.validation?.includes('required'));
    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const isDesktop = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.DESKTOP));
    const skeletonHeight = computed(() => isMobile.value ? '56px' : isDesktop.value ? '64px' : '60px');
</script>

<template>
    <div
        class="date-input"
        :data-week-mode="hasWeekMode"
        :data-required="isRequired"
        :class="{'right-direction': isRight}"
    >
        <Skeleton
            v-if="isLoading"
            :height="skeletonHeight"
        />
        <FormKit
            v-else
            :id="id ? id : 'datepicker'"
            :label="label"
            :name="name"
            :validation="validation"
            :placeholder="placeholder"
            :format="format ?? ConstantsDateTime.dateFormatStandard"
            :value="defaultValue"
            :value-format="ConstantsDateTime.dateFormatFormKit"
            :sequence="sequence"
            :help="help"
            :disabled-days="disabledDays"
            :min-date="minDate"
            :max-date="maxDate"
            :max-scan="32"
            :week-start="1"
            :calendar-icon="isIconFilled ? 'dateFilled' : 'dateOutlined'"
            :wrapper-class="size ?? FormSizeTypeEnum.NORMAL"
            :inner-class="style ?? FormStyleEnum.PRIMARY"
            :label-class="labelHidden ? 'formkit-label--hidden' : ''"
            :picker-only="isPickerOnly"
            :disabled="disabled"
            :validation-messages="validationMessages"
            type="datepicker"
            month-format="MMMM"
            week-day-format="ddd"
            prev-icon="chevronLeft"
            next-icon="chevronRight"
            panel-wrapper-class="date-input__panel-wrapper"
            panel-class="date-input__panel"
            panel-header-class="date-input__panel-header"
            @blur="$emit('blur')"
        />
    </div>
</template>

<style lang="scss" scoped>
    .date-input :deep(.formkit-outer) {
        --date-input-width-large: 322px;
        --date-input-width-medium: 282px;
        --date-input-width-small: 242px;

        .formkit-inner {
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            padding: var(--spacing-03);
            border: 1px solid var(--border-tertiary);
            border-radius: var(--border-radius-xs);
            background-color: var(--background-form-input);
            block-size: var(--size-11);
            font-size: var(--font-medium);
            gap: var(--spacing-02);
            inline-size: 100%;
            line-height: 1;

            input {
                font-size: var(--font-medium);
                inline-size: 100%;
                line-height: 1;

                &::placeholder {
                    color: var(--text-placeholder);
                }
            }

            .formkit-calendar-icon {
                display: flex;
                align-items: center;
                cursor: pointer;

                > svg {
                    block-size: calc(var(--size-2) + 2px);
                    inline-size: calc(var(--size-2) + 2px);
                }
            }

            .date-input__panel-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: var(--border-radius-xs);
                background-color: var(--background-primary-invert);
                block-size: var(--size-9);
                inline-size: 100%;
                padding-block: var(--spacing-01);
                padding-inline: var(--spacing-03);

                button,
                .formkit-years-header {
                    color: var(--text-primary-invert);
                    font-size: var(--font-regular);
                    text-transform: capitalize;
                }

                .formkit-prev-label ,
                .formkit-next-label {
                    display: none;
                }

                .formkit-prev-icon,
                .formkit-next-icon {
                    display: flex;

                    svg {
                        block-size: var(--size-3);
                        color: var(--text-primary-invert);
                        inline-size: var(--size-3);
                    }
                }
            }

            .date-input__panel-wrapper {
                position: absolute;
                z-index: var(--zindex-stack-once);
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid var(--border-primary);
                border-radius: var(--border-radius-xs);
                background-color: var(--background-secondary);
                block-size: auto;
                inline-size: var(--date-input-width-small);
                inset-block-start: calc(100% + var(--spacing-02));
                inset-inline-start: 0;
                padding-block: var(--spacing-03) var(--spacing-04);
                padding-inline: var(--spacing-03);
                text-transform: capitalize;
            }

            .date-input__panel {
                display: flex;
                justify-content: center;
                inline-size: 100%;
                margin-block-start: var(--spacing-04);
            }

            .formkit-calendar {
                border-spacing: 0;
                inline-size: 100%;
                margin-block: 0;
                margin-inline: var(--spacing-03);
            }

            // Day panel
            .formkit-week-day {
                color: var(--text-primary);
                font-size: var(--font-regular);
                font-weight: var(--font-weight-normal);
                padding-block-end: var(--spacing-03);
            }

            .formkit-day-cell {
                cursor: pointer;

                .formkit-day {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    block-size: var(--size-3);
                    color: var(--text-primary);
                    font-size: var(--font-regular);
                    font-weight: var(--font-weight-light);
                    inline-size: var(--size-3);
                    line-height: 20px;
                }

                &[tabindex="0"] {
                    .formkit-day {
                        background-color: var(--background-primary-highlight);
                    }
                }

                &[aria-selected=true] {
                    .formkit-day {
                        background-color: var(--background-primary-invert);
                        color: var(--text-primary-invert);
                    }
                }

                &[data-disabled=true] {
                    pointer-events: none;

                    .formkit-day {
                        color: var(--text-disabled-1);
                    }
                }
            }

            // Month panel & Year panel
            .formkit-months-header {
                margin-block: 0;
                margin-inline: auto;
            }

            .formkit-years,
            .formkit-months {
                display: flex;
                flex-direction: column;
                inline-size: 100%;
                margin-block-start: var(--spacing-03);
            }

            .formkit-year,
            .formkit-month {
                padding: var(--spacing-03);
                border-radius: var(--border-radius-xs);
                color: var(--text-primary);
                cursor: pointer;
                font-weight: var(--font-weight-medium);
                inline-size: 100%;
                text-align: center;

                &:hover {
                    background-color: var(--color-black);
                    color: var(--color-white);
                }
            }

            &:hover,
            &:focus {
                background-color: var(--background-form-input-active);
            }

            &.secondary {
                &:hover {
                    background-color: var(--background-form-input-hover);
                }
            }

            @include min-width(md) {
                block-size: var(--size-12);
            }

            @include min-width(lg) {
                block-size: var(--size-13);
            }
        }

        &[data-invalid=true] .formkit-inner {
            border-color: var(--border-negative);
        }

        &[data-expanded=true] .formkit-inner {
            background-color: var(--background-form-input-active);
        }

        // Medium sizes
        @include min-width(md) {
            .formkit-wrapper .date-input__panel-wrapper {
                inline-size: var(--date-input-width-medium);

                .formkit-day {
                    block-size: var(--size-4);
                    inline-size: var(--size-4);
                }
            }
        }

        // Large sizes
        @include min-width(lg) {
            .formkit-wrapper .date-input__panel-wrapper {
                inline-size: var(--date-input-width-large);

                .formkit-calendar {
                    margin-block: 0;
                    margin-inline: var(--spacing-03) var(--spacing-04);
                }

                .formkit-day {
                    block-size: var(--size-5);
                    inline-size: var(--size-5);
                }
            }
        }
    }

    .date-input[data-week-mode=true] :deep(.formkit-week:hover) {
        background-color: var(--background-primary-highlight);

        :first-child {
            border-end-start-radius: var(--border-radius-circle);
            border-start-start-radius: var(--border-radius-circle);
        }

        :last-child {
            border-end-end-radius: var(--border-radius-circle);
            border-start-end-radius: var(--border-radius-circle);
        }
    }

    .date-input[data-required=true] :deep(.formkit-outer) {
        .formkit-label::after {
            color: var(--text-secondary);
            content:" *";
        }
    }

    .date-input.right-direction :deep(.formkit-outer .formkit-wrapper .date-input__panel-wrapper) {
        inset-inline: auto 0;
    }
</style>
