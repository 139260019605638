<script setup lang="ts">
    import { type FormKitNode } from '@formkit/core';
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import { ConstantsBreakpoints } from '@shared/constants';
    import { FormSizeTypeEnum, FormStyleEnum } from '@shared/constants/form-style';
    import { useWindowSize } from '@vueuse/core';
    import { computed } from 'vue';

    const props = defineProps<{
        modelValue?: string
        name: string
        id?: string
        label?: string
        placeholder?: string
        validation?: string | [rule: string, ...args: unknown[]][]
        help?: string
        style?: FormStyleEnum.PRIMARY | FormStyleEnum.SECONDARY
        size?: FormSizeTypeEnum.NORMAL | FormSizeTypeEnum.EXTRA
        suffixIcon?: string,
        prefixIcon?: string,
        disabled?: boolean,
        isDecimal?: boolean,
        labelHidden?: boolean,
        validationVisibility?: 'submit' | 'live' | 'blur' | 'dirty' | undefined,
        validationRules?: Record<string, (node: FormKitNode, ...args: any[]) => boolean | Promise<boolean>>, // eslint-disable-line @typescript-eslint/no-explicit-any, func-call-spacing
        validationMessages?: Record<string, string>
        isLoading?: boolean
        autofocus?: boolean
        value?: string
        readonly?: boolean
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue', value: string): void
        (event: 'suffixIconClick' | 'change' | 'blur' | 'enter'): void
    }>();

    const { width } = useWindowSize();

    const valueModel = ref(props.modelValue ?? '');

    const isNumerical = computed(() =>
        Array.isArray(props.validation) ? props.validation[0].includes('number') : props.validation?.includes('number'));

    const isDecimalNumber = computed(() => {
        // Prop indicates that it's a decimal
        if (props.isDecimal) return true;
        if (isNumerical.value) {
            // FormKit validation indicates that it is a decimal
            if (Array.isArray(props.validation)) {
                return props.validation[0].includes('float');
            }
            return props.validation?.includes('float');
        }
        return false;
    });

    const isRequired = computed(() =>
        Array.isArray(props.validation) ? props.validation[0].includes('required') : props.validation?.includes('required'));
    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const isDesktop = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.DESKTOP));
    const skeletonHeight = computed(() => isMobile.value ? '56px' : isDesktop.value ? '64px' : '60px');

    // Sync prop modelValue with internal valueModel
    watch(() => props.modelValue, newValue => {
        if (newValue !== valueModel.value) {
            valueModel.value = newValue ?? '';
        }
    });

    // Emit updated value when valueModel changes
    watch(valueModel, newValue => {
        emit('update:modelValue', newValue);
    });
</script>

<template>
    <div
        class="text-input"
        :data-required="isRequired"
    >
        <Skeleton
            v-if="isLoading"
            :height="skeletonHeight"
        />
        <FormKit
            v-else
            :id="id ?? name"
            v-model="valueModel"
            :label="label"
            :name="name"
            :placeholder="placeholder"
            :validation-rules="validationRules"
            :validation-messages="validationMessages"
            :validation="
                isDecimalNumber
                    ? `${validation}|validateDecimalFormat`
                    : validation"
            :number="isNumerical && !isDecimalNumber ? true : undefined"
            :validation-visibility="validationVisibility"
            :help="help"
            :inner-class="style ?? FormStyleEnum.PRIMARY"
            :wrapper-class="size ?? FormSizeTypeEnum.NORMAL"
            :suffix-icon="suffixIcon"
            :suffix-icon-class="suffixIcon === 'removeOutlined' ? 'formkit-icon--clickable formkit-icon--negative' : ''"
            :prefix-icon="prefixIcon"
            :disabled="disabled"
            :label-class="labelHidden ? 'formkit-label--hidden' : ''"
            :autofocus="autofocus"
            :value="value"
            :readonly="readonly"
            type="text"
            @suffix-icon-click="$emit('suffixIconClick')"
            @change="$emit('change')"
            @blur="$emit('blur')"
            @keydown.enter="$emit('enter')"
        >
            <template #suffix>
                <slot name="append" />
            </template>
        </FormKit>
    </div>
</template>

<style scoped lang="scss">
    .text-input :deep(.formkit-outer) {
        .formkit-inner {
            input {
                box-sizing: border-box;
                padding: var(--spacing-03);
                border: 1px solid var(--border-tertiary);
                border-radius: var(--border-radius-xs);
                background-color: var(--background-form-input);
                block-size: var(--size-11);
                font-size: var(--font-medium);
                inline-size: 100%;
                line-height: 1;

                &::placeholder {
                    color: var(--text-placeholder);
                }

                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }

                @include min-width(md) {
                    block-size: var(--size-12);
                }

                @include min-width(lg) {
                    block-size: var(--size-13);
                }
            }

            &.secondary input {
                &:hover {
                    background-color: var(--background-form-input-hover);
                }

                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }
            }
        }

        &[data-invalid=true] .formkit-inner {
            input,
            &.secondary input {
                border-color: var(--border-negative);
            }
        }

        &[data-required=true] {
            .formkit-label::after {
                color: var(--text-secondary);
                content:" *";
            }
        }
    }
</style>
