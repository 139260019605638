/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { ProgrameeTyping } from '@/typings/widget';
import { useI18n } from 'vue-i18n';

export const formatCurrency = (
    cents: number, currency: string | undefined = 'EUR', locale = 'NL',
): string => new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
})
    .format(cents / 100);

export const formatCurrencyNoCent = (
    cents: number, currency: string | undefined = 'EUR', locale = 'NL',
): string => new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0, // No cents
    maximumFractionDigits: 0, // No cents
})
    .format(cents);

export const formatNumber = (number: number, options = {}): string => {
    const { locale } = useI18n();

    return new Intl.NumberFormat(locale.value, options)
        .format(number);
};

export const calculateTotalMinutes = (programme: ProgrameeTyping[]): number => {
    if (!programme?.length) return 0;
    if (!programme[0].timeslots.length) return 0;

    const [{ startDateTime }] = programme[0].timeslots;
    const { endDateTime } = programme[0].timeslots[programme[0].timeslots.length - 1];

    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);

    const diffInMs = Math.abs(endDate.getTime() - startDate.getTime());

    return diffInMs / (1000 * 60);
};

export const totalHours = (programme: ProgrameeTyping[]): string => {
    const { t } = useI18n();
    const minutes = calculateTotalMinutes(programme);

    if (minutes % 60 === 0) {
        return t('localize.hours.short', { hours: formatNumber(minutes / 60, { maximumSignificantDigits: 1 }) });
    }

    return t('localize.hoursAndMinutes.short', {
        hours: formatNumber(Math.floor(minutes / 60), { maximumSignificantDigits: 1 }),
        minutes: formatNumber(Math.floor(minutes % 60), { maximumSignificantDigits: 1 }),
    });
};

export const formatTime = (time: string): string => {
    const date = new Date(time);
    const hours = date.getHours()
        .toString()
        .padStart(2, '0');
    const minutes = date.getMinutes()
        .toString()
        .padStart(2, '0');
    return `${hours}:${minutes}`;
};
