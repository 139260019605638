export const SURVEY_APPOINTMENT = [
    {
        value: 'date-only',
        label: 'widget.survey-appointment.date-only',
        notranslate: true,
    },
    {
        value: 'date-some',
        label: 'widget.survey-appointment.date-some',
        notranslate: true,
    },
    {
        value: 'around-period',
        label: 'widget.survey-appointment.around-period',
    },
    {
        value: 'negotiable',
        label: 'widget.survey-appointment.negotiable',
    },
];

export const SURVEY_ORGANIZER = [
    {
        value: 'pete-precise',
        label: 'widget.survey-organizer.pete-precise',
    },
    {
        value: 'perfectionist',
        label: 'widget.survey-organizer.perfectionist',
    },
    {
        value: 'delightfully-flexible',
        label: 'widget.survey-organizer.delightfully-flexible',
    },
    {
        value: 'practical',
        label: 'widget.survey-organizer.practical',
    },
];

export const SURVEY_THEME = [
    {
        value: 'intieme-gezellig',
        label: 'widget.survey-theme.intieme-gezellig',
        intimate: 25,
        dance: 40,
    },
    {
        value: 'chic-formeel',
        label: 'widget.survey-theme.chic-formeel',
        intimate: 10,
        dance: 0,
    },
    {
        value: 'feest-dansen',
        label: 'widget.survey-theme.feest-dansen',
        intimate: 80,
        dance: 100,
    },
    {
        value: 'informeel-gezellig',
        label: 'widget.survey-theme.informeel-gezellig',
        intimate: 40,
        dance: 50,
    },
];
