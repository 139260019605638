<script setup lang="ts">
    import { FormKitMessages } from '@formkit/vue';
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import { ConstantsBreakpoints } from '@shared/constants';
    import { FormSizeTypeEnum, FormStyleEnum } from '@shared/constants/form-style';
    import { HelpersCountries } from '@shared/helpers';
    import { type ComputedRef, type Ref } from 'vue';
    import Dropdown from './dropdown.vue';

    const { t } = useI18n();

    const props = defineProps<{
        validation?: string | [rule: string, ...args: unknown[]][]
        help?: string
        size?: FormSizeTypeEnum.NORMAL | FormSizeTypeEnum.EXTRA
        validationMessages?: Record<string, string>
        disabled?: boolean
        label?: string
        labelHidden?: boolean
        isLoading?: boolean
    }>();

    const { width } = useWindowSize();

    const phoneInput = ref();
    const errorMessages: Ref<(string | number | boolean)[]> = ref([]);

    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const isDesktop = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.DESKTOP));
    const skeletonHeight = computed(() => isMobile.value ? '56px' : isDesktop.value ? '64px' : '60px');

    const countryOptions = Object.keys(HelpersCountries.countryPhonePrefixes)
        .map(countryCode => {
            const { translation, prefix } = HelpersCountries.getTranslationAndPrefix(countryCode);
            return {
                label: prefix,
                value: prefix,
                writtenAsset: t(translation),
            };
        });

    const isDropdownThin: ComputedRef<boolean> = computed(() =>
        props.size === FormSizeTypeEnum.NORMAL);

    const updateErrorMessages = (messages: (string | number | boolean)[]): void => {
        errorMessages.value = messages;
    };

    const isRequired: ComputedRef<boolean> = computed(() =>
        Array.isArray(props.validation) ? props.validation[0].includes('required') : props.validation?.includes('required') ?? false);
</script>

<template>
    <div
        class="phone-input"
        :class="{ 'phone-input--label-hidden': labelHidden }"
        :data-required="isRequired"
    >
        <span
            v-if="label && !isLoading"
            class="phone-input__label"
        >
            {{ label }}
        </span>
        <div class="phone-input__group">
            <div class="phone-input__inner">
                <Dropdown
                    :disabled="disabled"
                    :options="countryOptions"
                    :is-thin="isDropdownThin"
                    :style="FormStyleEnum.SECONDARY"
                    :size="size"
                    :is-loading="isLoading"
                    name="phoneCountryPrefix"
                    placeholder="+31"
                    label-hidden
                    extract-error-messages
                    @error-found="updateErrorMessages"
                />
                <Skeleton
                    v-if="isLoading"
                    :height="skeletonHeight"
                />
                <FormKit
                    v-else
                    ref="phoneInput"
                    :label="label"
                    :validation="`length:8,32|number|${validation}`"
                    :disabled="disabled"
                    :wrapper-class="size"
                    :help="help"
                    :validation-messages="validationMessages"
                    type="tel"
                    name="phoneNumber"
                    placeholder="0 00 00 00 00"
                    label-class="formkit-label--hidden"
                    inner-class="phone-input__phone"
                />
            </div>
        </div>
        <div class="formkit-outer">
            <ul class="formkit-messages">
                <li
                    v-for="(message, index) in errorMessages"
                    :key="`message-${index}`"
                    class="formkit-message"
                >
                    {{ message }}
                </li>
            </ul>
            <FormKitMessages :node="phoneInput?.node" />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .phone-input :deep(.formkit-outer) {
        .formkit-inner {
            input {
                box-sizing: border-box;
                padding: var(--spacing-03);
                border: 1px solid var(--border-tertiary);
                border-radius: var(--border-radius-xs);
                background-color: var(--background-form-input);
                block-size: var(--size-11);
                font-size: var(--font-medium);
                inline-size: 100%;
                line-height: 1;

                &::placeholder {
                    color: var(--text-placeholder);
                }

                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }

                @include min-width(md) {
                    block-size: var(--size-12);
                }

                @include min-width(lg) {
                    block-size: var(--size-13);
                }
            }

            &.secondary input {
                &:hover {
                    background-color: var(--background-form-input-hover);
                }

                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }
            }
        }

        &[data-invalid=true] .formkit-inner {
            input,
            &.secondary input {
                border-color: var(--border-negative);
            }
        }

        &[data-required=true] {
            .formkit-label::after {
                color: var(--text-secondary);
                content:" *";
            }
        }
    }

    .phone-input {
        &__label {
            display: inline-block;
            padding: 0;
            color: var(--text-secondary);
            font-size: var(--font-regular);
            font-weight: var(--font-weight-light);
            margin-block-end: var(--spacing-01);

            &::first-letter {
                text-transform: capitalize;
            }
        }

        &__inner {
            display: flex;
            gap: var(--spacing-02);
        }

        // eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
        .formkit-outer{
            flex: 2;
        }
    }
</style>
