<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import AmountCard from '@/components/cards/AmountCard.ce.vue';
    import AppointmentCalendarCard from '@/components/cards/AppointmentCalendarCard.ce.vue';
    import AppointmentCard from '@/components/cards/AppointmentCard.ce.vue';
    import BudgetCard from '@/components/cards/BudgetCard.ce.vue';
    import CalendarCard from '@/components/cards/CalendarCard.ce.vue';
    import FinalResultCard from '@/components/cards/FinalResultCard.ce.vue';
    import LoadingCard from '@/components/cards/LoadingCard.ce.vue';
    import NameFieldCard from '@/components/cards/NameFieldCard.ce.vue';
    import OptionsCard from '@/components/cards/OptionsCard.ce.vue';
    import PathSelectionCard from '@/components/cards/PathSelectionCard.ce.vue';
    import PreTextCard from '@/components/cards/PreTextCard.ce.vue';
    import SurveyCard from '@/components/cards/SurveyCard.ce.vue';
    import PackageSelection from '@/components/PackageSelection.ce.vue';
    import { DESCRIPTIONS } from '@/constants/descriptions';
    import { VENUE_HOST_EVENT, VENUE_HOST_RESET } from '@/constants/events';
    import { SURVEY_APPOINTMENT, SURVEY_ORGANIZER, SURVEY_THEME } from '@/constants/survey';
    import { formatCurrencyNoCent } from '@/helpers/formatting';
    import { type EventBusType } from '@/plugins/event-bus-plugin';
    import { DateTime } from '@shared/helpers';
    import { type Ref } from 'vue';

    interface SurveyItem {
        value: string;
        label: string;
        notranslate?: boolean
    }

    const props = defineProps<{
        venue: string
        maxGuestAmount?: number
        minGuestAmount?: number
        guestAmountStep?: number
        maxBudgetAmount?: number
        minBudgetAmount?: number
        budgetStep?: number
        locale: string
        error?: boolean
        brandColor?: string
    }>();

    const emit = defineEmits<{(event: 'backToMain')}>();

    const eventBus = inject<EventBusType>('eventBus');

    const { t } = useI18n();

    // Stores
    const pathStore = useWidgetPathStore();
    const {
        step,
        bookerName,
        eventTypeId,
        selectedPath,
        selectedDate,
        surveyOrganizer,
        surveyAppointment,
        surveyTheme,
        guestAmount,
        descriptionIds,
        budgetAmount,
        appointment,
        appointmentDate,
    } = storeToRefs(pathStore);
    const { resetPathStore } = pathStore;
    const { venueEventTypes } = storeToRefs(useWidgetVenueStore());

    const scrollContainer: Ref<HTMLElement | null> = ref(null);
    const selectPackages: Ref<boolean> = ref(false);

    // Computed
    // eslint-disable-next-line max-lines-per-function
    const finalSelectionMap: ComputedRef<{id: number, name: string, icon?: string}[]> = computed(() => {
        const eventTypeAnswer = {
            id: Math.floor(Math.random()),
            name: venueEventTypes.value.find(event => Number(event.id) === Number(eventTypeId.value))?.name ?? '',
        };
        const surveyOrganizerAnswer = {
            id: Math.floor(Math.random()),
            name: SURVEY_ORGANIZER.find(org => org.value === surveyOrganizer.value)?.label ?? '',
        };
        const surveyThemeAnswer = {
            id: Math.floor(Math.random()),
            name: SURVEY_THEME.find(thm => thm.value === surveyTheme.value)?.label ?? '',
        };
        const surveyAppointmentAnswer = {
            id: Math.floor(Math.random()),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            name: SURVEY_APPOINTMENT.find(thm => thm.value === surveyAppointment.value)?.label ?? '',
        };
        const descriptionAnswers = descriptionIds.value.map(id => ({
            id: Math.floor(Math.random()),
            name: DESCRIPTIONS.find(desc => desc.id === id)?.name ?? '',
            icon: DESCRIPTIONS.find(desc => desc.id === id)?.icon ?? '',
        }));
        const guestAnswer = {
            id: Math.floor(Math.random()),
            name: `${guestAmount.value} ${t('widget.global.guests')}`,
        };
        const budgetAnswer = {
            id: Math.floor(Math.random()),
            name: formatCurrencyNoCent(
                budgetAmount.value, 'EUR', props.locale,
            ) ?? '',
        };

        return [
            eventTypeAnswer,
            guestAnswer,
            surveyOrganizerAnswer,
            surveyThemeAnswer,
            surveyAppointmentAnswer,
            ...descriptionAnswers,
            budgetAnswer,
        ] as {id: number, name: string, icon?: string}[];
    });

    const eventName: ComputedRef<string> = computed(() => venueEventTypes.value.find(event => Number(event.id) === Number(eventTypeId.value))?.name ?? '');

    // Methods
    const onVenueHost = (next: number): void => {
        eventBus?.emit(VENUE_HOST_EVENT, { step: next });
    };

    const handleToMain = (): void => {
        resetPathStore();
        emit('backToMain');
        eventBus?.emit(VENUE_HOST_RESET);
    };

    const scrollToTop = ():void => {
        if (scrollContainer.value) scrollContainer.value.scrollTop = 0;
    };

    const toSurveyAppointmentItem = (item: SurveyItem): SurveyItem => {
        if (item.value === 'date-only' || item.value === 'date-some') {
            const date = DateTime.dayjsObject(String(selectedDate.value));

            const [
                weekday, day, month, year,
            ] = date.format('dddd D MMMM YYYY')
                .split(' ');

            item.label = t(item.label, {
                weekday,
                day,
                month,
                year,
            });
        }
        return item;
    };

    const surveyAppointmentItems = computed(() => SURVEY_APPOINTMENT.map(toSurveyAppointmentItem));

    const onCalendarSelected = (next: number, difference: number): void => {
        eventBus?.emit(VENUE_HOST_EVENT, {
            step: next,
            param: { days: difference },
        });
    };

    // Hooks
    onMounted(() => {
        step.value = 1;
    });
</script>

<template>
    <div class="path-view">
        <div
            v-if="selectPackages"
            ref="scrollContainer"
            class="path-view__scroll"
        >
            <PackageSelection
                :locale="locale"
                :venue="venue"
                :error="error"
                in-path
                @scroll-to-top="scrollToTop"
                @back-to-main="handleToMain"
                @back-to-path="selectPackages = false"
            />
        </div>
        <template v-else>
            <NameFieldCard
                v-if="[1, 1.5].includes(step)"
                v-model="bookerName"
                :step="1"
                @next="step = $event"
            />
            <OptionsCard
                v-if="[1.5, 2, 2.5].includes(step)"
                v-model="eventTypeId"
                :items="venueEventTypes"
                :step="2"
                :title="t('widget.titles.event-type-title', { name: bookerName ?? 'Customer' })"
                :subtitle="t('widget.titles.event-type-subtitle')"
                @next="step = $event"
                @started="() => onVenueHost(2)"
                @selected="() => onVenueHost(2.5)"
            />
            <PathSelectionCard
                v-if="[2.5, 3, 3.5].includes(step)"
                v-model="selectedPath"
                :step="3"
                :brand-color="brandColor"
                @next="step = $event"
                @select-packages="selectPackages = true"
                @started="() => onVenueHost(3)"
                @selected="$event => onVenueHost($event)"
            />
            <CalendarCard
                v-if="[3.5, 4, 4.5].includes(step)"
                v-model="selectedDate"
                :step="4"
                @next="step = $event"
                @selected="$event => onCalendarSelected(4, $event)"
                @exited="() => onVenueHost(4.5)"
            />
            <SurveyCard
                v-if="[4.5, 5, 5.5].includes(step)"
                v-model="surveyAppointment"
                :title="t('widget.titles.survey-appointment-title')"
                :subtitle="t('widget.titles.survey-appointment-subtitle')"
                :items="surveyAppointmentItems"
                :step="5"
                @next="step = $event"
                @exiting="() => onVenueHost(5)"
                @exited="() => onVenueHost(5.5)"
            />
            <SurveyCard
                v-if="[5.5, 6, 6.5].includes(step)"
                v-model="surveyOrganizer"
                :title="t('widget.titles.survey-organizer-title')"
                :subtitle="t('widget.titles.survey-organizer-subtitle')"
                :items="SURVEY_ORGANIZER"
                :step="6"
                @started="() => onVenueHost(6)"
                @exiting="() => onVenueHost(6.5)"
                @next="step = $event"
            />
            <SurveyCard
                v-if="[6.5, 7, 7.5].includes(step)"
                v-model="surveyTheme"
                :title="t('widget.titles.survey-theme-title')"
                :subtitle="t('widget.titles.survey-theme-subtitle')"
                :items="SURVEY_THEME"
                :step="7"
                @starting="() => onVenueHost(7)"
                @exiting="() => onVenueHost(7.5)"
                @next="step = $event"
            />
            <AmountCard
                v-if="[7.5, 8, 8.5].includes(step)"
                v-model="guestAmount"
                :name="bookerName"
                :step="8"
                :max-guest-amount="maxGuestAmount"
                :min-guest-amount="minGuestAmount"
                :guest-amount-step="guestAmountStep"
                :event="eventName"
                @started="() => onVenueHost(8)"
                @next="step = $event"
            />
            <OptionsCard
                v-if="[8.5, 9, 9.5].includes(step)"
                v-model="descriptionIds"
                :items="DESCRIPTIONS"
                :step="9"
                :title="t('widget.descriptions.title')"
                :subtitle="t('widget.descriptions.subtitle')"
                multiple
                with-button
                @next="step = $event"
            />
            <BudgetCard
                v-if="[9.5, 10, 10.5].includes(step)"
                v-model="budgetAmount"
                :step="10"
                :max-budget-amount="maxBudgetAmount"
                :min-budget-amount="minBudgetAmount"
                :budget-step="budgetStep"
                @next="step = $event"
            />
            <AppointmentCard
                v-if="[10.5, 11, 11.5, 13.5].includes(step)"
                v-model="appointment"
                :step="11"
                @next="step = $event"
            />
            <AppointmentCalendarCard
                v-if="[11.5, 12, 12.5].includes(step)"
                v-model="appointmentDate"
                :step="12"
                @next="step = $event"
                @started="() => onVenueHost(12)"
                @exited="() => onVenueHost(12.5)"
            />
            <!-- This is the end of the path so step has to be further from the last in path to avoid overlapping -->
            <LoadingCard
                v-if="[12.5, 13.5, 14, 14.5].includes(step)"
                :step="14"
                @next="step = $event"
            />
            <OptionsCard
                v-if="[14.5, 15, 15.5].includes(step)"
                :items="finalSelectionMap"
                :step="15"
                :title="t('widget.summary.title', { name: bookerName })"
                read-only
                auto-exit
                @next="step = $event"
            />
            <PreTextCard
                v-if="[15.5, 16, 16.5].includes(step)"
                :step="16"
                :event="eventName"
                @next="step = $event"
            />
            <FinalResultCard
                v-if="[16.5, 17].includes(step)"
                :venue="venue"
                :name="bookerName"
                :date="selectedDate"
                :locale="locale"
                :error="error"
                :guests="guestAmount"
                :total-guest="maxGuestAmount"
                :event-type="eventTypeId"
                :event-types="venueEventTypes"
                :message-data="{
                    surveyOrganizer,
                    surveyTheme,
                    surveyAppointment,
                    descriptionIds,
                    budgetAmount,
                    appointmentDate,
                }"
                @back-to-main="handleToMain"
            />
        </template>
    </div>
</template>

<style lang="scss" scoped>
.path-view {
    overflow: hidden;
    block-size: 100%;
    inline-size: 100%;

    &__scroll {
        block-size: 100%;
        min-block-size: 100%;
        -ms-overflow-style: none; /* IE & Edge */
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari */
        }
    }
}
</style>
