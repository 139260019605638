<script setup lang="ts">
    import CloseIcon from '@/components/icons/CloseIcon.ce.vue';
    import ConstellIcon from '@/components/icons/ConstellIcon.ce.vue';
    import { getContrastColor } from '@/helpers/text';
    import { computed, ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        openStateLabel: string | undefined,
        closedStateLabel: string
        brandColor?: string
    }>();

    const emit = defineEmits<{(event: 'letTheWindowGrow')}>();

    const { t } = useI18n();

    const isOpenScope = ref(false);

    const toggleWindow = (): void => {
        isOpenScope.value = !isOpenScope.value;
        emit('letTheWindowGrow');
    };

    const buttonLabel = computed(() => isOpenScope.value
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        ? props.openStateLabel || t('widget.buttons.close')
        : props.closedStateLabel || t('widget.buttons.plan-event'));

</script>

<template>
    <button
        class="btn-fab"
        type="button"
        :class="[{ close: isOpenScope }]"
        :style="{ color: getContrastColor(brandColor ?? '#282829')}"
        @click="toggleWindow"
    >
        <!-- The span is needed, otherwise styling breaks -->
        <span class="btn-fab-inner">
            <div class="btn-fab-inner__icon">
                <ConstellIcon
                    v-if="!isOpenScope"
                    :width="12"
                />
                <CloseIcon
                    v-if="isOpenScope"
                    :width="24"
                />
            </div>
            {{ buttonLabel }}
        </span>
    </button>
</template>

<style lang="scss" scoped>
.btn-fab {
    border: none;
    border-radius: 5px;
    background: var(--widget-brand-color);
    cursor: pointer;
    padding-block: 16px;
    padding-inline: 16px 24px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    &.close {
        padding-inline-start: 14px;
    }

    span {
        color: inherit;
        font: 16px var(--font-primary);
        font-weight: 400;
    }
}

.btn-fab-inner {
    display: flex;
    align-items: center;
    gap: 8px;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        block-size: 24px;
        color: inherit;
        inline-size: 24px;
    }
}
</style>
