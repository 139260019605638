<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import { VENUE_HOST_EVENT, VENUE_HOST_RESET } from '@/constants/events';
    import { getContrastColor } from '@/helpers/text';
    import { type EventBusType } from '@/plugins/event-bus-plugin';
    import { type EventTypeTyping } from '@/typings/widget';
    import Icon from '@shared/components/ui/icon/icon.vue';
    import { useSafeTimeout } from '@shared/composables/useTimeout';
    import { type Ref } from 'vue';

    const EXIT_DELAY = 3000;
    const NEXT_PART = 0.5;
    const VENUE_HOST_DELAY = 100;

    const props = defineProps<{
        venueName: string | null
        venueEventTypes: EventTypeTyping[]
        brandColor?: string
    }>();

    const emit = defineEmits<{(event: 'next' | 'goToPackages')}>();

    const eventBus = inject<EventBusType>('eventBus');
    const safeTimeout = useSafeTimeout();

    const { t } = useI18n();

    const dynamicIndex: Ref<number> = ref(0);
    const intervalId:Ref<Timer> = ref({} as Timer);
    const invisible: Ref<boolean> = ref(false);

    const eventTypeChanger: ComputedRef<string[]> = computed(() => props.venueEventTypes.map(eventType => eventType.name));

    // Function to loop through event types over time
    const loopIndexOverTime = (): void => {
        const timeInSeconds = 2; // Total time for the animation cycle
        const intervalDuration = timeInSeconds * 1000; // Convert to milliseconds
        dynamicIndex.value = 0;

        intervalId.value = setInterval(() => {
            dynamicIndex.value = (dynamicIndex.value + 1) % eventTypeChanger.value.length;
        }, intervalDuration);
    };

    const onNext = (): void => {
        invisible.value = true;
        eventBus?.emit(VENUE_HOST_EVENT, { step: NEXT_PART });
        safeTimeout.setSafeTimeout(() => {
            emit('next');
        }, EXIT_DELAY);
    };

    // Watch for changes to venueEventTypes and restart the animation
    watch(() => props.venueEventTypes, () => {
        clearInterval(intervalId.value);
        loopIndexOverTime();
    });

    // Clean up interval on unmount
    onBeforeUnmount(() => {
        clearInterval(intervalId.value);
    });

    onMounted(() => {
        eventBus?.emit(VENUE_HOST_RESET);
        setTimeout(() => {
            eventBus?.emit(VENUE_HOST_EVENT, { step: 0 });
        }, VENUE_HOST_DELAY);
    });
</script>

<template>
    <div class="welcome-screen">
        <div
            id="welcome-host-target"
            class="welcome-screen__host-target"
            :class="{'welcome-screen__host-target--invisible': invisible}"
        />
        <div
            class="welcome-screen__content"
            :class="{'welcome-screen--invisible': invisible}"
        >
            <h1>{{ t('widget.welcome.hello') }}</h1>
            <h3>{{ `${t('widget.welcome.by')} ${venueName}.` }}</h3>
            <span
                class="welcome-screen__text"
            >
                {{ `${t('widget.welcome.desc')} ` }}
                <strong
                    v-for="(eventType, evIndex) in eventTypeChanger"
                    :key="evIndex"
                    class="welcome-screen__animated-text"
                    :class="{ visible: evIndex === dynamicIndex, fading: evIndex !== dynamicIndex }"
                >
                    {{ eventType }}
                </strong>
            </span>
        </div>
        <div class="welcome-screen__host-spacer" />
        <div
            class="welcome-screen__flow"
            :class="{'welcome-screen--invisible': invisible}"
        >
            <button
                :style="{ color: getContrastColor(brandColor ?? '#282829')}"
                class="btn btn--icon-right welcome-screen__start"
                type="button"
                @click="onNext"
            >
                {{ t('widget.welcome.start') }}
                <Icon
                    :view-box-width="28"
                    :view-box-height="24"
                    name="arrowWidgetRight"
                    size="medium"
                />
            </button>
        </div>
        <div
            class="welcome-screen__see"
            :class="{'welcome-screen--invisible': invisible}"
        >
            <div
                class="welcome-screen__see__content"
                @click="emit('goToPackages')"
            >
                {{ t('widget.welcome.see') }}
                <Icon
                    name="chevronBottom"
                    size="medium"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.welcome-screen {
    padding-block: var(--spacing-04) 0;
    transform: scale(1);

    &--invisible {
        opacity: 0;
        pointer-events: none;
    }

    &__content,
    &__flow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-inline-size: 100%;
        padding-inline: var(--spacing-04);

        @include min-width(md) {
            max-inline-size: 50%;
            padding-inline: var(--spacing-05) 0;
        }
    }

    &__content {
        @include min-width(lg) {
            padding-inline: var(--spacing-15x) 0;
        }

        h1 {
            font-size: 64px;
            line-height: 1;

            @include min-width(md) {
                font-size: 81px;
            }
        }

        h3 {
            font-size: var(--font-h3);
            margin-block-end: var(--spacing-02);

            @include min-width(md) {
                margin-block-end: var(--spacing-05);
            }
        }
    }

    &__flow {
        @include min-width(lg) {
            padding-inline: var(--spacing-07) 0;
        }
    }

    &__text {
        font-size: var(--font-medium);
        margin-block-end: var(--spacing-07);
    }

    &__animated-text {
        position: absolute;
        font-size: var(--font-medium);
        opacity: 0;
        padding-inline-start: var(--spacing-01);
        transition: opacity 0.5s ease-in-out;

        &.visible {
            opacity: 1;
        }

        &.fading {
            opacity: 0;
        }
    }

    &__start {
        border-radius: var(--border-radius-rounded);
        background: var(--widget-brand-color);
        block-size: var(--size-15);
        font-size: var(--font-h4);
        font-weight: var(--font-weight-medium);
        gap: var(--spacing-03);
        inline-size: 100%;
        letter-spacing: 0.25px;
        line-height: 120%;
        mix-blend-mode: difference;
        padding-inline: var(--spacing-04);
        text-transform: none;
        transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

        @include min-width(md) {
            block-size: var(--size-16);
            inline-size: auto;
            padding-inline: var(--spacing-07) var(--spacing-11x);
        }

        @include min-width(lg) {
            block-size: var(--size-18);
        }

        &:hover {
            background: var(--background-primary-invert);
            /* stylelint-disable-next-line declaration-no-important */
            color: var(--text-primary-invert) !important;
        }
    }

    &__see {
        display: flex;
        justify-content: center;
        margin-block-start: var(--spacing-05);

        &::before,
        &::after {
            display: block;
            background: var(--border-tertiary);
            block-size: 1px;
            content: '';
            inline-size: 100%;
            margin-block-start: var(--spacing-02);
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: var(--font-regular);
            font-weight: var(--font-weight-medium);
            padding-inline: var(--spacing-04);
            white-space: nowrap;
        }

        @include min-width(md) {
            margin-block-start: var(--spacing-13);
        }
    }

    &__host-target {
        position: absolute;
        overflow: hidden;
        block-size: 100%;
        inline-size: 100%;
        inset-block: 0 0;
        inset-inline: 0 0;
        pointer-events: none;

        &--invisible {
            position: fixed;
            overflow: hidden;
        }
    }

    &__host-spacer {
        position: relative;
        block-size: 158px;
        margin-block: var(--size-2);

        @include min-width(md) {
            position: absolute;
            margin: 0;
            block-size: calc(100% - 125px);
            inline-size: 100%;
            inset-block-start: 0;
        }
    }

    @include min-width(md) {
        padding-block: var(--spacing-10) 0;
    }
}
</style>
