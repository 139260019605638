<script setup lang="ts">
    import { formatTime } from '@/helpers/formatting';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const props = defineProps<{
        timeSlot: {
            startDateTime: string
            endDateTime: string
            name: string
            spaces: {
                space: {
                    name: string
                }
            }[]
            description: string
            products: {
                id: string | number
                product: {
                    name: string
                }
            }[]
        },
        isGenericTimeslot: boolean,
    }>();

    const timeslotDescription: Ref<HTMLElement | null> = ref(null);

    const descriptionFormatter = (): void => {
        if (timeslotDescription.value) {
            const description = props.timeSlot.description.replace(/\n\n/gu, '<br><br>');
            timeslotDescription.value.innerHTML = description;
        }
    };

    onMounted(() => {
        descriptionFormatter();
    });
</script>

<template>
    <div class="timeslot-information">
        <div class="timeslot-information__dates">
            <span class="timeslot-information__dates__content">
                {{ isGenericTimeslot ? t('widget.package.all-day') : `${formatTime(timeSlot.startDateTime)} - ${formatTime(timeSlot.endDateTime)}` }}
            </span>
        </div>
        <div class="timeslot-information__info">
            <span class="timeslot-information__info__title">
                {{ isGenericTimeslot ? t('widget.package.generic-timeslot') : timeSlot.name }}
            </span>
            <div class="timeslot-information__info__spaces">
                <span
                    v-for="(space, index) in timeSlot.spaces"
                    :key="`${space.space}`"
                >
                    {{ space.space.name }}<span v-if="index !== timeSlot.spaces.length - 1"> - </span>
                </span>
            </div>
            <div
                v-if="timeSlot.description"
                ref="timeslotDescription"
                class="timeslot-information__info__description"
            />
            <div
                v-if="timeSlot.products.length"
                class="timeslot-information__info__list"
            >
                <span
                    v-for="(product) in timeSlot.products"
                    :key="product.id"
                    class="timeslot-information__info__item"
                >
                    {{ product.product.name }}
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.timeslot-information {
    display: flex;
    gap: var(--spacing-03);
    padding-block: 32px;
    padding-inline: 0;

    &:not(:last-child) {
        border-block-end: 1px solid var(--border-tertiary);
    }

    &__dates {
        min-inline-size: 90px;

        &__content {
            display: flex;
            align-items: center;
            block-size: 20px;
            font-size: 14px;
            line-height: 1;
            white-space: nowrap;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-03);

        &__title {
            display: flex;
            align-items: center;
            block-size: 20px;
            font-size: 17px;
            font-weight: var(--font-weight-medium);
            line-height: 1;
            text-transform: capitalize;
        }

        &__spaces {
            span {
                font-size: 12px;
                line-height: 1;
                text-transform: uppercase;
            }
        }

        &__description {
            font-size: 14px;
        }

        &__list {
            display: flex;
            flex-direction: column;
        }

        &__item {
            font-size: 14px;
            font-weight: var(--font-weight-medium);
        }
    }
}
</style>
