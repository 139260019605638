<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import WidgetInputRadio from '@/components/WidgetInputRadio.ce.vue';

    const props = defineProps<{
        modelValue: string;
        step: number
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue', value: string): void
        (event: 'next', value: number): void
    }>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 1000;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;
    const SKIP_STEP_MIDDLE = 2.5;
    const SKIP_STEP_NEXT = 3;

    const items = ref([
        {
            label: t('widget.appointment.yes'),
            value: 'yes',
        },
        {
            label: t('widget.appointment.no'),
            value: 'no',
        },
    ]);
    const appointmentModel = ref(props.modelValue ?? '');
    const showTitle = ref(false);
    const showElement = ref(false);
    const exitElement = ref(false);

    const handleExitStep = (value: string):void => {
        exitElement.value = true;
        if (value === 'yes') {
            emit('next', props.step + STEP_MIDDLE);
            setTimeout(() => {
                emit('next', props.step + STEP_NEXT);
            }, EXIT_DELAY);
        } else {
            emit('next', props.step + SKIP_STEP_MIDDLE);
            setTimeout(() => {
                emit('next', props.step + SKIP_STEP_NEXT);
            }, EXIT_DELAY);
        }
    };

    const resetAnimationState = ():void => {
        showTitle.value = false;
        showElement.value = false;
        exitElement.value = false;

        // Trigger the "showElement" transition again after a brief delay
        setTimeout(() => {
            showTitle.value = true;
            setTimeout(() => {
                showElement.value = true;
            }, EXIT_DELAY * 2);
        }, ENTRY_DELAY);
    };

    onMounted(() => {
        resetAnimationState();
    });

    watch(() => appointmentModel.value, (nVal: string) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{
            'slide-bottom-up': showTitle && !exitElement,
            'slide-up': showElement && !exitElement,
            'slide-out': exitElement
        }"
        class="appointment-card"
    >
        <div class="appointment-card__text">
            <h2 class="appointment-card__title">
                {{ t('widget.appointment.title') }}
            </h2>
            <span class="appointment-card__subtitle">
                {{ t('widget.appointment.subtitle') }}
            </span>
        </div>
        <div
            :class="{'slide-up': showElement}"
            class="appointment-card__actions"
        >
            <template
                v-for="item in items"
                :key="item.value"
            >
                <WidgetInputRadio
                    v-model="appointmentModel"
                    :input-value="item.value"
                    name="survey"
                    @click.stop="handleExitStep(item.value)"
                >
                    {{ item.label }}
                </WidgetInputRadio>
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.appointment-card {
    position: absolute;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    transform: translateY(100%);
    transition: all 0.75s ease-in-out;

    h2 {
        font-size: var(--font-h2);
        padding-inline: var(--spacing-03);
        text-align: center;

        @include min-width(md) {
            font-size: var(--font-h1);
        }
    }

    &__subtitle {
        font-size: var(--font-regular);
        padding-inline: var(--spacing-03);
        text-align: center;

        @include min-width(md) {
            font-size: var(--font-h4);
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-02);
        inline-size: 100%;
        transition: all 1s ease-in-out;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--spacing-07);
        inline-size: 100%;
        margin-block-start: var(--spacing-11);
        opacity: 0;
        transition: all 1s ease-in-out;

        &.slide-up {
            opacity: 1;
        }
    }

    &.slide-bottom-up {
        transform: translateY(15%);
    }

    &.slide-up {
        transform: translateY(0);
    }

    &.slide-out {
        transform: translateY(-100%);
    }
}
</style>
