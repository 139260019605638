/**
 * This is a workaround for a Vue3 bug regarding styles and Web Components.
 * See https://stackoverflow.com/questions/71680258/vue3-styling-nested-single-file-components
 * See https://github.com/vuejs/core/issues/4662
 *
 * Updated to remove the need for '.ce.vue' file extensions.
 */
import { defineCustomElement as VueDefineCustomElement, createApp, getCurrentInstance, h } from 'vue';

interface PluginOptions {
  plugin: any;
  options?: any;
}

interface DefineCustomElementOptions {
  globalComponents?: Record<string, any>;
  plugins?: PluginOptions[];
}

const nearestElement = (el: HTMLElement | null): HTMLElement | null => {
    while (el?.nodeType !== 1 /* ELEMENT */) el = el.parentElement;
    return el;
};

export const defineCustomElement = (component, { globalComponents = {}, plugins = [] }: DefineCustomElementOptions = {}) =>
    VueDefineCustomElement({
        props: component.props,
        setup(props) {
            const app = createApp(component);

            // Use plugins with their options if provided
            plugins.forEach(({ plugin, options }) => {
                app.use(plugin, options);
            });

            Object.entries(globalComponents)
                .forEach(([name, comp]) => app.component(name, comp));

            app.mixin({
                mounted() {
                    const insertStyles = (styles: string[]) => {
                        if (styles?.length) {
                            this.__style = document.createElement('style');
                            this.__style.innerText = styles
                                .join()
                                .replace(/\n/g, '');
                            nearestElement(this.$el)
                                ?.prepend(this.__style);
                        }
                    };

                    // Extract styles from the component's options
                    insertStyles((this.$?.type).styles);

                    // Inject styles for all registered child components
                    if (this.$options.components) {
                        for (const comp of Object.values(this.$options.components)) {
                            insertStyles((comp as any).styles);
                        }
                    }
                },
                unmounted() {
                    this.__style?.remove();
                },
            });

            const inst = getCurrentInstance();
            Object.assign(inst.appContext, app._context);
            Object.assign(inst.provides, app._context.provides); // This is needed to get vue-i18n to work.
            return () => h(component, props);
        },
    });
