<script setup lang="ts">
    import { HOURS } from '@/constants/hours';

    const props = defineProps<{ modelValue: string }>();

    const emit = defineEmits<{(event: 'update:modelValue', value: string | null)}>();

    // Refs
    const hoursModel: Ref<string> = ref(props.modelValue ?? '');

    // Methods
    const selectHour = (hour: string): void => {
        hoursModel.value = hour;
    };

    // Watchers
    watch(() => hoursModel.value, (nVal: string) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div class="widget-hours">
        <button
            v-for="hour in HOURS"
            :key="hour"
            :class="{'widget-hours__button--selected': hour === hoursModel}"
            type="button"
            class="widget-hours__button"
            @click="selectHour(hour)"
        >
            {{ hour }}
        </button>
    </div>
</template>

<style lang="scss" scoped>
.widget-hours {
    display: grid;
    padding: var(--spacing-02);
    border-radius: var(--border-radius-md);
    background: rgb(0 0 0 / .02);
    gap: var(--spacing-01);
    grid-template-columns: repeat(5, 1fr);
    inline-size: 100%;

    @include min-width(md) {
        padding: var(--spacing-04);
        gap: var(--spacing-02);
        grid-template-columns: repeat(7, 1fr);
    }

    @include min-width(lg) {
        grid-template-columns: repeat(2, 1fr);
        max-block-size: 100%;
        overflow-y: auto;
    }

    &__button {
        padding: 0;
        border: 1px solid var(--color-white);
        border-radius: var(--border-radius-sm);
        background-color: rgb(255 255 255 / 0.4);
        block-size: var(--size-8);
        font-family: var(--font-primary);
        font-size: var(--font-regular);
        font-weight: var(--font-weight-light);
        inline-size: 100%;

        @include min-width(md) {
            block-size: var(--size-12);
        }

        &--selected {
            border-color: var(--border-primary);
            background-color: var(--color-white);
            font-weight: var(--font-weight-medium);
        }
    }
}
</style>
