<script setup lang="ts">
    import Skeleton from '@shared/components/ui/skeleton.vue';
    import { ConstantsBreakpoints } from '@shared/constants';
    import { FormSizeTypeEnum, FormStyleEnum } from '@shared/constants/form-style';
    import { computed } from 'vue';

    const props = defineProps<{
        name: string
        label?: string
        placeholder?: string
        validation?: string | [rule: string, ...args: unknown[]][]
        help?: string
        style?: FormStyleEnum.PRIMARY | FormStyleEnum.SECONDARY
        size?: FormSizeTypeEnum.NORMAL | FormSizeTypeEnum.EXTRA
        validationMessages?: Record<string, string>
        disabled?: boolean
        labelHidden?: boolean
        isLoading?: boolean
    }>();

    const { width } = useWindowSize();

    const isRequired = computed(() =>
        Array.isArray(props.validation) ? props.validation[0].includes('required') : props.validation?.includes('required'));
    const isMobile = computed(() => Number(width.value) < Number(ConstantsBreakpoints.Breakpoints.TABLET));
    const isDesktop = computed(() => Number(width.value) >= Number(ConstantsBreakpoints.Breakpoints.DESKTOP));
    const skeletonHeight = computed(() => isMobile.value ? '56px' : isDesktop.value ? '64px' : '60px');
</script>

<template>
    <div
        class="email-input"
        :data-required="isRequired"
    >
        <Skeleton
            v-if="isLoading"
            :height="skeletonHeight"
        />
        <FormKit
            v-else
            :label="label"
            :name="name"
            :placeholder="placeholder"
            :validation="`${validation}|email`"
            :help="help"
            :inner-class="style ?? FormStyleEnum.PRIMARY"
            :wrapper-class="size ?? FormSizeTypeEnum.NORMAL"
            :disabled="disabled"
            :label-class="labelHidden ? 'formkit-label--hidden' : ''"
            :validation-messages="validationMessages"
            type="email"
        />
    </div>
</template>

<style scoped lang="scss">
    .email-input :deep(.formkit-outer) {
        .formkit-inner {
            input {
                box-sizing: border-box;
                padding: var(--spacing-03);
                border: 1px solid var(--border-tertiary);
                border-radius: var(--border-radius-xs);
                background-color: var(--background-form-input);
                block-size: var(--size-11);
                font-size: var(--font-medium);
                inline-size: 100%;
                line-height: 1;

                &::placeholder {
                    color: var(--text-placeholder);
                }

                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }

                @include min-width(md) {
                    block-size: var(--size-12);
                }

                @include min-width(lg) {
                    block-size: var(--size-13);
                }
            }

            &.secondary input {
                &:hover {
                    background-color: var(--background-form-input-hover);
                }

                &:focus,
                &:focus-visible,
                &:focus-within {
                    background-color: var(--background-form-input-active);
                }
            }
        }

        &[data-invalid=true] .formkit-inner {
            input,
            &.secondary input {
                border-color: var(--border-negative);
            }
        }

        &[data-required=true] {
            .formkit-label::after {
                color: var(--text-secondary);
                content:" *";
            }
        }
    }
</style>
